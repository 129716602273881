import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Divider } from "primereact/divider";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./Styles/login.css";
import { Link, useHistory } from "react-router-dom";

import Axios from "axios";


import Swal from "sweetalert2";
import { API } from "./constants/api_url";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useForceUpdate from "use-force-update";
import { Tooltip } from "primereact/tooltip";
import APIServices from "./service/APIService";



const PasswordReset = () => {

    useEffect(() => {
        localStorage.clear();
    }, [])

    let k = useLocation();
    const navigate = useHistory()
    const [data, setData] = useState({
        password: '', confirmPassword: '', resetKey:
            k.search.split('=')[1]
    })
    const [show, setShow] = useState(true)
    const forceUpdate = useForceUpdate()
    function is_valid_password(password, type) {
        // Check if the password is not an empty string and is between 8 and 20 characters.


        // Check if the password contains at least one lowercase letter.
        if (!/[a-z]/.test(password)) {
            return { result: false, text: 'Password not contains lowercase' };
        }

        // Check if the password contains at least one uppercase letter.
        if (!/[A-Z]/.test(password)) {
            return { result: false, text: 'Password not contains uppercase' };
        }

        // Check if the password contains at least one special character (non-alphanumeric).
        if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
            return { result: false, text: 'password not contains special character' };
        }

        // Check if the password contains any spaces.
        if (/\s/.test(password)) {
            return { result: false, text: 'Empty space not allowed' };
        }
        if (!/\d/.test(password)) {
            return { result: false, text: 'password not contains numeric character' };
        }
        if (!(password && password.length >= 8 && password.length <= 20)) {
            return { result: false, text: 'Minimum of 8 character & maximum of 20 character' };
        }
        if (type) {
            if (data.password !== password) {
                return { result: false, text: 'Password not matches' };
            } else {
                return { result: true, text: '' }
            }
        }
        // If all conditions are met, the password is valid.
        return { result: true, text: '' }
    }
    const updateData = (obj, val) => {
        console.log(obj, val)
        let loc = data
        loc[obj] = val
        setData(loc)
        forceUpdate()
    }
    const updatePassword = () => {
        if (is_valid_password(data.confirmPassword, true).result) {
            APIServices.put(API.ResetPassword, data).then((a) => {
                Swal.fire({
                    title: "Password Changed Successfully",
                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        setShow(false)
                        window.open(window.location.origin + '/#/')
                    }
                })
            }).catch(() => {
                Swal.fire({
                    title: "Error while password change, contact admin",
                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.close()
                    }
                })
            })
        }
    }
    return (
        <div className="col-12 justify-content-center align-items-center" style={{ background: 'white', height: '100vh', display: 'flex' }}>

            <div className="col-6" style={{ background: 'white', padding: 10, boxShadow: `rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px`, textAlign: 'center' }}>
                {show ? <>
                    <h1> Navigos - Password Change  </h1>

                    <div >
                        <div className="col-12 p-inputgroup">
                            <span className="col-4" style={{
                                alignItems: 'center',
                                display: 'flex',
                                margin: '10px'
                            }}>
                                Enter New Password :
                            </span>
                            <InputText onChange={(e) => { updateData('password', e.target.value) }} className={"col-8" + (!is_valid_password(data.password).result && 'p-invalid')} type='password' value={data.password} placeholder="enter password" />
                        </div>
                        <small className="col-12" style={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
                            {is_valid_password(data.password).text}
                        </small>
                    </div>
                    <div>
                        <div className="col-12 p-inputgroup">
                            <span className="col-4" style={{
                                alignItems: 'center',
                                display: 'flex',
                                margin: '10px'
                            }}>
                                Re-enter New Password
                            </span>
                            <InputText onChange={(e) => { updateData('confirmPassword', e.target.value) }} className={"col-8" + (!is_valid_password(data.confirmPassword, true).result && 'p-invalid')} type='password' value={data.confirmPassword} placeholder="enter password" />
                        </div>
                        <small className="col-12" style={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
                            {is_valid_password(data.confirmPassword, true).text}
                        </small>
                    </div>
                    <div className="col-12 " style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', margin: 10
                    }}>
                        <Tooltip target={".criteria"} position={'right'} autoHide={true}>
                            <div>
                                <ol>
                                    <li>Minimum 8 character & maximum 20 character required</li>
                                    <li>Minimum 1 Lowercase character required</li>
                                    <li>Minimum 1 Uppercase character required</li>
                                    <li>Minimum 1 numeric character required</li>
                                    <li>Minimum 1 Special character required</li>
                                    <li>Empty space should not allowed</li>
                                    <li>Password should matches</li>
                                </ol>
                            </div>
                        </Tooltip>
                        <label className="criteria" style={{ textDecoration: 'underline' }}>      Password Criteria</label>

                        {/* <i className="material-icons criteria" style={{ color: '#005284' }}>info</i> */}
                    </div>
                    <div className="col-12" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', margin: 10
                    }}>
                        <Button onClick={() => { updatePassword() }}>Change Password</Button>

                    </div>
                </> :
                    <div>
                        <h5 style={{ color: 'green' }}> Password Changed Successfully </h5>
                        <span>click <a onClick={() => { window.open(window.location.origin + '/#/') }}>here</a> to go login screen</span>
                    </div>

                }
            </div>


        </div>
    )
}
export default PasswordReset