import React from "react";
import  WaterDataTable  from '../Leadership/dataTable/waterDataTable'
import  WasteDataTable  from '../Leadership/dataTable/wasteDataTable'
import  AirDataTable  from '../Leadership/dataTable/airDataTable'
import  LaborDataTable  from '../Leadership/dataTable/laborDataTable'
import  EthicalDataTable  from '../Leadership/dataTable/ethicalDataTable'
import  HealthDataTable  from '../Leadership/dataTable/HealthDataTable'


const LeadershipDashboard = () => {

    return (
        <div className="font-lato">
            <div style={{ marginBottom: '3%' }}>
                <WaterDataTable />
            </div>
            <div style={{ marginBottom: '3%' }}>
                <WasteDataTable />
            </div>
            <div style={{ marginBottom: '3%' }}>
                <AirDataTable />
            </div>
            <div style={{ marginBottom: '3%' }}>
                <LaborDataTable />
            </div>
            <div style={{ marginBottom: '3%' }}>
                <EthicalDataTable />
            </div>
            <div style={{ marginBottom: '3%' }}>
                <HealthDataTable />
            </div>
        </div>
    )
}

export default LeadershipDashboard