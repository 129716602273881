import $ from "jquery";
import React, { Component, createRef, useRef, useEffect } from "react";
import Swal from "sweetalert2";
import { InputText } from "primereact/inputtext";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const FormBuilder = (props) => {

  const fb = useRef();

  useEffect(() => {

    const options = {

      editOnAdd: true,
      typeUserAttrs: {
        number: {
          fraction: {
            label: 'Fraction',
            value: 0
          },
          directionsign: {
            label: '0-higher,1-lower',
            value: 0
          },
          
        }
      },
      disabledActionButtons: ['data', 'save', 'clear'],
      onAddField: (optionTemplate, optionIndex) => {


        return optionTemplate
      },
      disabledAttrs: [
        'access',
        'className',
        'inline',
        'value', 'min', 'max', 'maxlength',
        'other',
        'rows',
        'step',
        'style',
        'subtype',
        'toggle'
      ],
      disableFields: ['autocomplete', 'hidden', 'header', 'button'
      ],
  
      stickyControls: {
        enable: true,
        offset: {
          top: 70,
          right: 20,
          left: 'auto'
        }
      },
      onSave: function (evt, formData) {
        console.log(formData)
        props.onSubmit(formData)
        const customSwal2 = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',

          },
          buttonsStyling: false
        })
        customSwal2.fire(
          'Saved!',
          '',
          'success')

      },
    };


    let formBuilderPlugin = $(fb.current).formBuilder(options);

    props.fbref(formBuilderPlugin)
    setTimeout(() => { formBuilderPlugin.actions.setData(props.values); }, 500)

  }, [])

  return (
    <>

      <div id="fb-editor" ref={fb} />
    </>


  );
}

// class FormBuilder extends Component {
//     fb = createRef();
//     componentDidMount() {
//       $(this.fb.current).formBuilder();
//     }

//     render() {
//       return <div id="fb-editor" ref={this.fb} />;
//     }
//   }

export default FormBuilder