import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import APIServices from "../../service/APIService";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";

window.jQuery = $;
window.$ = $;

const ApproverIndicatorClientAssignment = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [user, setUser] = useState([]);
    const [assdilaog, setAssDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const [assignments, setAssignments] = useState([])
    const [assobj, setAssObj] = useState({ indicatorId: null, categoryId: null, dcfIds: [], approverFrequency: null })
    const forceUpdate = useForceUpdate();
    const [old, setOld] = useState([]);
    const [category, setCategory] = useState([])
    const [appindicator, setAppIndicator] = useState([])

    const [dcflist, setDCFList] = useState([]);
    const [userList, setUserList] = useState([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const [userConfig, setUserConfig] = useState({
        name: "",
        location: {
            countrylist: [],
            newcountry: "",
            newcity: "",
            newlocation: "",
            selectedcountry: "",
            citylist: [],
            selectedcity: "",
            locationlist: [],
            selectedlocation: "",
        },
    });
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [selected, setSelected] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [cascade, setCascade] = useState("");
    const [showSave, setShowSave] = useState(0);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });

    useEffect(async () => {
        setOld(selector.information);
        const promise0 = APIServices.get(API.UserProfile)
        const promise1 = APIServices.get(API.DCF_Title_Only)
        const promise2 = APIServices.get(API.Categories)
        const promise3 = APIServices.get(API.AppIndicator)

        Promise.all([promise0, promise1, promise2, promise3]).then((values) => {

            let locuser = [];
            setDCFList(values[1].data)
            setAppIndicator(values[3].data)
            setCategory(values[2].data)
            setUserList(values[0].data);
            values[0].data.forEach((item) => {
                if (item.role === "clientadmin") {
                    locuser.push({
                        name: item.information.companyname,
                        id: item.id,
                    });
                }
            });

            setUser(locuser);


        })


    }, [selector]);
    const updateUserConfig = (obj, val) => {
        let loc = userConfig;
        loc[obj] = val;
        if (val !== undefined) {
            APIServices.get(API.AppIndicatorAss_UP(val)).then((res) => {
               setAssignments(res.data)
            });
            setUserConfig(loc);
        }

        setCascade("");
        forceUpdate();
    };
    const showTier3CM = (e, ind) => {

        tier3ref.current.forEach((i, j) => {
            if (j === ind) {
                tier3ref.current[ind].show(e)
            } else {
                if (tier3ref.current[j] !== null) {
                    tier3ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
    }
    const deleteTier1 = async (item, type) => {
        let index = tier1.findIndex((i) => {
            return i.id === item.id;
        });
        let loc = tier1
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.name}" from ${type} list</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.LocationOne_Edit(item.id)).then((res) => {
                loc.splice(index, 1);
                let loc2 = selected

                loc2.tier1 = ""
                loc2.tier2 = ""
                setSelected(loc2)
                setTier1(loc)
                forceUpdate()
            })
        }
    }
 
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
    }
  
   
    const updateAssignment = (obj, val) => {
        setAssObj((prev) => ({ ...prev, [obj]: val }))
    }
    const saveAssignment = () => {
        let loc = JSON.parse(JSON.stringify(assignments))
        setSubmitted(true)
        console.log(userConfig.name)
        if (assobj.categoryId && assobj.indicatorId && assobj.approverFrequency && assobj.dcfIds.length) {
            if (assobj.id) {
                APIServices.patch(API.AppIndicatorAss_Edit(assobj.id), assobj).then((res) => {
                    let index = assignments.findIndex((i) => {
                        return i.id === assobj.id;
                    })
                    if (index !== -1) {
                        loc[index] = assobj;
                        setAssignments(loc)
                    }
                    setAssDialog(false)
                }).catch((e) => {
                    setAssDialog(false)
                })
            } else {
                APIServices.post(API.AppIndicatorAss_UP(userConfig.name), assobj).then((res) => {
                    loc.push(res.data)
                    setAssignments(loc)
                    setAssDialog(false)
forceUpdate()
                }).catch((e) => {
                    setAssDialog(false)
                })
            }

        }

    }
    const DialogFooter = (
        <>
            <Button
                label="Save"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    saveAssignment();
                }}
            />
        </>
    );
    const addNew = () => {
        setSubmitted(false)
        setAssObj({ indicatorId: null, categoryId: null, dcfIds: [], approverFrequency: null })
        setAssDialog(true)
    }
    const editAss = (rowData)=>{
        setAssObj(rowData)
        setSubmitted(false)
        setAssDialog(true)
    }
    const indicatorTemplate = (rowData) => {
        let txt = 'Not Found'
        let index = appindicator.find(i => i.id === rowData.indicatorId)
        if (index) {
            txt = index.title
        }
        return <span className='clr-navy fw-6 fs-14 cur-pointer text-three-dot text-underline' onClick={()=>{editAss(rowData)}}>{txt}</span>
    }
    const categoryTemplate = (rowData) => {
        let txt = 'Not Found'
        let index = category.find(i => i.id === rowData.categoryId)
        if (index) {
            txt = index.title
        }
        return <>{txt}</>
    }
    const dcfIdsTemplate = (rowData) => {
     
        return  <>{rowData.dcfIds.length} </>
    }
    const frequencyTemplate = (rowData) => {
        let txt = 'Not Found'

        let index = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Bi-Annually', id: 6 }, { name: 'Annually', id: 12 }].find(i => i.id === rowData.approverFrequency)
        if (index) {
            txt = index.name
        }
        return <>{txt}</>
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>

                        <div>                        <label
                            style={{
                                marginRight: 10,
                            }}
                        >
                            Select Enterprise / Client
                        </label>
                            <Dropdown value={userConfig.name} options={user} optionValue="id" onChange={(e) => updateUserConfig("name", e.value)} optionLabel="name" filter filterBy="name" placeholder="Select Enterprise" />
                        </div>
                        {/* {userConfig.name !== "" && userConfig.name !== undefined &&  <div><h6>Total Site(s) - <span style={{ color: '#005284' }}>{sitecount}</span></h6></div> } */}


                    </div>


                    {userConfig.name !== "" && userConfig.name !== undefined && (

                        <div className="col-12">
                            <div className="flex justify-content-end">
                                <div  > <Button label="Add Assignment" onClick={addNew} /> </div>
                            </div>
                            <DataTable  scrollable value={assignments} >
                                <Column header="Indicator" body={indicatorTemplate} />
                                <Column header="Category" body={categoryTemplate} />
                                <Column header="Frequency" body={frequencyTemplate} />
                                <Column header='# of DCF Tagged' body={dcfIdsTemplate} />

                            </DataTable>
                            <Dialog   modal
                className="p-fluid" header={assobj.id ? 'Edit Assignment' : 'Add Assignment'} visible={assdilaog} footer={DialogFooter} style={{ width: '65%' }} onHide={() => { setAssDialog(false) }}>
                              <div className='col-12'>
                                <div className="field">
                                    <label htmlFor="ecpsngr">Select Indicator</label>
                                    <Dropdown
                                        optionLabel="title"
                                        onChange={(e) => {
                                            updateAssignment("indicatorId", e.value);
                                        }}
                                        optionValue="id"
                                        options={appindicator}
                                        value={assobj.indicatorId}
                                    />

                                    {submitted && assobj.indicatorId === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Select Indicator
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="ecpsngr">Select Category</label>
                                    <Dropdown
                                        optionLabel="title"
                                        onChange={(e) => {
                                            updateAssignment("categoryId", e.value);
                                        }}
                                        optionValue="id"
                                        options={category}
                                        value={assobj.categoryId}
                                    />

                                    {submitted && assobj.categoryId === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Select Category
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="ecpsngr">Select Approving Frequency</label>
                                    <Dropdown
                                        optionLabel="name"
                                        onChange={(e) => {
                                            updateAssignment("approverFrequency", e.value);
                                        }}
                                        optionValue="id"
                                        options={[{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Bi-Annually', id: 6 }, { name: 'Annually', id: 12 }]}
                                        value={assobj.approverFrequency}
                                    />

                                    {submitted && assobj.approverFrequency === null && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Select Approve Frequency
                                        </small>
                                    )}
                                </div>
                                <div className="field">
                                    <label htmlFor="ecpsngr">DCF Tagging</label>
                                    <MultiSelect filter  filterBy={"title"} className="w-full " panelClassName={'hidefilter'} value={assobj.dcfIds} optionValue='id' optionLabel='title' options={dcflist} onChange={(e) => { updateAssignment("dcfIds", e.value) }} placeholder="Select DCF(s)" />

                                    {submitted && assobj.dcfIds.length === 0 && (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            Select Atleast One DCF
                                        </small>
                                    )}
                                </div>
                                </div>



                            </Dialog>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ApproverIndicatorClientAssignment, comparisonFn);
