import React, { useEffect, useState, useRef, createRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import FormBuilder from "../../components/FormBuilder";
import { MultiSelect } from 'primereact/multiselect';
import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip'
import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $ from 'jquery'
import axios from "axios";
import IATACodes from "../../assets/dummy/IATACodes";
import { AutoComplete as AC } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";
import { API } from "../../constants/api_url";
import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";


const google = window.google;

window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");
// (0.79/1000)

const ConsolidateFormLibrary = () => {
    let dtref = useRef(null)
    const hardcodeddcf = ['10', '11', '36', '15', '16']
    const selector = useSelector((state) => state.user.userdetail)
    const forceUpdate = useForceUpdate()
    const his = useHistory()
    const [formfilter, setFormFilter] = useState('')
    const [formbk, setFormbk] = useState([])
    const [selectedform, setSelectedForm] = useState({ title: '', tags: [] })
    const [prevdialog, setPrevDialog] = useState(false);
    const [items, setItems] = useState([])
    const [searchstr, setSearch] = useState('')
    const [adddialog, setAddDialog] = useState(false)
    const [title, setTitle] = useState('')
    const [form, setForm] = useState([])
    const [clienttag, setClientTag] = useState([])
    const [submitted, setSubmitted] = useState(false)
    useEffect(() => {

        APIServices.get(API.SRF).then((res) => {
            console.log(res.data.reverse());

            setForm(res.data);
            setFormbk(res.data)
        })
        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];

            res.data.forEach((item) => {
                if (item.role === "clientadmin" && item.information.enterpriseid !== undefined) {
                    locuser.push({
                        name: item.information.enterpriseid,
                        id: item.id,
                    });
                }
            });

            setClientTag(locuser);
        });

    }, []);
    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }
    const submitFooter = (
        <>
            <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setAddDialog(false);
                }}
            />
            <Button
                label="Save & Exit"
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    if (selectedform.title.trim().length !== 0) {
                        addNewCF()
                    }
                }}
            />
        </>
    );
    const addNewCF = () => {
        APIServices.post(API.SRF, { title: selectedform.title.trim(), tags: selectedform.tags, data1: [], type: 1, suffix: "SRF", curator_id: selector.id, comments: "", created: moment.utc() }).then((res) => {
            let loc = JSON.parse(JSON.stringify(form))
            loc.unshift(res.data)
            setAddDialog(false)
            setForm(loc)
            setFormbk(loc)
            forceUpdate()

        })
    }
    const renderPreview = () => {


        if (prevdialog && selectedform.data1.length !== 0) {

            let data = selectedform.data1


            return data.map((field, ind) => {
                if (field.type === 'paragraph') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">

                            <p>{removeHTMLTag(field.label)}</p>
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'date' + ind}>{removeHTMLTag(field.label)}</label>
                            <Calendar disabled showIcon ></Calendar>
                        </div>
                    )
                } else if (field.type === 'text') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'text' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'textarea') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textarea' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputTextarea disabled></InputTextarea>
                        </div>
                    )
                } else if (field.type === 'number') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textno' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText keyfilter={'num'} disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'select') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'select' + ind}>{removeHTMLTag(field.label)}</label>
                            <Dropdown options={field.values} ></Dropdown>
                        </div>
                    )
                } else if (field.type === 'radio-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'radio' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.label}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>
                    )
                } else if (field.type === 'checkbox-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'checkbox' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.value}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>

                    )
                }
            })
        }
    }
    const checkDPStatus = (data) => {
        if (data === null || data === undefined) {
            return { result: false, data: '0/0' }
        } else if (data.length === 0) {
            return { result: false, data: '0/0' }
        } else if (data.length !== 0) {
            let total = 0, count = 0

            data.forEach((item) => {

                if (item.type !== 'paragraph' && item.type !== 'file' && item.type !== 'date') {
                    total = total + 1
                    if (item.name.startsWith('DP')) {
                        count = count + 1
                    }

                }
            })
            return { result: true, data: count + '/' + total }
        } else {
            return { result: false, data: '0/0' }
        }
    }
    const nameTemplate = (rowData) => {
        console.log(rowData.id)
        return (
            <>
                <span > {rowData.title}</span>
            </>
        )
    }
    const dpidStatus = (rowData) => {

        return (
            <>
                <Tooltip target={".csf" + rowData.id}>
                    {
                        (!rowData.tags || !rowData.tags.length) ?
                            ''
                            :
                            clienttag.filter(i => rowData.tags.includes(i.id)).map(i => <>{i.name}  <br />  </>)

                    }
                </Tooltip>


                <span className={(!rowData.tags || !rowData.tags.length) ? 'status-tag-gray' : 'status-tag-navy csf' + rowData.id}  > {(!rowData.tags || !rowData.tags.length) ? 'Default' : 'CSF'}</span>
            </>
        )
    }
    const formIDTemplate = (rowData) => {
        return (
            <>
                <span> SRF{rowData.id}</span>
            </>
        )
    }
    const editCF = (item) => {
        his.push({ pathname: 'add_cf', state: item })
    }

    const search = (event) => {

        setSearch(event)
        let _items = formbk.filter((k) => { return (k.title.trim().toLowerCase().includes(event.trim().toLowerCase()) || k.id.toString().includes(event.trim()) || k.tags && k.tags.length && clienttag.filter(i => k.tags.includes(i.id)).map(i => i.name.trim().toLowerCase()).some(i => i.includes(event.trim().toLowerCase()))) })
        setForm(_items);
    }
    const cloneSRF = async (item) => {
        let newObj = JSON.parse(JSON.stringify(item))
        newObj.title = item.title + ' _Cloned'
        delete newObj.updated
        delete newObj.id
        newObj.data2 = []
        delete newObj.modifier_id
        if (item.purpose) {
            newObj.purpose = item.purpose
        } else {
            delete newObj.purpose
        }
        newObj.comments = ''
        newObj.suffix = 'SRF'
        newObj.created = DateTime.utc()
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to clone "${item.title}" Form
  </div>`,

            confirmButtonColor: 'green',
            showCancelButton: true,

            confirmButtonText:
                'Clone',

        })
        if (accept) {
            APIServices.post(API.SRF, newObj).then((res) => {
                if (res.status === 200) {
                    let loc = JSON.parse(JSON.stringify(form))
                    loc.push(res.data)
                    setFormbk(loc)
                    setForm(loc)
                    forceUpdate()
                    setTimeout(() => {
                        Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Form Cloned Successfully",
                            showConfirmButton: false,
                            timer: 1000,
                        });
                    })



                }
            })
        }
    }
    const deleteCF = async (item) => {
        const { value: password } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.title}" from SRF Library
          </div>`,
            input: 'password',
            inputLabel: 'Password',
            confirmButtonColor: 'red',
            inputPlaceholder: 'Enter your password',
            showCancelButton: true,
            inputAttributes: {
                maxlength: 10,
                autocapitalize: 'off',
                autocorrect: 'off'
            }
        })

        if (password === 'vadapooche') {
            APIServices.delete(API.SRF + '/' + item.id).then((a) => {
                let loc = form;
                loc.splice(form.findIndex((k) => { return k.id === item.id }), 1);
                setFormbk(loc)
                setForm(loc)
                forceUpdate()
            })
        }

    }
    const actionBodyTemplate = (rowData) => {

        return (
            true ?
                <>
                    <div className="actions">
                        <Button
                            icon="pi pi-pencil"
                            className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                            onClick={() => {
                                editCF(rowData)
                            }}
                        />

                        {rowData.data1.length !== 0 &&
                            <>
                                <Button
                                    icon="pi pi-copy"
                                    className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'gray' }}
                                    onClick={() => {
                                        cloneSRF(rowData)
                                    }}
                                />
                                <Button
                                    icon="pi pi-eye"
                                    className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'mediumseagreen' }}
                                    onClick={() => {
                                        window.open(window.location.origin + '/#/cf_preview/' + rowData.id)
                                    }}
                                />
                            </>
                        }
                        <Button
                            icon="pi pi-trash"
                            className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                            onClick={() => {
                                deleteCF(rowData)
                            }}
                        />
                    </div>
                </>
                :
                <>
                    Disabled for Hardcoded Forms
                </>
        )
    }
    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );

    return (
        <div className="grid p-fluid">
            {selector.role === "eisqradmin" ? (
                <div className="col-12 md:col-12">
                    <div className="card ">
                        <div
                            className="col-12 md:col-12"
                            style={{
                                textDecoration: "underline",
                                textAlign: "center",
                                textDecorationColor: "green",
                            }}
                        >
                            <h5>SR Form Library</h5>

                        </div>

                        <div className="flex grid col-12 justify-content-between" style={{ marginBottom: 20, marginTop: 20 }}>
                            <div style={{ width: 400 }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search SRF/Tag" value={searchstr} onChange={(e) => { search(e.target.value) }} />
                                </span>
                            </div>
                            <Button
                                icon="pi pi-plus"
                                className="mr-2"
                                onClick={() => {
                                    setSelectedForm({ title: '', tags: [] })
                                    setAddDialog(true)
                                }}
                            ></Button>
                        </div>
                        <DataTable

                            ref={dtref}
                            value={form.sort((a, b) => { return b.id - a.id }, 0)}
                            scrollable
                            paginator
                            rows={20}
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} SRF"

                            emptyMessage="No SRF found."

                            responsiveLayout="scroll"
                        >


                            <Column
                                field="title"
                                header="Form Name"
                                body={nameTemplate}
                                sortable
                                headerStyle={{
                                    width: "55%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>

                            <Column
                                field="id"
                                header="ID"
                                body={formIDTemplate}
                                sortable
                                headerStyle={{
                                    width: "10%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="id"
                                header="Tag"
                                body={dpidStatus}

                                headerStyle={{
                                    width: "5%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column
                                field="created"
                                header="Created On"
                                body={(rowData) => { return (<>{moment(rowData.created).local().format('DD MMM YYYY, hh:mm A')}</>) }}
                                headerStyle={{
                                    width: "5%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >
                            </Column>
                            <Column
                                field="updated"
                                header="Last Modified"
                                body={(rowData) => { return (<>{moment(rowData.updated).local().format('DD MMM YYYY, hh:mm A')}</>) }}
                                headerStyle={{
                                    width: "5%",
                                    minWidth: "8rem", background: '#EEF2FF'
                                }}
                            >

                            </Column>
                            <Column header="Action" headerStyle={{
                                background: '#EEF2FF', width: '15%'
                            }} body={actionBodyTemplate}> </Column>
                        </DataTable>
                        <Dialog
                            visible={adddialog}
                            header={'Add New SRF'}
                            modal
                            style={{ width: '60%' }}
                            footer={submitFooter}
                            onHide={() => {
                                setAddDialog(false);
                            }}
                        >
                            <div className="col-12">
                                <div className="flex flex-column gap-2">
                                    <label className="m-2" htmlFor="username">SRF Title</label>
                                    <InputText id="username" className="m-2" aria-describedby="username-help" value={selectedform.title} onChange={(e) => { setSelectedForm((prev) => ({ ...prev, title: e.target.value })) }} />
                                    <small id="username-help" className="m-2" style={{ color: 'red' }}>
                                        {selectedform.title.trim().length === 0 && 'please enter title '}
                                    </small>
                                </div>
                                <div className="flex flex-column gap-2">
                                    <label className="m-2" htmlFor="username">Tag</label>
                                    <MultiSelect optionValue="id" value={selectedform.tags} onChange={(e) => { setSelectedForm((prev) => ({ ...prev, tags: e.value })) }} style={{ width: '100%' }} options={clienttag} optionLabel="name" display="chip"
                                        placeholder="Select Tags" maxSelectedLabels={3} className="w-full " />

                                </div>
                            </div>

                        </Dialog>
                    </div>
                </div>

            ) :
                <div className="card col-12">You have no rights to access this page</div>

            }

        </div>)
}
export default ConsolidateFormLibrary