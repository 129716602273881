
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { InputTextarea } from "primereact/inputtextarea";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { TabMenu } from "primereact/tabmenu";
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import APIServices from "../../service/APIService";
import { MultiSelect } from "primereact/multiselect";
window.jQuery = $;
window.$ = $;
const { DateTime } = require('luxon')


const ClientSupplierSRFAssignment = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const locationList = useSelector((state) => state.sitelist.locationList)
    const [srfconfig, setSRFConfig] = useState({ frequency: null, start_date: null, end_date: null, user_id: null, reviewer_id: [], approver_id: [], site: null, srfId: null, type: 0, comments: [] });
    const [selDataPoint, setSelDataPoint] = useState([])
    const configtype = [{ name: 'Location' }, { name: 'Data Point' }]
    const [assignedlist, setAssignedList] = useState([])
    const [justifylist, setJustifyList] = useState([])
    const [sitelist, setSiteList] = useState([])
    const [sitesuggestion, setSiteSuggestion] = useState([])
    const [assignedlistbk, setAssignedListBK] = useState([])
    const [unassignedlist, setUnAssignedList] = useState([])
    const [unassignedlistBK, setUnAssignedListBK] = useState([])
    const [stdlist, setStdList] = useState([])
    const [stdlistBk, setStdListBK] = useState([])
    const [frequencyMonths, setFrequencyMonths] = useState([])
    const [assigndialog, setAssignDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [search, setSearch] = useState('')
    const [search_, setSearch_] = useState({ ass: '', unass: '', jus: '' })

    const forceUpdate = useForceUpdate();
    const [old, setOld] = useState([]);
    const [datapoint, setDataPoint] = useState([]);
    const [srflist, setSRFList] = useState([]);
    const [srf, setSRF] = useState('');
    const [srfitems, setSRFItems] = useState([]);
    const [locationtree, setLocationTree] = useState([]);
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quarterly', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    const [location, setLocation] = useState([]);
    const [userList, setUserList] = useState([]);
    const [approverList, setApproverList] = useState([]);
    const [userRawList, setUserRawList] = useState([]);
    const [approverRawList, setApproverRawList] = useState([]);
    const [reviewerList, setReviewerList] = useState([]);
    const [reviewerRawList, setReviewerRawList] = useState([]);
    const [userConfig, setUserConfig] = useState({
        client: "", type: "",
        location: ''
    });
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [selected, setSelected] = useState(1);
    const [cascade, setCascade] = useState("");
    const [showSave, setShowSave] = useState(0);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });
    useEffect(() => {

        let loc = userConfig
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let locuser = [], locapprover = [], locreviewer = [], srf_list = [], capture = [], alluser = []
        let site_list = []

        let REQUEST_URL_WITH_ID_2 = API.LocationOne_UP(selector.id)
        let url_2 = REQUEST_URL_WITH_ID_2 + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        let counter = 0;
        APIServices.get(url_2)
            .then((res) => {
                if (res.status === 200) {

                    counter++



                    res.data.forEach((cat, i) => {

                        if (cat.locationTwos !== undefined) {


                            cat.locationTwos.forEach((topic, j) => {

                                if (topic.locationThrees !== undefined) {

                                    topic.locationThrees.forEach((metric, k) => {


                                        site_list.push({ name: metric.name + " (" + topic.name + ")", id: metric.id, country: { name: cat.name, id: cat.id }, city: { name: topic.name, id: topic.id } })


                                        let capi = capture.findIndex((aa) => { return aa.value === cat.id })
                                        if (capi === -1) {
                                            capture.push({
                                                value: cat.id, text: cat.name, id: cat.id + '_country', children: [
                                                    {
                                                        value: topic.id, text: topic.name, id: topic.id + '_city', children: [
                                                            {
                                                                value: metric.id, text: metric.name, id: metric.id + '_site'


                                                            }
                                                        ]
                                                    }
                                                ]
                                            })

                                        } else {
                                            let topi = capture[capi].children.findIndex((aa) => { return aa.value === topic.id })
                                            if (topi === -1) {
                                                capture[capi].children.push({

                                                    value: topic.id, text: topic.name, id: topic.id + '_city', children: [
                                                        {
                                                            value: metric.id, text: metric.name, id: metric.id + '_site'
                                                        }
                                                    ]

                                                })
                                            } else {
                                                let meti = capture[capi].children[topi].children.findIndex((aa) => { return aa.value === metric.id })
                                                if (meti === -1) {
                                                    capture[capi].children[topi].children.push({


                                                        value: metric.id, text: metric.name, id: metric.id + '_site'


                                                    })
                                                }
                                                else {

                                                }

                                            }
                                        }




                                    })
                                }
                            })
                        }
                    })

                    setSiteList(site_list)
                    loc.location = capture
                    setUserConfig(loc);
                    renderLocationTree(capture)
                }
            })
            .catch((e) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Something went wrong, try again later `,
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
        let uriString = {
            "include": [{ "relation": "newDataPoints" }]

        }



        const promise1 = APIServices.get(API.SRF)
        const promise2 = APIServices.get(API.AssignDCFClient_UP(selector.id))
        const promise3 = APIServices.get(API.UserProfile)
        const promise4 = APIServices.get(API.AssignSRFUser_UP(selector.id))
        Promise.all([promise1, promise2, promise3, promise4]).then(function (values) {

            if (values[1].data.length !== 0) {
                console.log(values[1])
                srf_list = values[0].data.filter(i => values[1].data[0].cf_ids.includes(i.id))
                setSRFList(srf_list);
            }
            console.log(srf_list)
            values[2].data.forEach((item) => {

                if ((item.clientId === selector.id && item.role === 'clientsupplier') || item.id === selector.id) {

                    if (item.id === selector.id) {
                        if (alluser.findIndex(i => i.id === item.id) === -1) {
                            alluser.push({
                                name: 'Enterprise Admin',
                                id: item.id,
                            })
                        }
                        locapprover.push({
                            name: 'Enterprise Admin',
                            id: item.id,
                        })
                    } else {
                        if (item.id !== selector.id) {
                            if (alluser.findIndex(i => i.id === item.id) === -1) {
                                alluser.push({
                                    name: item.information.empname,
                                    id: item.id,
                                })
                            }
                            if (item.information.role !== undefined && item.information.role.reporter) {
                                locuser.push({
                                    name: item.information.empname,
                                    id: item.id,
                                })
                            }
                            if (item.information.role !== undefined &&  item.information.role.reviewer) {
                                locreviewer.push({
                                    name: item.information.empname,
                                    id: item.id,
                                })
                            }
                            if (item.information.role !== undefined  && item.information.role.approver) {
                                locapprover.push({
                                    name: item.information.empname,
                                    id: item.id,
                                })
                            }
                        }

                    }



                }
            });
            setApproverList(locapprover)
            setUserList(locuser);
            setReviewerList(locreviewer);
            setReviewerRawList(locreviewer);
            setUserRawList(locuser);
            setApproverRawList(locapprover);
            let data_ = []
            values[3].data.forEach((item) => {
                console.log(item)
                if (srf_list.findIndex((k) => { return k.id === item.srfId }) !== -1) {


                    item.sites = site_list.filter((i) => { return i.id === item.site })
                    item.frequency = frequency_list[frequency_list.findIndex((k) => { return k.id === item.frequency })]
                    item.srfId = srf_list[srf_list.findIndex((k) => { return k.id === item.srfId })]
                    let user = alluser.findIndex((k) => { return k.id === item.user_id }), reviewer = alluser.findIndex((k) => { return k.id === item.reviewer_id })
                    item.user_id = user === -1 ? { name: 'Not Found' } : alluser[alluser.findIndex((k) => { return k.id === item.user_id })]
                    item.reviewer_id = reviewer === -1 ? { name: 'Not Found' } : alluser[alluser.findIndex((k) => { return k.id === item.reviewer_id })]


                    data_.push(item)
                }
            })

            setAssignedListBK((prev) => ([...prev, ...data_]))
            setAssignedList(data_.filter((i) => { return i.type === 0 }))
            setJustifyList(data_.filter((i) => { return i.type === 1 }))
        })

    }, [selector])
    // useEffect(()=>{console.log(assignedlist)},[assignedlist])
    useEffect(() => {


        getUnAssigned(JSON.parse(JSON.stringify(assignedlistbk)))



    }, [assignedlistbk])

    const getSiteName = (id) => {
        let result = ''
        userConfig.location.forEach((item) => {
            if (item.id === id) {

                result = item.text
            } else {
                item.children.forEach((item2) => {
                    if (item2.id === id) {
                        result = item2.text
                    } else {
                        item2.children.forEach((item3) => {
                            if (item3.id === id) {
                                result = item3.text
                            }
                        })
                    }
                })
            }
        })
        return result
    }
    const getSiteNames = (id) => {


        let loc = JSON.parse(JSON.stringify(sitelist))
        let result = null
        loc.forEach((item3) => {


            if (item3.id === id) {

                result = item3
            }

        })


        return result


    }
    const getUnassignedDCFList = () => {

    }
    const getUnAssigned = (asslist) => {

        let loc = JSON.parse(JSON.stringify(sitelist))
        let srf__list = JSON.parse(JSON.stringify(srflist))
        let loclist = JSON.parse(JSON.stringify(locationList))
        let list = []
        srf__list.forEach((assitem) => {

            list.push({ id: assitem.id, name: assitem.title, site: [], unassign: loclist.map((k) => { return k.id }), unassigns: JSON.parse(JSON.stringify(locationList)) })

        })
        asslist.forEach((unass) => {
            let index = list.findIndex((k) => { return k.id === unass.srfId.id })

            if (index !== -1) {
                list[index].site.push(unass.site)
                let siteindex = list[index].unassigns.findIndex((l) => { return l.id === unass.site })
                if (siteindex !== -1) {
                    list[index].unassigns.splice(siteindex, 1)
                }

                let siteindex2 = list[index].unassign.findIndex((l) => { return l === unass.site })
                if (siteindex2 !== -1) {
                    list[index].unassign.splice(siteindex2, 1)
                }


            }
        })

        setUnAssignedList(list.filter((i) => { return i.unassigns.length !== 0 }))
        setUnAssignedListBK(list.filter((i) => { return i.unassigns.length !== 0 }))
    }
    const getUnAssFilterData = (unassdata) => {
        let sttunass = []
        unassdata.filter((i) => { return i.unassigns.length !== 0 }).forEach((i) => {
            i.unassigns.forEach((j) => {

                sttunass.push({ srf: i.name, srf_id: i.id, site_id: j.id, site: j.country.name + '>' + j.city.name + '>' + j.title })
            })
        })
        return sttunass
    }
    const updateSRFConfig = (obj, val) => {
        let loc = srfconfig;

        if (obj === 'srfId' && val !== null && val !== undefined) {
            let locsite = JSON.parse(JSON.stringify(sitelist))
            let locass = JSON.parse(JSON.stringify(assignedlist)).filter((i) => { return i.srfId.id === val.id })
            let locjus = JSON.parse(JSON.stringify(justifylist)).filter((i) => { return i.srfId.id === val.id })


            locsite.forEach((i) => {
                let index = locass.sort((a, b) => { return b.id - a.id }, 0).findIndex((res) => { return res.site === i.id })
                let index1 = locjus.findIndex((res) => { return res.site === i.id })
                if ((index !== -1 && (typeof locass[index].end_date !== 'string')) || index1 !== -1) {
                    i.disabled = true
                } else {
                    i.disabled = false
                }
            })
            setSiteList(locsite)

            console.log(val, stdlistBk, stdlistBk.filter(i => { i.srf_ids !== null && i.srf_ids.includes(val.id) }))

            loc['site'] = null
        }
        if (obj === 'site') {

            loc[obj] = val === null ? [] : val
        }
        if (obj === 'start_date' || obj === 'end_date') {
            if (loc[obj] === null) {
                loc[obj] = val === undefined ? null : val;
            } else {
                loc[obj] = val === undefined ? null : val;
            }

            console.log(loc[obj], val)
        }
        if (obj !== 'comments' && obj !== 'site' && obj !== 'start_date' && obj !== 'end_date' && obj !== 'frequency' && obj !== 'standard') {

            loc[obj] = val === undefined ? null : val
        } else if (obj === 'comments') {
            loc[obj][0] = val
            loc[obj][1] = moment.utc()
        } else if (obj === 'frequency') {
            loc['start_date'] = null
            loc[obj] = val === undefined ? null : val
            console.log(val.id)
            if (val !== null) {
                if (val.id === 1 || val.id === 6) {
                    setFrequencyMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
                } else if (val.id === 3 || val.id === 4 || val.id === 5) {
                    setFrequencyMonths([0, 3, 6, 9])
                } else {
                    setFrequencyMonths([0, 2, 4, 6, 8, 10])
                }
            }
        }

        // loc[obj]= val

        // if (obj === 'user_id') {

        //     if (val !== undefined) {
        //         let locapprover = JSON.parse(JSON.stringify(approverRawList));
        //         let index = locapprover.findIndex((i) => { return i.id === val.id })
        //         if (index !== -1) {
        //             locapprover.splice(index, 1)
        //             setApproverList(locapprover)

        //         }
        //     } else {
        //         setApproverList(approverRawList)
        //     }
        // } else if (obj === 'approver_id') {
        //     if (val !== undefined) {
        //         let locuser = JSON.parse(JSON.stringify(userRawList));
        //         let index = locuser.findIndex((i) => { return i.id === val.id })
        //         if (index !== -1) {
        //             locuser.splice(index, 1)
        //             setUserList(locuser)

        //         }
        //     } else {
        //         setUserList(userRawList)
        //     }
        // }

        console.log(loc, stdlist)
        setSRFConfig(loc)
        forceUpdate()
    }



    const handleSearch = (event) => {
        setSearch(event)
        let backup = JSON.parse(JSON.stringify(assignedlistbk))
        let filter = backup.filter((item) => { if (item.srfId.title.trim().toLowerCase().includes(event.trim().toLowerCase()) || item.frequency.name.trim().toLowerCase().includes(event.trim().toLowerCase()) || (item.sites.findIndex((i) => { return i.trim().toLowerCase().includes(event.trim().toLowerCase()) })) !== -1) { return item } })

        setAssignedList(filter)
    }

    const search_srf = (event) => {

        let _items = srflist.filter((k) => { return k.title.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setSRFItems(_items);
        forceUpdate()
    }
    const search_site = (event) => {

        let _items = sitelist.filter((k) => { return k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setSiteSuggestion(_items);
        forceUpdate()
    }


    const renderLocationTree = (data, type) => {

        if (document.getElementById('loctree') !== null) {

            $('#loctree').jstree({
                'core': {
                    'check_callback': true,
                    'data':
                        data,
                    "themes": {
                        "icons": false
                    }
                },

                'checkbox': {
                    'keep_selected_style': false
                },
                'search': {
                    'case_insensitive': true,
                    'show_only_matches': true
                },
                'plugins': ['checkbox', 'search']
            });
            $('#loctree').on('check_node.jstree uncheck_node.jstree changed.jstree', function (e,
                data) {
                // Get the list of checked node IDs


                if (data.event) {
                    var id = $('#type-id').val();
                    var type = $('#user-type').val();
                    var selected = $('#loctree').jstree("get_selected");

                    var overall = $('#loctree').jstree(true).get_selected(true);

                    let sorted_selection = overall.filter((k) => { return k.state.disabled === false }).map((i) => { return i.id })
                    setSRFConfig((prev) => ({ ...prev, 'site': sorted_selection }))

                    // Send the list of checked node IDs to the backend server
                    // if (id && type) {
                    //     save_checked_units(id, type, selected, overall);
                    //     save_assignment(id, type, selected, overall);
                    // }
                }
                // console.log(checkedNodeIds);


            });
            $('#loctree').delegate("a", "click", function (event, data) {
                if (event.currentTarget.className.split(' ').length === 3) {
                    $('#loctree').jstree(true).open_all(this, false, false);
                    $(event.target).find('.jstree-checkbox').click()
                } else {
                    $('#loctree').jstree(true).close_all(this, false, false);
                    $(event.target).find('.jstree-checkbox').click()
                }

            })
            $('#loctree').jstree(true).settings.core.data = data;
            if (type === undefined) {
                $('#loctree').jstree(true).refresh();
            }


        }
    }
    const assignSRFSupplier = () => {
        setSubmitted(true)
        let ser = search_
        console.log(srfconfig)
        if ((srfconfig.type === 1 && srfconfig.site !== null && srfconfig.comments.length !== 0 && srfconfig.comments[0].trim().length !== 0) || (srfconfig.type === 0 && srfconfig.approver_id.length !== 0 && srfconfig.start_date !== null && srfconfig.frequency !== null && srfconfig.site !== null && srfconfig.user_id !== null && srfconfig.reviewer_id !== null && srfconfig.approver_id.length !== 0 && !srfconfig.approver_id.includes(srfconfig.user_id) && !srfconfig.approver_id.includes(srfconfig.reviewer_id))) {
            let data = {}
            if (srfconfig.type === 0) {
                data = { end_date: srfconfig.end_date, approver_id: srfconfig.approver_id, reviewer_id: srfconfig.reviewer_id.id, start_date: srfconfig.start_date, user_id: srfconfig.user_id.id, frequency: srfconfig.frequency.id, site: srfconfig.site, srfId: srfconfig.srfId.id, comments: ['', ''], type: 0 }

            } else {
                data = { approver_id: [], user_id: 0, frequency: 0, site: srfconfig.site, srfId: srfconfig.srfId.id, comments: srfconfig.comments, type: 1 }

            }
            setStdList([])
            if (srfconfig.id === undefined) {
                delete data.end_date
                data['site'] = srfconfig.site.id !== undefined ? srfconfig.site.id : srfconfig.site
                data['created_on'] = moment.utc()
                data['creator_id'] = selector.id
                APIServices.post(API.AssignSRFUser_UP(selector.id), data).then((res) => {
                    ser.ass = ''
                    ser.jus = ''
                    ser.unass = ''
                    setSearch_(ser)
                    let srf_list = JSON.parse(JSON.stringify(srflist))
                    let locuser = JSON.parse(JSON.stringify(userRawList))
                    let locapprover = JSON.parse(JSON.stringify(approverRawList))
                    let locreviewer = JSON.parse(JSON.stringify(reviewerRawList))
                    let asslistbk = JSON.parse(JSON.stringify(assignedlistbk))
                    let item = res.data


                    item.frequency = frequency_list[frequency_list.findIndex((k) => { return k.id === item.frequency })]
                    item.srfId = srf_list[srf_list.findIndex((k) => { return k.id === item.srfId })]
                    item.user_id = locuser[locuser.findIndex((k) => { return k.id === item.user_id })]
                    item.reviewer_id = locreviewer[locreviewer.findIndex((k) => { return k.id === item.reviewer_id })]
                    item.sites = sitelist.filter((i) => { return i.id === item.site })
                    asslistbk.push(item)
                    setAssignedListBK((prev) => [...prev, item])
                    setAssignedList(asslistbk.filter((i) => { return i.type === 0 }))
                    setJustifyList(asslistbk.filter((i) => { return i.type === 1 }))

                    setSearch('')
                    forceUpdate()
                    setSubmitted(false)
                    setAssignDialog(false)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Assignment Added Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            } else {
                if (data.end_date === null) {
                    delete data.end_date
                }

                data['modified_on'] = moment.utc()
                data['modifier_id'] = selector.id
                data['site'] = srfconfig.site

                APIServices.patch(API.SRF_User_Edit(srfconfig.id), data).then((res) => {
                    let data2 = srfconfig
                    let assignls = assignedlistbk
                    let srf_list = JSON.parse(JSON.stringify(srflist))
                    let locuser = JSON.parse(JSON.stringify(userList))
                    ser.ass = ''
                    ser.jus = ''
                    ser.unass = ''
                    setSearch_(ser)
                    data2['sites'] = [getSiteNames(data2.site)]
                    assignls[assignls.findIndex((k) => { return k.id === srfconfig.id })] = data2


                    setAssignedListBK((prev) => (assignls))
                    setAssignedList(assignls.filter((i) => { return i.type === 0 }))
                    setJustifyList(assignls.filter((i) => { return i.type === 1 }))

                    setSearch('')
                    forceUpdate()
                    setSubmitted(false)
                    setAssignDialog(false)
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Assignment updated Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            }
        }
    }
    const deleteAssignSRF = async (item) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete DCF Assignment
          </div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            let loc = JSON.parse(JSON.stringify(assignedlistbk));

            APIServices.delete(API.SRF_User_Edit(item.id)).then((a) => {
                let ser = search_
                ser.ass = ''
                ser.unass = ''
                setSearch_(ser)
                loc.splice(loc.findIndex((k) => { return k.id === item.id }), 1)

                setAssignedListBK(loc)
                setAssignedList(loc.filter((k) => { return k.type === 0 }))


                forceUpdate()
            })
        }

    }
    const getJustificationCount = () => {


        return assignedlistbk.filter((i) => { return i.type === 1 }).length
    }
    const getUnassignedCount = () => {
        let count = 0;
        unassignedlist.forEach((item) => {

            count = count + item.unassigns.length
        })
        return count
    }
    const getAssignedCount = () => {
        let total = 0;
        let count = 0;
        if (sitelist.length !== 0) {
            let loctree = JSON.parse(JSON.stringify(sitelist))
            loctree.forEach((item) => {

                total = total + 1

            })
        }
        assignedlist.forEach((item) => {
            console.log(item)
            count += 1
        })
        return (total * srflist.length)
    }
    const editSRFConfig = (items, type) => {


        if (items.start_date !== undefined && items.start_date !== null && (typeof items.start_date === 'string')) {

            items.start_date = DateTime.fromISO(items.start_date, { zone: 'utc' }).toLocal().toJSDate()
        } else if (items.start_date === undefined) {

            items.start_date = null
        }


        if (items.end_date !== undefined && items.end_date !== null && (typeof items.end_date === 'string' || typeof items.end_date === 'object')) {
            items.disable_end = true
            if (typeof items.end_date === 'string') {
                items.end_date = DateTime.fromISO(items.end_date, { zone: 'utc' }).toLocal().toJSDate()

            }
        } else if (items.end_date === undefined) {
            items.end_date = null
            delete items.disable_end
        }
        console.log(items)
        setSRFConfig(items)
        setAssignDialog(true)

        forceUpdate()
    }
    const editSRFConfig_ = (items, type) => {


        items.start_date = moment(items.start_date).toDate()
        setSRFConfig(items)
        setAssignDialog(true)
        let asslist = JSON.parse(JSON.stringify(assignedlistbk))
        let loc = JSON.parse(JSON.stringify(sitelist))
        let srf__list = JSON.parse(JSON.stringify(srflist))

        let assigned = [], complete_site = []
        asslist.forEach((srf) => {

            let srfindex = assigned.findIndex((i) => { return i.id === srf.srfId.id })

            if (srf.srfId.id === items.srfId.id) {

                if (srfindex === -1) {

                    assigned.push({ id: srf.srfId.id, site: srf.site })
                } else {

                    srf.site.forEach((site) => {
                        if (!assigned[srfindex].site.includes(site)) {
                            assigned[srfindex].site.push(site)
                        }
                    })

                }
            }
        })
        loc.forEach((item3) => {

            if (!complete_site.includes(item3.id)) {
                complete_site.push(item3.id)
            }


        })

        let disabled = []

        assigned.forEach((item) => {

            if (item.id === items.srfId.id) {

                if (item.site.length !== complete_site.length) {
                    item.unassign = complete_site.filter(function (n) {
                        return item.site.indexOf(n) === -1;
                    })
                    disabled = complete_site.filter(function (n) {
                        return item.site.indexOf(n) === -1;
                    })
                    item.unassigns = item.unassign.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })

                } else {
                    item.unassign = complete_site.filter(function (n) {
                        return item.site.indexOf(n) !== -1;
                    })
                    disabled = complete_site.filter(function (n) {
                        return item.site.indexOf(n) === -1;
                    })
                    item.unassigns = item.unassign.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })

                }

            }
        })



        forceUpdate()
    }

    const actionBodyTemplate = (rowData) => {

        return (
            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                        onClick={() => {
                            editSRFConfig(rowData, 1)
                        }}
                    />

                    <Button
                        icon="pi pi-trash"
                        className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                        onClick={() => {
                            deleteAssignSRF(rowData)
                        }}
                    />
                </div>
            </>
        )
    }

    const justifySRF = (item) => {

        console.log(item)
    }
    const actionBodyTemplate_ = (rowData) => {
        let index = srflist.findIndex((i) => { return i.id === rowData.id })
        let data = { srfId: srflist[index], site: rowData.unassign, frequency: null, start_date: null, user_id: null, approver_id: null }

        return (
            <>
                <div className="actions" style={{
                    justifyContent: 'space-evenly',
                    display: 'flex'
                }}>
                    <Button
                        label="Assign"

                        onClick={() => {
                            editSRFConfig(data, 1)
                        }}
                    />

                    <Button

                        label="Justify"
                        onClick={() => {
                            justifySRF(rowData)
                        }}
                    />
                </div>
            </>
        )
    }
    const srfNameTemplate = (rowData) => {

        return (<>{rowData.srfId.title}</>)
    }
    const srfNameTemplate_ = (rowData) => {
        let index = srflist.findIndex((i) => { return i.id === rowData.id })

        return (<>{rowData.srf}</>)
    }
    const srfNameTemplate_1 = (rowData) => {
        let index = srflist.findIndex((i) => { return i.id === rowData.srfId.id })
        return (<>{srflist[index].title}</>)
    }

    const userNameTemplate = (rowData) => {
        return (<>{rowData.user_id !== null && rowData.user_id !== undefined && rowData.user_id.name}</>)
    }
    const approverNameTemplate = (rowData) => {

        return (<>{rowData.reviewer_id !== null && rowData.reviewer_id !== undefined && rowData.reviewer_id.id === rowData.user_id.id ? 'Self' : rowData.reviewer_id.name}</>)
    }
    const frequencyTemplate = (rowData) => {
        return (<>{rowData.frequency !== null && rowData.frequency !== undefined && rowData.frequency.name}</>)
    }
    const siteTemplate = (rowData) => {

        return (<>{rowData.sites.map((item) => {
            return <label style={{ display: 'flex', width: '100%' }}>{item.country + ">" + item.city + ">" + item.site}</label>
        })
        }</>)
    }
    const siteTemplate_assign = (rowData) => {
        let txt = 'Site Not Found'

        let index = locationList.findIndex((i) => { return i.id === rowData.site })
        if (index !== -1) {
            txt = locationList[index].title
        }

        return <label style={{ display: 'flex', width: '100%' }}>{txt}</label>


    }
    const siteTemplate_ = (rowData) => {
        let index = srflist.findIndex((i) => { return i.id === rowData.srf_id })
        let val = null
        if (index !== -1) {
            val = srflist[index]
        }
        console.log(rowData)
        return (<div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
            padding: '10px',
            background: 'whitesmoke',
            margin: '5px',
            borderRadius: '10px',
            color: 'black'
        }}>
            <div>
                <label style={{ backgroundColor: (search_.unass.trim().length !== 0 && rowData.site.trim().toLowerCase().includes(search_.unass.trim().toLowerCase())) && 'yellow' }} >{rowData.site} </label>
            </div>
            <div style={{ display: 'flex' }}>
                <span style={{
                    margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                    color: 'white',
                    padding: '5px',
                    borderRadius: '10px'
                }} onClick={() => {
                    let data = { disabled: true, srfId: srflist[index], site: rowData.site_id, frequency: null, start_date: null, user_id: null, reviewer_id: null, approver_id: [], type: 0, comments: [] }
                    editSRFConfig(data, 1)
                }} >Assign</span>
                <span style={{
                    margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                    color: 'white',
                    padding: '5px',
                    borderRadius: '10px'
                }} onClick={() => {
                    let data = { disabled: true, srfId: srflist[index], site: rowData.site_id, frequency: null, start_date: null, user_id: null, reviewer_id: null, approver_id: [], type: 1, comments: [] }
                    editSRFConfig(data, 1)
                }} >Justify</span>
            </div>
        </div>
        )



    }
    const siteTemplate_1 = (rowData) => {
        let index = srflist.findIndex((i) => { return i.id === rowData.srfId.id })

        return (<>{rowData.sites.map((item) => {
            return (<div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
                padding: '10px',
                background: 'whitesmoke',
                margin: '5px',
                borderRadius: '10px',
                color: 'black'
            }}>
                <div>
                    <label  >{item.country.name + ">" + item.city.name + ">" + item.name.split('(')[0]} </label>
                </div>
                <div style={{ display: 'flex' }}>
                    <span style={{
                        margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '10px'
                    }} onClick={() => {
                        let data = { disabled: true, id: rowData.id, srfId: srflist[index], creator_id: rowData.creator_id, created: rowData.created_on, modifier_id: selector.id, modified_on: moment.utc(), site: item.id, frequency: null, start_date: null, user_id: null, approver_id: [], reviewer_id: null, type: 0, comments: ['', ''] }
                        editSRFConfig(data, 1)
                    }} >Assign</span>
                    <span style={{
                        margin: 3, cursor: 'pointer', background: 'cornflowerblue',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '10px'
                    }} onClick={() => {
                        let data = { id: rowData.id, srfId: srflist[index], creator_id: rowData.creator_id, created: rowData.created_on, modifier_id: selector.id, modified_on: moment.utc(), site: item.id, frequency: null, start_date: null, user_id: null, approver_id: [], reviewer_id: null, type: 1, comments: rowData.comments }
                        editSRFConfig(data, 1)
                    }} >View/Edit reason</span>
                </div>
            </div>
            )

        })
        }</>)
    }
    const getSite = (ids) => {
        // ids.map((siteid) => { return getSiteNames(siteid) }).filter((item) => { return item !== null })
        console.log(ids, getSiteNames(ids))
        return [getSiteNames(ids)]

    }
    const renderLocationTreeByDCF = (id) => {
        let asslist = JSON.parse(JSON.stringify(assignedlistbk))
        let loc = JSON.parse(JSON.stringify(userConfig.location))
        let loctree = JSON.parse(JSON.stringify(userConfig.location))
        let assigned = [], complete_site = []
        asslist.forEach((srf) => {

            let srfindex = assigned.findIndex((i) => { return i.id === srf.srfId.id })
            if (srfindex === -1) {

                assigned.push({ id: srf.srfId.id, site: srf.site })
            } else {
                srf.site.forEach((site) => {
                    if (!assigned[srfindex].site.includes(site)) {
                        assigned[srfindex].site.push(site)
                    }
                })

            }

        })
        loc.forEach((item) => {

            item.children.forEach((item2) => {

                item2.children.forEach((item3) => {
                    if (!complete_site.includes(item.id)) {
                        complete_site.push(item.id)
                    }

                    if (!complete_site.includes(item2.id)) {
                        complete_site.push(item2.id)
                    }
                    if (!complete_site.includes(item3.id)) {
                        complete_site.push(item3.id)
                    }

                })

            })

        })
        let data = []
        assigned.forEach((item) => {
            if (item.id === id.id) {
                data = complete_site.filter(function (n) {
                    return item.site.indexOf(n) === -1;
                })

            }

        })
        if (assigned.length === 0) {
            data = complete_site
        }
        loctree.map((item) => {
            if (data.indexOf(item.id) !== -1) {

                item['state'] = { disabled: false }

            } else {
                item['state'] = { disabled: true, "checkbox_disabled": true }
            }
            item.children.forEach((item2) => {
                if (data.indexOf(item2.id) !== -1) {
                    item2['state'] = { disabled: false }
                } else {

                    item2['state'] = { disabled: true, "checkbox_disabled": true }
                }
                item2.children.forEach((item3) => {
                    if (data.indexOf(item3.id) !== -1) {
                        item3['state'] = { disabled: false }
                    } else {
                        item3['state'] = { disabled: true, "checkbox_disabled": true }
                    }
                })
            }
            )


        })

        renderLocationTree(loctree)
    }
    const addNewAssign = () => {

        setSRFConfig((prev) => ({ frequency: null, start_date: null, end_date: null, user_id: null, approver_id: [], reviewer_id: null, site: null, srfId: null, type: 0, comments: [] }));
        setAssignDialog(true);
    }
    const export2Excel = () => {
        let loc = [];
        let obj = { DCF: '' };

        srflist.forEach((l) => {
            obj = {};

            sitelist.forEach((i) => {
                let index = assignedlist.findIndex((k) => { return k.site === i.id && l.id === k.srfId.id })
                let index1 = justifylist.findIndex((k) => { return k.site === i.id && l.id === k.srfId.id })
                obj['DCF'] = l.title
                obj[i.name.split('(')[0]] = (index === -1 && index1 === -1) ? 'Unassigned' : index === -1 ? "Justified - " + justifylist[index1].comments[0] : assignedlist[index].user_id.name + ' / ' + assignedlist[index].reviewer_id.name
            })

            loc.push(obj);

        });

        const path = require('path')
        const os = require('os')
        const homedir = os.homedir()
        const downloadFolder = path.join(homedir, 'Downloads')
        const filename = path.join(downloadFolder, 'hellow.xlsx')
        const ws = XLSX.utils.json_to_sheet(loc);
        const range = { s: { c: 0, r: 0 }, e: { c: sitelist.length, r: srflist.length } }
        //         const cell = XLSX.utils.sheet_to_json(ws,{range});

        // cell.forEach(c =>{
        //    c.s ={fill:{fgColor:{rgb:'FFFF0000'}}}
        // })
        // XLSX.utils.sheet_add_json(ws,cell,{skipHeader:true,origin:range.s})
        const wb = {
            Sheets: { ["DCF Assignment"]: ws },
            SheetNames: ["DCF Assignment"],
        };
        const worksheet = wb.Sheets['DCF Assignment']
        // {style:{type:'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }}
        const fill = { type: 'pattern', patternType: 'solid', fgColor: { rgb: 'FFFF0000' } }
        let style = []
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {

                let cellAddress = XLSX.utils.encode_cell({ r: row, c: col })
                if (worksheet[cellAddress].v === 'Unassigned') {
                    style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: 'FFFF0000' })

                } else if (worksheet[cellAddress].v.toLowerCase().includes('justified -')) {
                    style.push({ range: XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }) + ':' + XLSX.utils.encode_range({ s: cellAddress, e: cellAddress }), color: '097969' })

                }
            }
        }
        //  XLSX.writeFile(wb,filename)

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        XlsxPopulate.fromDataAsync(data).then((workbook) => {
            workbook.sheets().forEach((sheet) => {

                sheet.range(`A1:${XLSX.utils.encode_col(sitelist.length)}1`).style({

                    fontColor: 'ffffff',
                    fill: '6082B6'

                });
                style.forEach((cell) => {

                    sheet.range(cell.range).style({

                        fontColor: cell.color,
                        bold: cell.color === 'FFFF0000' ? false : true

                    });
                })


            })
            workbook
                .outputAsync()
                .then((workbookBlob) =>
                    FileSaver.saveAs(URL.createObjectURL(workbookBlob), "DCFAssignment.xlsx")
                )

            // sheet.range(dataInfo.theadRange1).style({
            //     fill: "808080",
            //     bold: true,
            //     horizontalAlignment: "center",
            //     fontColor: "ffffff",
            //   });
        })
        // FileSaver.saveAs(data, "DCFAssignment.xlsx");
    }
    const searchAssignment = (e) => {
        let ser = search_
        ser.ass = e.target.value
        setSearch_(ser)
        let up = []
        assignedlistbk.forEach((k) => {

            if (k.srfId.title.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) && k.type === 0) {
                up.push(k)

            }

        })


        setAssignedList(up)
        forceUpdate()
    }
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getTotalCard = (sd, ed, f) => {
        let count = 0
        console.log(sd, ed)
        let betweenMonths = []
        if (typeof sd === 'object' && typeof ed === 'object') {
            sd = DateTime.fromJSDate(sd);
            ed = DateTime.fromJSDate(ed);
            while (sd <= ed) {

                betweenMonths.push(sd.toFormat('LLL-yyyy'));
                sd = sd.plus({ months: 1 })

            }
        }




        return splitArray(betweenMonths, f === 4 ? 12 : f === 5 ? 6 : f === 6 ? 1 : f).length
    }
    const searchJustify = (e) => {
        let ser = search_
        ser.jus = e.target.value
        setSearch_(ser)
        let up = []
        assignedlistbk.forEach((k) => {

            if (k.srfId.title.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) && k.type === 1) {
                up.push(k)

            }

        })


        setJustifyList(up)
        forceUpdate()
    }

    const searchUnassigned = (e) => {
        let ser = search_
        ser.unass = e.target.value
        setSearch_(ser)
        console.log(unassignedlist)
        let up = []
        unassignedlistBK.forEach((k) => {

            if (k.name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) || k.unassigns.filter((l) => { return l.name.toLowerCase().trim().includes(e.target.value.trim().toLowerCase()) }).length !== 0) {
                up.push(k)

            }

        })


        setUnAssignedList(up)
        forceUpdate()
    }
    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        console.log(month)
        return frequencyMonths.includes(month) ? <span title={tooltipText}>{shortMonth} </span> : null
    };
    const items = [
        { label: 'Assigned' + ' (' + getUniqueObjects(assignedlistbk.filter((i) => { return i.type === 0 })).length + '/' + getAssignedCount() + ')', command: () => setSelected(1) },
        { label: 'To be Assigned' + ' (' + getUnassignedCount() + ')', command: () => setSelected(2) },
        { label: 'Intentionally not Assigned' + ' (' + justifylist.length + ')', command: () => setSelected(3) },

    ];
    function getUniqueObjects(array) {
        console.log(array)
        // Create an empty Set to store unique combinations of srfId and site
        const uniqueSet = new Set();

        // Filter the array of objects to get unique objects
        const uniqueObjects = array.filter(obj => {
            // Generate a unique key based on srfId and site
            const key = obj.srfId.id + '-' + obj.site;

            // Check if the key is already present in the Set
            if (uniqueSet.has(key)) {
                // If key is already present, return false to filter out the object
                return false;
            } else {
                // If key is not present, add it to the Set and return true to keep the object
                uniqueSet.add(key);
                return true;
            }
        });

        return uniqueObjects;
    }
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card" style={{ height: 'calc(100vh - 9rem)', marginTop: '0rem' }}>
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 10
                    }} >SRF Assignment</div>
                    <div>
                        <TabMenu model={items} />
                        {/* <div className="col-12" style={{ display: 'flex', flexDirection: 'row' }} >
                            <div className='col-4' >
                                <div style={{
                                    padding: 5,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',
                                    border: selected === 0 ? '3px solid blue' : 'none'

                                }} onClick={() => { setSelected(0) }}>

                                    <label style={{ margin: 5 }}>Assigned</label>
                                    <label style={{ margin: 5 }}>{assignedlistbk.filter((i) => { return i.type === 0 }).length + '/' + getAssignedCount()}</label>
                                </div>
                            </div>
                            <div className='col-4' >
                                <div style={{
                                    padding: 5,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',
                                    border: selected === 1 ? '3px solid blue' : 'none'
                                }} onClick={() => { setSelected(1) }}>
                                    <label style={{ margin: 5 }}>To be Assigned</label>
                                    <label style={{ margin: 5 }}>{getUnassignedCount()}</label>
                                </div>
                            </div>
                            <div className='col-4' >
                                <div style={{
                                    padding: 5,
                                    background: 'gainsboro',
                                    borderRadius: '10px',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    color: 'black',
                                    border: selected === 2 ? '3px solid blue' : 'none'
                                }} onClick={() => { setSelected(2) }}>
                                    <label style={{ margin: 5 }}>Intentionally not Assigned</label>
                                    <label style={{ margin: 5 }}>{justifylist.length}</label>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {selector.role === "clientadmin" ?
                        <div>
                            {selected === 1 && <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    {/* <div className="col-6" >
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText value={search} onChange={(e) => { handleSearch(e.target.value) }} style={{ width: 300 }} placeholder="Search by srf/site/frequency" />
                                                </span>
                                            </div> */}
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div className="col-6" >
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText value={search_.ass} onChange={searchAssignment} style={{ width: 350 }} placeholder="Search SRF" />
                                            </span>
                                        </div>
                                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                            {/* <Button style={{ marginRight: 20 }} onClick={() => { export2Excel() }}> Export Report</Button> */}
                                            <Button onClick={() => { addNewAssign() }}> + Assign SRF</Button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable
                                    value={assignedlist}
                                    dataKey="id"
                                    paginator
                                    scrollable
                                    style={{ height: 0 }}
                                    rows={20}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} assignment"

                                    emptyMessage="No Assignment found."

                                    responsiveLayout="scroll"
                                >


                                    <Column
                                        field="srfId"
                                        header="SRF"
                                        body={srfNameTemplate}
                                        sortable
                                        headerClassName="stickyToTopTableHeaders"
                                        headerStyle={{
                                            width: "25%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="site"
                                        header="Site"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={siteTemplate_assign}

                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="frequency"
                                        header="Frequency"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={frequencyTemplate}
                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >
                                    </Column>
                                    <Column
                                        field="user_id"
                                        header="Submitter"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={userNameTemplate}
                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="approver_id"
                                        header="Reviewer"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={approverNameTemplate}
                                        headerStyle={{
                                            width: "15%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column header="Action"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={actionBodyTemplate}
                                        headerStyle={{
                                            background: '#EEF2FF', width: '15%'
                                        }} > </Column>
                                </DataTable>
                            </div>}


                            {selected === 2 && <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>

                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div className="col-6" >
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText value={search_.unass} onChange={searchUnassigned} style={{ width: 350 }} placeholder="Search SRF/Site/City" />
                                            </span>
                                        </div>
                                        <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                            {/* <Button style={{ marginRight: 20 }} onClick={() => { export2Excel() }}> Export Report</Button> */}

                                            <Button onClick={() => { addNewAssign() }}> + Assign SRF</Button>
                                        </div>
                                    </div>
                                </div>
                                <DataTable


                                    value={getUnAssFilterData(unassignedlist)}
                                    dataKey="id"
                                    paginator
                                    scrollable
                                    style={{ height: 0, marginTop: 30 }}
                                    rows={5}
                                    rowsPerPageOptions={[10, 20, 50]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} SRF"

                                    emptyMessage="No UnAssignment SRF found."

                                    responsiveLayout="scroll"
                                >


                                    <Column
                                        field="srfId"
                                        header="SRF"
                                        body={srfNameTemplate_}

                                        headerClassName="stickyToTopTableHeaders"
                                        headerStyle={{
                                            width: "30%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="site"
                                        header="Location"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={siteTemplate_}

                                        headerStyle={{
                                            width: "70%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>





                                </DataTable>
                            </div>}

                            {selected === 3 && <div>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    {/* <div className="col-6" >
                                                <span className="p-input-icon-left">
                                                    <i className="pi pi-search" />
                                                    <InputText value={search} onChange={(e) => { handleSearch(e.target.value) }} style={{ width: 300 }} placeholder="Search by srf/site/frequency" />
                                                </span>
                                            </div> */}
                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <div className="col-6" >
                                            <span className="p-input-icon-left">
                                                <i className="pi pi-search" />
                                                <InputText value={search_.jus} onChange={searchJustify} style={{ width: 350 }} placeholder="Search SRF" />
                                            </span>
                                        </div>

                                    </div>
                                </div>
                                <DataTable
                                    scrollable

                                    value={justifylist}
                                    dataKey="id"
                                    paginator
                                    rows={20}
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} assignment"
                                    style={{ height: 0, marginTop: 30 }}
                                    emptyMessage="No Assignment found."

                                    responsiveLayout="scroll"
                                >


                                    <Column
                                        field="srfId"
                                        header="SRF"
                                        body={srfNameTemplate_1}
                                        sortable
                                        headerClassName="stickyToTopTableHeaders"
                                        headerStyle={{
                                            width: "30%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>
                                    <Column
                                        field="site"
                                        header="Sites"
                                        headerClassName="stickyToTopTableHeaders"
                                        body={siteTemplate_1}

                                        headerStyle={{
                                            width: "70%",
                                            minWidth: "8rem", background: '#EEF2FF'
                                        }}
                                    >

                                    </Column>





                                </DataTable>
                            </div>}






                        </div>
                        :
                        <div className="card col-12">You have no rights to access this page</div>

                    }
                </div>
                <Dialog
                    visible={assigndialog}
                    style={{
                        width: "75%",
                    }}
                    header={(srfconfig.type === 0 ? "Assign " : "Justification for ") + ((srfconfig.srfId !== null && srfconfig.srfId.title !== undefined) ? srfconfig.srfId.title : 'SRF')}
                    modal
                    className="p-fluid"

                    onHide={() => { setAssignDialog(false) }}
                >
                    <div>

                        <div style={{ flexDirection: 'row', display: 'flex', height: '67vh' }}>

                            <div className="col-6">
                                {(srfconfig.id === undefined && (srfconfig.disabled === undefined || !srfconfig.disabled)) &&
                                    <div style={{ marginBottom: 20 }}>
                                        <label
                                            style={{
                                                marginRight: 10,
                                            }}
                                        >
                                            Select SRF
                                        </label>
                                        <AutoComplete field="title" forceSelection disabled={srfconfig.id !== undefined || srfconfig.disabled} value={srfconfig.srfId} suggestions={srfitems} completeMethod={search_srf} onChange={(e) => { console.log(e.value); setSRF(e.value); updateSRFConfig('srfId', e.value); }} dropdown />

                                    </div>
                                }
                                {submitted && (srfconfig.srfId === null || srfconfig.srfId === undefined) &&
                                    (
                                        <small
                                            className="p-invalid"
                                            style={{
                                                color: "red", marginLeft: '22%'
                                            }}
                                        >

                                            select SRF
                                        </small>
                                    )}
                                {(srfconfig.id === undefined && (srfconfig.disabled === undefined || !srfconfig.disabled)) ?
                                    <>
                                        {srfconfig.srfId !== null &&
                                            <>
                                                <div style={{ marginBottom: 20 }}>
                                                    <label

                                                    >
                                                        Select Site
                                                    </label>

                                                    <AutoComplete forceSelection field="name" value={srfconfig.site} suggestions={sitesuggestion} completeMethod={search_site} onChange={(e) => updateSRFConfig('site', e.value)} dropdown />


                                                </div>
                                                {(submitted && (srfconfig.site === null || srfconfig.site.length === 0)) &&
                                                    (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red", marginLeft: '22%'
                                                            }}
                                                        >

                                                            select site
                                                        </small>
                                                    )}
                                                {srfconfig.type === 0 &&
                                                    <>


                                                        {srfconfig.end_date !== null && srfconfig.id !== undefined && <div>
                                                            <label>Total Card :  {getTotalCard(srfconfig.start_date, srfconfig.end_date, srfconfig.frequency.id)}</label>
                                                        </div>}
                                                    </>
                                                }

                                            </>
                                        }
                                    </>
                                    :
                                    <div>
                                        <label style={{ fontWeight: 'bold' }}>Selected Location</label>
                                        {getSite(srfconfig.site).map((item) => {

                                            return (<div>
                                                <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                                                    <label>Country : {item.country.name}</label>
                                                    <label>City : {item.city.name}</label>
                                                    <label>Site : {item.name.split('(')[0]}</label>

                                                </div>
                                            </div>)
                                        })}
                                        {srfconfig.type === 0 && stdlist.length !== 0 &&
                                            <>

                                                {srfconfig.end_date !== null && srfconfig.id !== undefined && <div>
                                                    <label>Total Card :  {getTotalCard(srfconfig.start_date, srfconfig.end_date, srfconfig.frequency.id)}</label>
                                                </div>}
                                            </>
                                        }
                                    </div>
                                }

                            </div>
                            {(srfconfig.srfId !== null && srfconfig.type === 0) ?
                                <>
                                    <div className="col-6">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10,
                                                        }}
                                                    >
                                                        Set Data Frequency
                                                    </label>
                                                </div>
                                                <div className="col-7 ddf">
                                                    <Dropdown disabled={srfconfig.id !== undefined && srfconfig.disabled === undefined} style={{ width: '100%', height: '100%' }} value={srfconfig.frequency} options={frequency_list} onChange={(e) => updateSRFConfig("frequency", e.value)} optionLabel="name" placeholder="Select frequency" />

                                                </div>



                                            </div>
                                            {submitted && (srfconfig.frequency === null) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Frequency
                                                    </small>
                                                )}
                                            {srfconfig.frequency !== null && <>
                                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div className="col-5">
                                                        <label
                                                            style={{
                                                                margin: 10,
                                                            }}
                                                        >
                                                            Start Date
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <ReactDatePicker
                                                            disabled={srfconfig.id !== undefined && srfconfig.start_date !== null}
                                                            selected={srfconfig.start_date}
                                                            onSelect={(e) => { updateSRFConfig("start_date", e) }}
                                                            renderMonthContent={renderMonthContent}
                                                            showMonthYearPicker

                                                            dateFormat="MM/yyyy"
                                                        />
                                                        {/* <Calendar  dateFormat="M/yy" view='month' maxDate={moment(moment.utc()).toDate()} value={srfconfig.start_date} placeholder="date" onChange={(e) => {
                                                        updateSRFConfig("start_date", e.value);
                                                    }} /> */}

                                                    </div>



                                                </div>
                                                {submitted && (srfconfig.start_date === null) &&
                                                    (
                                                        <small
                                                            className="p-invalid"
                                                            style={{
                                                                color: "red", marginLeft: '22%'
                                                            }}
                                                        >

                                                            set start date
                                                        </small>
                                                    )}
                                            </>}
                                            {srfconfig.id !== undefined && srfconfig.start_date !== null && srfconfig.disabled !== true && <>
                                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <div className="col-5">
                                                        <label
                                                            style={{
                                                                margin: 10,
                                                            }}
                                                        >
                                                            End Date
                                                        </label>
                                                    </div>
                                                    <div className="col-7">
                                                        <ReactDatePicker
                                                            disabled={((srfconfig.id === undefined || srfconfig.disable_end !== undefined))}
                                                            selected={srfconfig.end_date}
                                                            onSelect={(e) => { updateSRFConfig("end_date", e) }}
                                                            minDate={moment(srfconfig.start_date).toDate()}
                                                            showMonthYearPicker

                                                            dateFormat="MM/yyyy"
                                                        />
                                                        {/* <Calendar  dateFormat="M/yy" view='month' maxDate={moment(moment.utc()).toDate()} value={srfconfig.start_date} placeholder="date" onChange={(e) => {
                                                        updateSRFConfig("start_date", e.value);
                                                    }} /> */}

                                                    </div>



                                                </div>

                                            </>}
                                            <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10,
                                                        }}
                                                    >
                                                        Assign Reporter
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <Dropdown showClear value={srfconfig.user_id} disabled={srfconfig.id !== undefined ? srfconfig.disabled === undefined ? true : srfconfig.disabled !== true : false} style={{ width: '100%' }} options={userList} onChange={(e) => updateSRFConfig("user_id", e.value)} optionLabel="name" filter filterBy="name" placeholder="Select Reporter" />

                                                </div>



                                            </div>
                                            {submitted && (srfconfig.user_id === null) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Reporter
                                                    </small>
                                                )}
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10
                                                        }}
                                                    >
                                                        Assign Reviewer
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <Dropdown showClear style={{ width: '100%' }} disabled={srfconfig.id !== undefined ? srfconfig.disabled === undefined ? true : srfconfig.disabled !== true : false} value={srfconfig.reviewer_id} options={reviewerList} onChange={(e) => updateSRFConfig("reviewer_id", e.value)} optionLabel="name" filter filterBy="name" placeholder="Select Reviewer" />

                                                </div>



                                            </div>
                                            {submitted && (srfconfig.reviewer_id === null) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Reviewer
                                                    </small>
                                                )}
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <div className="col-5">
                                                    <label
                                                        style={{
                                                            margin: 10
                                                        }}
                                                    >
                                                        Assign Approver
                                                    </label>
                                                </div>
                                                <div className="col-7">
                                                    <MultiSelect display="chip" disabled={srfconfig.id !== undefined ? srfconfig.disabled === undefined ? true : srfconfig.disabled !== true : false} value={srfconfig.approver_id} onChange={(e) => updateSRFConfig("approver_id", e.value)} options={approverList} optionLabel="name" optionValue="id"
                                                        filter={true} placeholder="Select Category" className="w-full " panelClassName={'hidefilter'} />

                                                </div>



                                            </div>
                                            {submitted && (srfconfig.approver_id === null || srfconfig.approver_id.length === 0) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Select Approver
                                                    </small>
                                                )}
                                            {submitted && (srfconfig.approver_id !== null && srfconfig.user_id !== null && srfconfig.reviewer_id !== null && srfconfig.approver_id.includes(srfconfig.user_id) && srfconfig.approver_id.includes(srfconfig.reviewer_id)) &&
                                                (
                                                    <small
                                                        className="p-invalid"
                                                        style={{
                                                            color: "red", marginLeft: '22%'
                                                        }}
                                                    >

                                                        Reporter/Reviewer should not be assigned as  Approver
                                                    </small>
                                                )}
                                        </div>
                                    </div>

                                </>
                                : srfconfig.srfId !== null &&
                                <>

                                    <div className="col-6" style={{ padding: 10, marginTop: 15 }}>
                                        <label className="col-12">Comments : <span style={{ color: 'red' }}>*</span></label>

                                        <div className="  flex justify-content-center">
                                            <InputTextarea style={{ height: '40vh', overflow: 'auto', resize: 'none' }} value={srfconfig.comments[0]} autoResize={false} onChange={(e) => { updateSRFConfig('comments', e.target.value) }} rows={15} cols={30} />
                                        </div>
                                        {submitted && (srfconfig.comments.length === 0 || srfconfig.comments[0].trim().length === 0) &&
                                            (
                                                <small
                                                    className="p-invalid"
                                                    style={{
                                                        color: "red", marginLeft: '5%'
                                                    }}
                                                >

                                                    Comments required
                                                </small>
                                            )}

                                    </div>

                                </>
                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={() => { assignSRFSupplier() }}>Save</Button>
                        </div>


                    </div>
                </Dialog>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientSupplierSRFAssignment, comparisonFn);
