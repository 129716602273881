import Axios from "axios";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../constants/api_url";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import APIServices from "../../service/APIService";
const ClientIndicators = () => {

    const [indicator, setIndicator] = useState([])
    const [indicatorbk, setIndicatorBK] = useState([])
    const [rawData, setRawData] = useState([])
    const [category, setCategory] = useState(null)
    const [tagsdialog, setTagsDialog] = useState(false);
    const [tags, setTags] = useState([])
    const [search, setSearch] = useState('')
    const [catselection, setCategorySelection] = useState(null)
    const forceUpdate = useForceUpdate()
    const selector = useSelector((state) => state.user.userdetail)
    useEffect(() => {
        let category_string = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }
        APIServices.get(API.Categories + `?filter=${encodeURIComponent(JSON.stringify(category_string))}`).then((res) => {
            let metrics = res.data
            let data = [], category_ = [{ name: 'All', id: 0 }], rawData = []
            APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res2) => {


                res.data.forEach((cat) => {

                    if (cat.newTopics !== undefined) {
                        cat.newTopics.forEach((top) => {
                       
                            if (top.newMetrics !== undefined) {

                                top.newMetrics.forEach((met) => {

                                    if (res2.data[0].metric_ids.includes(met.id)) {
                                        console.log(met.id,'in')
                                        if (category_.findIndex((l) => { return l.id === cat.id }) === -1) {
                                            category_.push({ name: cat.title, id: cat.id })
                                        }
                                        rawData.push({ cat_id: cat.id, top_id: top.id, met_id: met.id })
                                        data.push({ ...met, topic_title: top.title, cat_id: cat.id })
                                    }
                                })
                            }
                        })
                    }
                })


                // let data = res.data[0].metric_ids.map((i) => { return metrics.filter((k) => { return k.id === i })[0] })
                setCategory(category_)
                setRawData(rawData)
                setCategorySelection({ name: 'All', id: 0 })
                setIndicator(data)
                setIndicatorBK(data)
                forceUpdate()
            })


        })
        // APIServices.get(API.Metric).then((res) => {
        //     let metrics = res.data
        //     let data = [], category_ = [{ name: 'All', id: 0 }]
        //     APIServices.get(API.AssignDCFClient_UP(selector.id)).then((res) => {
        //         res.data.forEach((item) => {
        //             item.metric_ids.forEach((item2) => {
        //                 console.log(item2)
        //                 let index = metrics.findIndex((k) => { return k.id === item2 })
        //                 if (index !== -1) {

        //                     data.push(metrics[index])
        //                 }
        //             })
        //         })
        //         // let data = res.data[0].metric_ids.map((i) => { return metrics.filter((k) => { return k.id === i })[0] })
        //         console.log(data)
        //         setIndicator(data)
        //         setIndicatorBK(data)

        //     })
        // })

    }, [])
    const openTags = (tag) => {

        setTags([tag[0].tags1, tag[0].tags2, tag[0].tags3])
        setTagsDialog(true)

    }
    const checkTags = (tag, val) => {
        let check = false
        tag.forEach((i) => {
            if (i.toLowerCase().includes(val)) {
                check = true
            }
        })
        return check
    }
    const searchMetric = (e) => {
        let loc = JSON.parse(JSON.stringify(indicatorbk))
        let locs = loc.filter((k) => { return (k.cat_id === catselection.id || catselection.id === 0) })
        setIndicator(locs.filter((i) => { return (i.title.toLowerCase().includes(e.target.value.toLowerCase()) || (i.data1 !== null && i.data1.length !== 0 && checkTags(i.data1[0].tags, e.target.value.toLowerCase())) || i.topic_title.toLowerCase().includes(e.target.value.toLowerCase())) }))
        
        setSearch(e.target.value)

        forceUpdate()
    }
    const updateCategory = (val) => {
        setCategorySelection(val)

        let locs = JSON.parse(JSON.stringify(indicatorbk))

        if (val.id !== 0) { setIndicator(locs.filter((k) => { return k.cat_id === val.id })) } else {
            setIndicator(locs)
        }
        setSearch('')
        forceUpdate()
    }
    return (
        <div className="grid" >
            <div className="col-12" >
                <div className="card" style={{ height: 'calc(100vh - 9rem)' }} >
                    <div style={{
                        fontSize: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: '600', marginBottom: 20
                    }}>Material Topics & Indicators({indicator.length}) </div>
                    <div className="grid">

                        <div className="col-6 grid" style={{
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }} >
                            <span className="col-3">Select Category</span>

                            <Dropdown className="col-9" style={{ width: 300, padding: 0 }} options={category} value={catselection} optionLabel="name" onChange={(e) => { updateCategory(e.value) }} />

                        </div>
                        <div className="col-6" style={{
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }} >

                            <span className="p-input-icon-left" >
                                <i className="pi pi-search" />
                                <InputText value={search} onChange={searchMetric} style={{ width: 300 }} placeholder="Search by Topic/Indicator/Tags" />
                            </span>
                        </div>
                    </div>
                    <div>
                        {indicator.length !== 0 ?
                            <div style={{ overflow: 'auto', height: '63vh', marginTop: 20 }}>
                                <DataTable
                                    value={indicator}
                                    style={{

                                        overflow: 'auto'
                                    }}
                                    scrollable
                                    paginator
                                    rows={20}
                                    rowsPerPageOptions={[10,20,50,100]}
                                    className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} indicators"
                                >
                                    <Column header="Topic" field='topic_title'> </Column>
                                    <Column header="Indicator" field='title'> </Column>
                                    <Column header="Reference" body={(rowData) => { return <>{Array.isArray(rowData.data1) !== null && rowData.data1[0].tags1 !== undefined && <div className="col-2" ><i className="material-icons" style={{ color: 'green', cursor: 'pointer' }} onClick={() => { openTags(rowData.data1) }}>visibility</i></div>} </> }}> </Column>

                                </DataTable>
                                {/* {indicator.map((item) => {

                                    return (
                                        <div className="col-12">
                                            <div className="grid" style={{
                                                margin: '5px',
                                                background: '#005284', color: 'white',
                                                borderRadius: '10px',
                                                padding: '10px'
                                            }}>
                                                <div className="col-10">{item.title}</div>
                                                <div className="col-2" ><label style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { openTags(item.data1) }}>view reference</label></div>
                                            </div>
                                        </div>
                                    )
                                })} */}
                            </div>
                            :
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '20px'
                            }}>{search.trim().length === 0 ? '  No Indicators found' : `No Indicator found with "${search}"`}</div>

                        }
                    </div>
                </div>
            </div>
            <Dialog
                visible={tagsdialog}
                style={{
                    width: "50%", height: '50%'
                }}
                header='Impacted Standards / Frameworks'
                modal
                className="p-fluid"

                onHide={() => { setTagsDialog(false) }}
            >
                <div style={{ margin: 10 }}>


                    <div>
                        {tags.map((item, i) => {
                            if (item.length !== 0) {

                                return (
                                    <div style={{ padding: 10 }}>
                                        <label style={{
                                            width: '100%',
                                            display: 'flex',
                                            marginBottom: '20px'
                                        }}> {i === 0 ? 'Must Have' : i === 1 ? 'Progressive' : 'Advanced'}</label>
                                        {item.map((tag) => {
                                            return (
                                                <text style={{ background: 'gray', padding: 5, margin: 5, borderRadius: 5, color: 'white' }}>{tag}</text>
                                            )
                                        })

                                        }

                                    </div>
                                )

                            }

                        })

                        }
                    </div>


                </div>




            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ClientIndicators, comparisonFn);
