import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditText } from "react-edit-text";
import EdiText from 'react-editext'
import { AutoComplete } from "primereact/autocomplete";
import Axios from "axios";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from 'primereact/multiselect';
import Swal from "sweetalert2";
import { Chips } from 'primereact/chips';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from "primereact/dropdown";
import { API } from "../../constants/api_url";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { Checkbox } from "primereact/checkbox";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { country } from "../../constants/countrylist";
import moment from "moment";
import { InputNumber } from "primereact/inputnumber";
import { Tag } from 'primereact/tag';
import { FileUpload } from "primereact/fileupload";
import APIServices from "../../service/APIService";
const { DateTime } = require('luxon')
const EFLibrary = () => {

    const forceUpdate = useForceUpdate();
    const stdref = useRef(null)
    const [tieroptions, setTierOptions] = useState({ tier0: [], tier1: [], tier2: [], tier3: [], tier4: [] })
    const tier1ref = useRef([]);
    const tier2ref = useRef([]);
    const selector = useSelector((state) => state.user.userdetail);
    const [stdmodal, setStdModal] = useState(false)
    const [efmodal, setEFModal] = useState(false)
    const [editormodal, setEditorModal] = useState(false)
    const [efcatmodal, setEFCatModal] = useState(false)
    const [importdialog, setImportDialogModal] = useState(false)
    const [importdata, setImportData] = useState([])
    const [cliptext, setClipText] = useState('<p></p>')
    const [stdname, setStdName] = useState({ title: '' })
    const [newstdate, setStdDate] = useState({ start: null, end: null })
    const [datemodal, setDateModal] = useState(false)
    const fileInputRef = useRef(null);
    const [selectedstd, setSelectedStd] = useState({ id: null })
    const [data, setData] = useState([])
    const [category, setCategory] = useState([])
    const [efcat, setEFCat] = useState({ title: '' })
    const [submitted, setSubmitted] = useState(false)
    const [expandedRows, setExpandedRows] = useState(null);
    const [efstdlist, setEFStdList] = useState([])
    const [actstdid, setActStdID] = useState(null)
    const [actEFid, setActEFID] = useState(null)
    const [actcatid, setActCatID] = useState(null)
    const [stdconfigmodal, setStdConfigModal] = useState(false)
    const [actStdItem, setActiveStdItem] = useState({ title: '', public: false, client_ids: [], dcf_ids: [] })
    const [actdateid, setActDateID] = useState(null)
    const [dcflist, setDcfList] = useState(null)
    const clientList = useSelector(state => state.userlist.clientList)

    const [newstandards, setNewStandards] = useState({ category: null, source: '', description: '', version: '', status: '', last_update: null, link: '', status: null })
    const [newef, setNewEF] = useState({ description: '', unit: '', ef_id: '', subcategory1: 0, subcategory2: 0, subcategory3: 0, subcategory4: 0, methodology: '', applicability: '', quality: '', notes: '', co2e: 0, co2: 0, ch4: 0, n2o: 0, created_by: '', checked_by: '' })
    useEffect(() => {

        let efdates = {
            "include": ["newEfDates"]

        }
        APIServices.get(API.DCF).then((res) => {
            setDcfList(res.data.filter(i => i.type !== 2))
        })
        APIServices.get(API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efdates))}`).then((res) => {
            res.data.forEach((item) => {
                if (item.newEfDates === undefined) {
                    item.newEfDates = []
                }
            })
            setEFStdList(res.data)
        })


    }, []);
    const updateStdDate = (obj, val) => {
        let loc = newstdate
        loc[obj] = val
        setStdDate(loc)
        forceUpdate()
    }
    const updateStd = (obj, val) => {
        let loc = newstandards
        console.log(val, obj)
        loc[obj] = val
        setNewStandards(loc)
        forceUpdate()
    }
    const updateEF = (obj, val) => {
        let loc = newef

        loc[obj] = val
        if (obj === 'subcategory1') {
            let temp = JSON.parse(JSON.stringify(tieroptions))
            let index = temp.tier1.findIndex(x => x.id === val)
            if (index !== -1) {
                console.log(temp)
                let temp_ = temp.tier1[index].newEfSubcategory2s === undefined ? [] : temp.tier1[index].newEfSubcategory2s
                temp.tier2 = temp_
                temp.tier3 = []
                temp.tier4 = []
                setTierOptions(temp)
            } else {
                temp.tier2 = []
                temp.tier3 = []
                temp.tier4 = []
                setTierOptions(temp)
            }
            loc['subcategory2'] = 0
            loc['subcategory3'] = 0
            loc['subcategory4'] = 0
        } else if (obj === 'subcategory2') {
            let temp = JSON.parse(JSON.stringify(tieroptions))
            let index = temp.tier2.findIndex(x => x.id === val)
            if (index !== -1) {
                console.log(temp)
                let temp_ = temp.tier2[index].newEfSubcategory3s === undefined ? [] : temp.tier2[index].newEfSubcategory3s

                temp.tier3 = temp_
                temp.tier4 = []
                setTierOptions(temp)
            } else {

                temp.tier3 = []
                temp.tier4 = []
                setTierOptions(temp)
            }

            loc['subcategory3'] = 0
            loc['subcategory4'] = 0
        } else if (obj === 'subcategory3') {
            let temp = JSON.parse(JSON.stringify(tieroptions))
            let index = temp.tier3.findIndex(x => x.id === val)
            if (index !== -1) {
                console.log(temp)
                let temp_ = temp.tier3[index].newEfSubcategory4s === undefined ? [] : temp.tier3[index].newEfSubcategory4s

                temp.tier4 = temp_
                setTierOptions(temp)
            } else {

                temp.tier4 = []
                setTierOptions(temp)
            }


            loc['subcategory4'] = 0
        }
        setNewEF(loc)
        console.log(newef, tieroptions)
        forceUpdate()
    }
    const saveCategory = () => {

    }

    const textEditor = (options) => {

        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };
    const numberEditor = (options) => {
        return <InputText min={0} type={'number'} value={options.value} onChange={(e) => options.editorCallback(parseFloat(e.target.value))} />

    }
    const statusEditor = (options) => {
        console.log(options)
        return (
            <MultiSelect
                value={options.value}
                options={country}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Country"
                itemTemplate={(option) => {
                    return <Tag value={option} ></Tag>;
                }}
            />
        );
    };
    const statusBodyTemplate = (rowData) => {
        rowData.applicability.map((item) => {
            return <Tag value={item} ></Tag>;
        })

    };
    const onRowEditComplete = (e) => {
        let _products = [...importdata];
        let { newData, index } = e;
        if (newData.unit.trim().length !== 0 && newData.subcategory1.trim().length !== 0) {
            _products[index] = newData;

            setImportData(_products);
        }

    };
    function isValidURL(url) {
        if (url.trim().length === 0) {
            return true
        }
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(url);
    }

    const addFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setStdModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveStandards() }} />
        </>
    );
    const addFooter3 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setEFCatModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveEFCategory() }} />
        </>
    );
    const addFooter4 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setImportDialogModal(false); setImportData([]); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { importNewEfItems() }} />
        </>
    );
    const addFooter5 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setEditorModal(false); }} />
            <Button label="Copy" icon="pi pi-check" className="p-button-text" onClick={() => { copyToClipBoard() }} />
        </>
    );
    const addFooter6 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setDateModal(false); }} />
            <Button label="Save & Exit" icon="pi pi-check" className="p-button-text" onClick={() => { saveEFDate() }} />
        </>
    );
    const addFooter2 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setEFModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveEF() }} />
        </>
    )
    const addFooter7 = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-text" onClick={() => { setStdConfigModal(false); setSubmitted(false); }} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={() => { saveStdConfig() }} />
        </>
    );
    const randomIntFromInterval = (min, max) => { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    }
    const getID = () => {
        return parseInt(Date.now() * Math.random()) + DateTime.now().toMillis() + randomIntFromInterval(1, 1000)

    }
    const copyToClipBoard = async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(cliptext);
        } else {
            document.execCommand('copy', true, cliptext);
        }
    }
    const rejectToExcel = (rejectedData) => {
        const ws = XLSX.utils.json_to_sheet(rejectedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        FileSaver.saveAs(blob, "RejectedEF.xlsx");

    };
    const importNewEfItems = () => {
        let data = JSON.parse(JSON.stringify(importdata))
        data.forEach((i) => {
            i.subcategory1 = i.one;
            i.subcategory2 = i.two
            i.subcategory3 = i.three
            i.subcategory4 = i.four
            delete i.one
            delete i.two
            delete i.three
            delete i.four
        })
        console.log(data)
        APIServices.post(API.EF_Item_Import, data).then((a) => {
            let data_ = JSON.parse(JSON.stringify(data))
            if (a.data.createdRows.length > 0) {
                let index = data_.findIndex((l) => { return l.id === importdata[0].newEfId })
                if (index !== -1) {
                    if (data_[index].newEfItems === undefined) {
                        data_[index]['newEfItems'] = a.data.createdRows

                    } else {
                        data_[index].newEfItems.push(...a.data.createdRows)

                    }
                    setData(data_)
                }
            }
            if (a.data.status !== 0) {
                let loc = a.data.rejectedRows
                loc.forEach((item) => {
                    delete item.created_on
                    delete item.extra1
                    delete item.extra2
                    delete item.newEfId
                    delete item.id
                    item['co2e_in_kg'] = item.co2e
                    item['co2_in_kg'] = item.co2
                    item['ch4_in_kg'] = item.ch4
                    item['n2o_in_kg'] = item.n2o
                    delete item.co2e
                    delete item.co2
                    delete item.ch4
                    delete item.n2o
                    if (item.applicability.length === 1) {
                        item.applicability = item.applicability[0] + '-'

                    } else {
                        item.applicability = item.applicability.join('-')

                    }

                })
                rejectToExcel(a.data.rejectedRows)
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Rejected Entries exported as excal",
                    showConfirmButton: false,
                    timer: 2000,
                });
            }

            setImportDialogModal(false)
            setImportData([])
        })
    }
    const saveStandards = () => {
        console.log(newstandards)
        setSubmitted(true)
        if (newstandards.category !== null && newstandards.source.trim().length !== 0 && newstandards.applicabledate !== null && newstandards.last_update !== null && newstandards.status !== null && newstandards.version.trim().length !== 0 && isValidURL(newstandards.link.trim())) {
            let data_ = JSON.parse(JSON.stringify(data))
            if (newstandards.id) {
                let loc = JSON.parse(JSON.stringify(newstandards))
                delete loc.id
                delete loc.extra1
                delete loc.extra2
                delete loc.created_on
                delete loc.newEfItems
                delete loc.newEfDateId

                APIServices.patch(API.NEW_EF_Edit(newstandards.id), loc).then((res) => {
                    let index = data_.findIndex((k) => { return k.id === newstandards.id })
                    data_[index] = newstandards
                    setSubmitted(false)
                })


            } else {
                let loc = JSON.parse(JSON.stringify(newstandards))
                loc.created_on = moment.utc()
                APIServices.post(API.NEW_EF_Date(actdateid), loc).then((res) => {
                    console.log(res)
                    data_.push({ ...res.data, newEfItems: [] })
                    setSubmitted(false)
                })

            }

            setData(data_)
            setStdModal(false)
        }
    }
    const saveEFCategory = () => {
        let index = category.findIndex((l) => { return l.title.trim().toLowerCase() === efcat.title.trim().toLowerCase() })
        setSubmitted(true)
        if (efcat.title.trim().length !== 0 && index === -1) {
            let data_ = JSON.parse(JSON.stringify(category))
            if (efcat.id) {
                let loc = JSON.parse(JSON.stringify(efcat))
                delete loc.id
                delete loc.extra
                delete loc.newEfSubcategory1s
                APIServices.patch(API.EF_Category_edit(efcat.id), loc).then((res) => {
                    let index = data_.findIndex((k) => { return k.id === efcat.id })
                    data_[index] = efcat
                    setSubmitted(false)
                })


            } else {
                let loc = JSON.parse(JSON.stringify(efcat))
                loc.created_on = moment.utc()
                APIServices.post(API.EF_Category_Std(actstdid), loc).then((res) => {
                    delete res.data.extra
                    data_.push(res.data)
                    setSubmitted(false)
                })

            }

            setCategory(data_)
            setEFCatModal(false)
        } else if (index === -1) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Category already existing",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const saveEFDate = () => {
        let data_ = JSON.parse(JSON.stringify(efstdlist))
        let index = data_.findIndex((k) => { return k.id === actstdid })

        setSubmitted(true);
        if (newstdate.start !== null) {
            if (newstdate.id) {

                let loc = JSON.parse(JSON.stringify(newstdate))

                delete loc.extra

                if (loc.end === null) {
                    delete loc.end
                }
                APIServices.patch(API.EF_Date_Edit(newstdate.id), loc).then((res) => {

                    let index2 = data_[index].newEfDates.findIndex((l) => { return l.id === newstdate.id })
                    data_[index].newEfDates[index2] = loc
                    setSubmitted(false);
                })

            } else {

                let loc = JSON.parse(JSON.stringify(newstdate))
                if (loc.end === null) {
                    delete loc.end
                }
                loc.created_on = moment.utc()

                APIServices.post(API.EF_Date_Std(actstdid), loc).then((res) => {

                    if (data_[index].newEfDates === undefined) {
                        data_[index]['newEfDates'] = []
                    }
                    data_[index]['newEfDates'].push(res.data)
                    setSubmitted(false);
                }).catch((e) => {
                    console.log(e)
                })


            }
            setEFStdList(data_)
            setDateModal(false)
        }
    }
    const saveEF = () => {
        let data_ = JSON.parse(JSON.stringify(data))
        let index = data_.findIndex((k) => { return k.id === selectedstd.id })

        setSubmitted(true);
        if (newef.ef_id.trim().length !== 0 && newef.created_by.trim().length !== 0 && newef.unit.trim().length !== 0 && newef.subcategory1 !== 0 && newef.co2e >= 0 && newef.co2 >= 0 && newef.n2o >= 0 && newef.ch4 >= 0 && newef.applicability !== null && newef.applicability.length !== 0) {
            if (newef.id) {
                let index__ = data_.findIndex((k) => { return k.id === newef.newEfId })
                let loc = JSON.parse(JSON.stringify(newef))

                if (loc.applicability.length === country.length) {
                    loc.applicability = [0]
                }


                APIServices.patch(API.EF_Item_Edit(newef.id), loc).then((res) => {
                    console.log(data_[index__])
                    let index2 = data_[index__].newEfItems.findIndex((l) => { return l.id === newef.id })
                    data_[index__].newEfItems[index2] = loc
                    setSubmitted(false);
                })

            } else {

                let loc = JSON.parse(JSON.stringify(newef))
                if (loc.applicability.length === country.length) {
                    loc.applicability = [0]
                }
                loc.created_on = moment.utc()

                APIServices.post(API.EF_Item_NEW_EF(selectedstd.id), loc).then((res) => {

                    if (data_[index].newEfItems === undefined) {
                        data_[index]['newEfItems'] = []
                    }
                    data_[index]['newEfItems'].push(res.data)
                    setSubmitted(false);
                }).catch((e) => {
                    setSubmitted(false);
                    Swal.fire({
                        position: "center",
                        icon: "warning",
                        title: "Check ef_id is already exists",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                })


            }
            setData(data_)
            setEFModal(false)
        }

    }
    const saveStdConfig = () => {
        setSubmitted(true)
        console.log(actStdItem)
        if (actStdItem.id !== undefined && actStdItem.title.trim().length !== 0 && actStdItem.dcf_ids !== null && (actStdItem.public === true || (actStdItem.public === false && actStdItem.client_ids.length !== 0))) {
            APIServices.patch(API.EF_Std_Edit(actStdItem.id), actStdItem
            ).then((a) => {
                let loc = JSON.parse(JSON.stringify(efstdlist))
                let index = loc.findIndex(x => x.id === actStdItem.id);
                if (index !== -1) {
                    loc[index] = { ...loc[index], ...actStdItem }
                }
                setEFStdList(loc)
                setStdConfigModal(false)
                setSubmitted(false)
                console.log(a)
            })
        }
    }
    const allowExpansion = (rowData) => {
        if (rowData.newEfItems === undefined) {
            return false
        } else {
            return rowData.newEfItems.length > 0

        };
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <h5>Emission Factor for {data.name}</h5>
                <DataTable value={data.newEfItems}>
                    <Column field="ef_id" header="EF_ID" />
                    <Column field="unit" header="Unit" />
                    <Column field="subcategory1" header="Subcategory 1" body={(rowData) => { return subcategory1template(rowData, data.category) }} />
                    <Column field="subcategory2" header="Subcategory 2" body={(rowData) => { return subcategory2template(rowData, data.category) }} />

                    <Column field="subcategory3" header="Subcategory 3" body={(rowData) => { return subcategory3template(rowData, data.category) }} />
                    <Column field="subcategory4" header="Subcategory 4" body={(rowData) => { return subcategory4template(rowData, data.category) }} />


                    <Column body={(rowData) => { return actionBodyTemplate2(rowData, data.category) }} />
                </DataTable>
            </div>
        );
    };
    const LastUpDateTemplate = (rowData) => {

        return (
            <>{formatDate(rowData.last_update, 'dd-MM-yyyy')} </>
        )

    }
    function formatDate(dateInput, format, type) {
        let luxonDate;
        if (dateInput === null || dateInput === undefined) {
            if (type === 2) {
                return 'Present'
            }
            return 'NA'
        }
        if (dateInput instanceof DateTime) {
            // If it's already a Luxon DateTime object, use it directly
            luxonDate = dateInput;
        } else if (typeof dateInput === 'string') {
            // If it's a string, parse it as a Luxon DateTime object
            luxonDate = DateTime.fromISO(dateInput);
        } else if (dateInput instanceof Date) {
            // If it's a JavaScript Date object, convert it to a Luxon DateTime object
            luxonDate = DateTime.fromJSDate(dateInput);
        } else {
            throw new Error('Invalid date input');
        }

        // Check if it's a valid Luxon DateTime object before formatting
        if (luxonDate instanceof DateTime) {
            // Format the date based on the provided format
            return luxonDate.toFormat(format);
        } else {
            throw new Error('Invalid date input');
        }
    }

    const YearTemplate = (rowData) => {



        return (
            <>{formatDate(rowData.year, 'yyyy')} </>
        )

    }
    const categoryTemplate = (rowData) => {
        let category_ = "Deleted"
        let index = category.findIndex((k) => { return k.id === rowData.category })
        if (index !== -1) {
            category_ = category[index].title
        }
        return (
            <>{category_} </>
        )

    }
    const AppDateTemplate = (rowData) => {

        return (
            <>{formatDate(rowData.applicabledate[0], 'MMM-yyyy') + ' to ' + formatDate(rowData.applicabledate[1], 'MMM-yyyy')} </>
        )
    }
    const editStandard = (std) => {
        let item = JSON.parse(JSON.stringify(std))
        let index = category.findIndex((k) => { return k.id === std.category })
        if (index === -1) {
            item.category = null
        }
        item.last_update = DateTime.fromISO(item.last_update, { zone: 'utc' }).toJSDate()
        delete item.year
        // item.applicabledate = [DateTime.fromISO(item.applicabledate[0], { zone: 'utc' }).toJSDate(), item.applicabledate[1] === null ? null : DateTime.fromISO(item.applicabledate[1], { zone: 'utc' }).toJSDate()]
        // item.year = DateTime.fromISO(item.year, { zone: 'utc' }).toJSDate()
        console.log(item)
        setNewStandards(item)

        setStdModal(true)
    }
    function removeNullKeys(obj, ignoreKeys) {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            // Handle arrays
            return obj.length ? obj : undefined;
        }

        const result = {};

        for (const key in obj) {
            if (obj[key] !== null && (!ignoreKeys || ignoreKeys.indexOf(key) === -1)) {
                const value = removeNullKeys(obj[key], ignoreKeys);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        }

        return Object.keys(result).length ? result : undefined;
    }



    const editStd = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        delete items.extra
        delete items.newEfDates
        setActiveStdItem(items)
        setStdConfigModal(true)
    }

    const editEf = (ef, category_id) => {
        let item = { ...newef, ...removeNullKeys(JSON.parse(JSON.stringify(ef)), []) }

        console.log(ef)
        delete item.created_on
        delete item.extra2
        let index = category.findIndex((k) => { return k.id === category_id })
        if (index !== -1) {
            let loc = JSON.parse(JSON.stringify(tieroptions))
            let temp = category[index].newEfSubcategory1s === undefined ? [] : category[index].newEfSubcategory1s
            loc.tier1 = temp
            let index1 = temp.findIndex((k) => { return k.id === item.subcategory1 })
            if (index1 !== -1) {
                let temp1 = temp[index1].newEfSubcategory2s === undefined ? [] : temp[index1].newEfSubcategory2s
                loc.tier2 = temp1
                console.log(temp1)
                let index2 = temp1.findIndex((k) => { return k.id === item.subcategory2 })
                if (index2 !== -1) {
                    let temp2 = temp1[index2].newEfSubcategory3s === undefined ? [] : temp1[index2].newEfSubcategory3s
                    loc.tier3 = temp2
                    let index3 = temp2.findIndex((k) => { return k.id === item.subcategory3 })
                    if (index3 !== -1) {
                        let temp3 = temp2[index3].newEfSubcategory4s === undefined ? [] : temp2[index3].newEfSubcategory3s
                        loc.tier4 = temp3
                    } else {
                        item.subcategory4 = 0
                    }
                } else {
                    item.subcategory3 = 0
                    item.subcategory4 = 0
                }
            } else {
                item.subcategory2 = 0
                item.subcategory3 = 0
                item.subcategory4 = 0
            }
            setTierOptions(loc)
        } else {
            item.subcategory1 = 0
            item.subcategory2 = 0
            item.subcategory3 = 0
            item.subcategory4 = 0
        }
        if (item.applicability.length === 1 && item.applicability.includes(0)) {
            item.applicability = JSON.parse(JSON.stringify(country)).map((i) => { return i.id })

        }
        setNewEF(item)

        setEFModal(true)
    }
    const deleteStandard = async (rowData) => {
        if (rowData.newEfItems === undefined || rowData.newEfItems.length === 0) {
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete  ${rowData.name} ?
          </div>`,

                confirmButtonColor: 'red',
                showCancelButton: true,
                confirmButtonText:
                    'Delete',

            })
            if (accept) {
                APIServices.delete(API.NEW_EF_Edit(rowData.id)).then((l) => {
                    let data_ = JSON.parse(JSON.stringify(data))
                    let index = data_.findIndex((k) => { return k.id === rowData.id })
                    data_.splice(index, 1)
                    setData(data_)
                })
            }
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Delete Item before deleting standard",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const deleteEf = async (rowData) => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete item with ${rowData.subcategory1} 
    as subcategory  ? </div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,
            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.EF_Item_Edit(rowData.id)).then((l) => {
                let data_ = JSON.parse(JSON.stringify(data))
                let index = data_.findIndex((k) => { return k.id === rowData.newEfId })
                let index2 = data_[index].newEfItems.findIndex((l) => { return l.id === rowData.id })
                data_[index].newEfItems.splice(index2, 1)
                setData(data_)
            })
        }
    }
    function findMatchingIds(array, ...titles) {
        let currentArray = array;
        const matchingIds = [];
console.log(titles)
        for (let i = 0; i < titles.length; i++) {

            const titleToMatch = titles[i].trim().toLowerCase();

            if (!currentArray) {
                // If currentArray is undefined, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            const item = currentArray.find(
                (obj) => obj.title.trim().toLowerCase() === titleToMatch
            );

            if (!item) {
                // If the title is not found, return the result and matchingIds
                return { result: false, id: matchingIds };
            }

            matchingIds.push(item.id);

            // Move to the next level if available, otherwise, set currentArray to undefined
            currentArray =
                item[`newEfSubcategory${i + 2}s`] !== undefined
                    ? item[`newEfSubcategory${i + 2}s`]
                    : undefined;
        }

        return { result: true, id: matchingIds };
    }
    const importFromExcel = (e) => {
        const file = e.files[0];

        import("xlsx").then((xlsx) => {
            const reader = new FileReader();
            reader.onload = (ev) => {
                const data = new Uint8Array(ev.target.result);
                const workbook = xlsx.read(data, { type: "array" });

                // Assuming you want the first sheet
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // Convert worksheet to an array of objects
                const jsonData = xlsx.utils.sheet_to_json(worksheet);

                // Get the headers based on the first row
                const headers = 15 // Total columns
                console.log(jsonData)
                const sanitizedData = jsonData.map((row) => {
                    const sanitizedRow = {};
                    for (let i = 0; i <= headers; i++) {
                        const header = worksheet[xlsx.utils.encode_cell({ r: 0, c: i })]?.v || `Column${i + 1}`;
                        sanitizedRow[header] = row[header];
                    }
                    return sanitizedRow;
                });

                console.log('Sanitized Headers:', headers);
                console.log('Sanitized Data:', sanitizedData);
                sanitizedData.forEach((i) => {
                    i['newEfId'] = actEFid
                    if (i.subcategory2 === undefined || i.subcategory2 === null) {
                        i.subcategory2 = 0
                    }
                    if (i.subcategory3 === undefined || i.subcategory3 === null) {
                        i.subcategory3 = 0
                    }
                    if (i.subcategory4 === undefined || i.subcategory4 === null) {
                        i.subcategory4 = 0
                    }
                    if (i.co2_in_kg === undefined || i.co2_in_kg === null || typeof i.co2_in_kg !== 'number') {
                        i.co2_in_kg = 0
                    }
                    if (i.ch4_in_kg === undefined || i.ch4_in_kg === null || typeof i.ch4_in_kg !== 'number') {
                        i.ch4_in_kg = 0
                    }
                    if (i.n2o_in_kg === undefined || i.n2o_in_kg === null || typeof i.n2o_in_kg !== 'number') {
                        i.n2o_in_kg = 0
                    }
                    if (i.quality === undefined || i.quality === null || i.quality.toString().trim().length === 0) {
                        i.quality = ''
                    }
                    if (i.description === undefined || i.description === null || i.description.toString().trim().length === 0) {
                        i.description = ''
                    }
                    if (i.methodology === undefined || i.methodology === null || i.methodology.toString().trim().length === 0) {
                        i.methodology = ''
                    }

                    if (i.notes === undefined || i.notes === null || i.notes.toString().trim().length === 0) {
                        i.notes = ''
                    }
                })
                let apiData = []

                sanitizedData.forEach((items) => {

                    if (items.newEfId) {
console.log('Valid EFID')
                        if (items.ef_id !== undefined && items.ef_id !== null && items.created_by !== undefined && items.created_by !== null && items.created_by.toString().trim().length !== 0 && items.unit !== undefined && items.unit !== null && items.unit.toString().trim().length !== 0 && items.subcategory1 !== undefined && items.subcategory1 !== null && items.subcategory1.toString().trim().length !== 0 && items.co2e_in_kg !== undefined && items.co2e_in_kg !== null && parseFloat(items.co2e_in_kg) >= 0 && items.applicability !== null && typeof items.applicability === 'string' && items.applicability.trim().split('-').length > 1) {
                            let index = category.findIndex(i => i.id === actcatid)
                            console.log('Items Allowed',items,index)

                            items['co2e'] = parseFloat(items.co2e_in_kg)
                            items['co2'] = parseFloat(items.co2_in_kg)
                            items['ch4'] = parseFloat(items.ch4_in_kg)
                            items['n2o'] = parseFloat(items.n2o_in_kg)
                            items.ef_id = items.ef_id.toString().trim()
                            items.unit = items.unit.toString().trim()
                            delete items.co2e_in_kg
                            delete items.co2_in_kg
                            delete items.ch4_in_kg
                            delete items.n2o_in_kg
                            if (items.applicability.split('-').filter((k) => { return k.trim().length !== 0 }).map((l) => { return parseInt(l) }).includes(0)) {
                                items.applicability = [0]
                            } else {
                                let country_ = country.map((m) => m.id)
                                items.applicability = items.applicability.split('-').filter((k) => { return k.trim().length !== 0 }).filter((l) => { return country_.includes(parseInt(l)) }).map((l) => { return parseInt(l) })

                            }
                            items.created_on = DateTime.utc()
                            if (index !== -1) {
                                if (actcatid === 1) {
                                    const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1, items.subcategory2, items.subcategory3)
                                    if (result) {
                                        items.one = id[0]
                                        items.two = id[1]
                                        items.three = id[2]
                                        items.four = 0
                                        apiData.push(items)
                                    }
                                } else if (actcatid === 5 || actcatid === 11) {
                                    const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1)
                                    console.log(result, id)
                                    if (result) {
                                        items.one = id[0]
                                        items.two = 0
                                        items.three = 0
                                        items.four = 0
                                        apiData.push(items)
                                    }
                                } else if (actcatid === 6 ) {
                                    const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1, items.subcategory2, items.subcategory3, items.subcategory4)
                                    console.log(result, id)
                                    if (result) {
                                        items.one = id[0]
                                        items.two = id[1]
                                        items.three = id[2]
                                        items.four = id[3]
                                        apiData.push(items)
                                    }
                                }else {
                                    if(items.subcategory4){
                                        const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1, items.subcategory2, items.subcategory3, items.subcategory4)
                                        console.log(result, id)
                                        if (result) {
                                            items.one = id[0]
                                            items.two = id[1]
                                            items.three = id[2]
                                            items.four = id[3]
                                            apiData.push(items)
                                        }
                                    }else   if(items.subcategory3) {
                                        const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1, items.subcategory2, items.subcategory3)
                                        console.log(result, id)
                                        if (result) {
                                            items.one = id[0]
                                            items.two = id[1]
                                            items.three = id[2]
                                            items.four = 0
                                            apiData.push(items)
                                        }
                                    } else   if(items.subcategory2) {
                                        const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1, items.subcategory2)
                                        console.log(result, id)
                                        if (result) {
                                            items.one = id[0]
                                            items.two = id[1]
                                            items.three = 0
                                            items.four = 0
                                            apiData.push(items)
                                        }
                                    }else {
                                        const { result, id } = findMatchingIds(category[index].newEfSubcategory1s, items.subcategory1)
                                        console.log(result, id)
                                        if (result) {
                                            items.one = id[0]
                                            items.two = 0
                                            items.three = 0
                                            items.four = 0
                                            apiData.push(items)
                                        }
                                    }
                               
                                }
                            }

                        }
                    }
                })
                console.log(apiData)
                if (apiData.length !== 0) {
                    setImportData(apiData)
                    setImportDialogModal(true)
                }
            };

            reader.readAsArrayBuffer(file);
        });
        e.value = null

    }
    const addEF = (rowData) => {
        return (

            <>
                <div className="actions">
                    <Button
                        icon="pi 
                        pi-plus"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: 'white', color: '#005284' }}
                        onClick={() => {
                            setSelectedStd(rowData); setNewEF({ description: '', unit: '', ef_id: '', subcategory1: 0, subcategory2: 0, subcategory3: 0, subcategory4: 0, methodology: '', applicability: '', quality: '', notes: '', co2e: 0, co2: 0, ch4: 0, n2o: 0, created_by: '', checked_by: '' }); setEFModal(true);
                        }}
                    />
                </div>
            </>
        )
    }
    const addNewEFItem = (rowData) => {
        let item = JSON.parse(JSON.stringify(rowData))
        setSelectedStd(item);
        setNewEF({ description: '', unit: '', ef_id: '', subcategory1: 0, subcategory2: 0, subcategory3: 0, subcategory4: 0, methodology: '', applicability: '', quality: '', notes: '', co2e: 0, co2: 0, ch4: 0, n2o: 0, created_by: '', checked_by: '' });
        setEFModal(true);
        let index = category.findIndex((k) => { return k.id === item.category })
        let loc = JSON.parse(JSON.stringify(tieroptions))
        if (index !== -1) {

            let temp = category[index].newEfSubcategory1s === undefined ? [] : category[index].newEfSubcategory1s
            loc.tier1 = temp
            loc.tier2 = []
            loc.tier3 = []
            loc.tier4 = []
            setTierOptions(loc)
        } else {
            loc.tier1 = []
            loc.tier2 = []
            loc.tier3 = []
            loc.tier4 = []
            setTierOptions(loc)
        }
    }
    const actionBodyTemplate = (rowData) => {

        return (

            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                        onClick={() => {
                            editStandard(rowData)
                        }}
                    />
                    <Button
                        icon="pi 
                        pi-plus"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, backgroundColor: 'white', color: '#005284' }}
                        onClick={() => {
                            addNewEFItem(rowData)
                        }}
                    />
                    <div>
                        <Button
                            icon="pi pi-file-excel" form
                            className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'green' }}
                            onClick={() => {
                                setActEFID(rowData.id)
                                setActCatID(rowData.category)
                                if (fileInputRef.current) {
                                    fileInputRef.current.click();
                                }
                            }}
                        />
                        <input
                            type="file"
                            ref={fileInputRef}
                            id={rowData.id}
                            style={{ display: 'none' }}
                            onChange={(event) => { importFromExcel(event.target) }}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // Set accepted file types if needed
                        />
                    </div>
                    {/* <Button
                        icon="pi pi-trash"
                        className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                        onClick={() => {
                            deleteStandard(rowData)
                        }}
                    /> */}
                </div>
            </>

        )
    }
    const subcategory1template = (rowData, category_id) => {
        let index = category.findIndex((i) => { return i.id === category_id })
        let text = 'Not Found'
        console.log(category[index].newEfSubcategory1s)
        if (index !== -1 && category[index].newEfSubcategory1s !== undefined && category[index].newEfSubcategory1s.length !== 0) {
            let index1 = category[index].newEfSubcategory1s.findIndex((i) => { return i.id === rowData.subcategory1 })
            if (index1 !== -1) {
                text = category[index].newEfSubcategory1s[index1].title
            } else {
                text = 'NA'
            }

        } else {
            text = 'Category NA'
        }
        return (
            <>{text}</>
        )
    }
    const subcategory2template = (rowData, category_id) => {
        let index = category.findIndex((i) => { return i.id === category_id })
        let text = 'Not Found'
        console.log(category[index].newEfSubcategory1s)
        if (index !== -1 && category[index].newEfSubcategory1s !== undefined && category[index].newEfSubcategory1s.length !== 0) {
            let index1 = category[index].newEfSubcategory1s.findIndex((i) => { return i.id === rowData.subcategory1 })
            if (index1 !== -1 && category[index].newEfSubcategory1s[index1].newEfSubcategory2s !== undefined && category[index].newEfSubcategory1s[index1].newEfSubcategory2s.length !== 0) {
                let index2 = category[index].newEfSubcategory1s[index1].newEfSubcategory2s.findIndex((i) => { return i.id === rowData.subcategory2 })
                if (index2 !== -1) {
                    text = category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].title
                } else {
                    text = 'NA'
                }

            } else {
                text = 'NA'
            }
        } else {
            text = 'Category NA'
        }
        return (
            <>{text}</>
        )
    }
    const subcategory3template = (rowData, category_id) => {
        let index = category.findIndex((i) => { return i.id === category_id })
        let text = 'Not Found'
        console.log(category[index].newEfSubcategory1s)
        if (index !== -1 && category[index].newEfSubcategory1s !== undefined && category[index].newEfSubcategory1s.length !== 0) {
            let index1 = category[index].newEfSubcategory1s.findIndex((i) => { return i.id === rowData.subcategory1 })
            if (index1 !== -1 && category[index].newEfSubcategory1s[index1].newEfSubcategory2s !== undefined && category[index].newEfSubcategory1s[index1].newEfSubcategory2s.length !== 0) {
                let index2 = category[index].newEfSubcategory1s[index1].newEfSubcategory2s.findIndex((i) => { return i.id === rowData.subcategory2 })
                if (index2 !== -1 && category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s !== undefined && category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s.length !== 0) {
                    let index3 = category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s.findIndex((i) => { return i.id === rowData.subcategory3 })
                    if (index3 !== -1) {
                        text = category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].title
                    } else {
                        text = 'NA'
                    }
                } else {
                    text = 'NA'
                }

            } else {
                text = 'NA'
            }
        } else {
            text = 'Category NA'
        }
        return (
            <>{text}</>
        )
    }
    const subcategory4template = (rowData, category_id) => {
        let index = category.findIndex((i) => { return i.id === category_id })
        let text = 'Not Found'
        console.log(category[index].newEfSubcategory1s)
        if (index !== -1 && category[index].newEfSubcategory1s !== undefined && category[index].newEfSubcategory1s.length !== 0) {
            let index1 = category[index].newEfSubcategory1s.findIndex((i) => { return i.id === rowData.subcategory1 })
            if (index1 !== -1 && category[index].newEfSubcategory1s[index1].newEfSubcategory2s !== undefined && category[index].newEfSubcategory1s[index1].newEfSubcategory2s.length !== 0) {
                let index2 = category[index].newEfSubcategory1s[index1].newEfSubcategory2s.findIndex((i) => { return i.id === rowData.subcategory2 })
                if (index2 !== -1 && category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s !== undefined && category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s.length !== 0) {
                    let index3 = category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s.findIndex((i) => { return i.id === rowData.subcategory3 })
                    if (index3 !== -1 && category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s !== undefined && category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s.length !== 0) {
                        let index4 = category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s.findIndex((i) => { return i.id === rowData.subcategory4 })
                        if (index4 !== -1) {
                            text = category[index].newEfSubcategory1s[index1].newEfSubcategory2s[index2].newEfSubcategory3s[index3].newEfSubcategory4s[index4].title
                        } else {
                            text = 'NA'
                        }

                    } else {
                        text = 'NA'
                    }
                } else {
                    text = 'NA'
                }

            } else {
                text = 'NA'
            }
        } else {
            text = 'Category NA'
        }
        return (
            <>{text}</>
        )
    }
    const actionBodyTemplate2 = (rowData, category_id) => {

        return (

            <>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="mr-2" style={{ width: 30, height: 30, borderRadius: 15, background: 'dodgerblue' }}
                        onClick={() => {
                            editEf(rowData, category_id)
                        }}
                    />

                    {/* <Button
                        icon="pi pi-trash"
                        className="mr-2 " style={{ width: 30, height: 30, borderRadius: 15, background: 'palevioletred' }}
                        onClick={() => {

                        }}
                    /> */}
                </div>
            </>

        )
    }
    const categoryOptionTemplate = (rowData) => {
        return (
            <div className="grid justify-content-between col-12" style={{ padding: 0 }} >
                <div className="col-10">
                    {rowData.title}
                </div>

                <div className="col-2" style={{ zIndex: 10 }}>
                    <i className="material-icons" style={{ color: 'green' }} onClick={(e) => {
                        e.stopPropagation();

                        setEFCat(JSON.parse(JSON.stringify(rowData))); setEFCatModal(true)
                    }}>edit</i>
                </div>
            </div>
        )
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })

    }
    const renderEF = (id) => {
        setActDateID(id)
        let efitem = {
            "include": ["newEfItems"]

        }

        APIServices.get(API.NEW_EF_Date(id) + `?filter=${encodeURIComponent(JSON.stringify(efitem))}`).then((res) => {
            setData(res.data)
        })
    }
    const showTier2CM = (e, ind) => {
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })


    }
    const deleteEFStd = (id) => {
        APIServices.delete(API.EF_Std_Edit(id)
        ).then((a) => {
            let loc = JSON.parse(JSON.stringify(efstdlist))
            let index = loc.findIndex((l) => { return l.id === id })
            if (index !== -1) {
                loc.splice(index, 1)
                setEFStdList(loc)
                setActDateID(null)
                setActStdID(null)
            }

        })
    }
    const deleteEFDate = (id) => {
        APIServices.delete(API.EF_Date_Edit(id)
        ).then((a) => {
            let loc = JSON.parse(JSON.stringify(efstdlist))

            let index = loc.findIndex((l) => { return l.id === actstdid })
            if (index !== -1) {
                let index2 = loc[index].newEfDates.findIndex((l) => { return l.id === id })
                if (index2 !== -1) {
                    loc[index].newEfDates.splice(index2, 1)
                    setEFStdList(loc)
                    setActDateID(null)
                }

            }
        })
    }
    const updateStandardName = (id, value) => {
        if (value.trim().length !== 0) {
            APIServices.patch(API.EF_Std_Edit(id), { title: value, created_on: DateTime.utc() }
            ).then((a) => {
                let loc = JSON.parse(JSON.stringify(efstdlist))
                let index = loc.findIndex(x => x.id === id);
                if (index !== -1) {
                    loc[index].title = value
                }
                setEFStdList(loc)

                console.log(a)
            })
        }

    }
    const addNewStandardName = () => {


        APIServices.post(API.EF_Std, { title: stdname.title.trim(), created_on: DateTime.utc(), public: false, dcf_ids: [], client_ids: [] }
        ).then((a) => {
            let loc = JSON.parse(JSON.stringify(efstdlist))
            let loc_ = JSON.parse(JSON.stringify(stdname))
            loc_.title = ''
            setStdName(loc_)

            loc.push(a.data)
            setEFStdList(loc)

        })

    }
    const renderTitle = () => {
        let index = efstdlist.findIndex((k) => { return k.id === actstdid })
        if (index !== -1) {
            let index2 = efstdlist[index].newEfDates.findIndex((k) => { return k.id === actdateid })
            if (index2 !== -1) {
                return efstdlist[index].title + ' -> ' + formatDate(efstdlist[index].newEfDates[index2].start, 'MMM-yyyy') + '-' + formatDate(efstdlist[index].newEfDates[index2].end, 'MMM-yyyy', 2)
            }
        }
    }
    return (
        <div >

            {selector.role === "eisqradmin" ? (
                <div className="col-12" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div className="col-3 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                        <div className="col-12">
                            <div className="grid">
                                <div className="col-10">
                                    <InputText
                                        type={"text"}
                                        style={{ width: '100%' }}
                                        value={stdname.title}
                                        onChange={(e) => {
                                            let loc = stdname
                                            loc.title = e.target.value
                                            setStdName(loc)
                                            forceUpdate()
                                        }}
                                        placeholder="Enter Standard Name"
                                    />
                                </div>
                                <div className="col-2" >
                                    <Button
                                        icon="pi pi-plus"

                                        style={{ cursor: stdname.title.trim().length === 0 ? 'not-allowed' : 'pointer', background: stdname.title.trim().length === 0 ? 'gray' : '#22C55E', border: stdname.title.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}

                                        onClick={() => {
                                            addNewStandardName();
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div>
                            {efstdlist.map((item, stdind) => {
                                return (
                                    <div>
                                        <div>
                                            <div onClick={() => {
                                                if (actstdid === item.id) {
                                                    setActStdID(null)
                                                } else {
                                                    setActStdID(item.id)
                                                }
                                                let efcats = {

                                                    "include": [{ "relation": "newEfSubcategory1s", "scope": { "include": [{ "relation": "newEfSubcategory2s", "scope": { "include": [{ "relation": "newEfSubcategory3s", "scope": { "include": [{ "relation": "newEfSubcategory4s" }] } }] } }] } }]

                                                }
                                                APIServices.get(API.EF_Category_Std(item.id) + `?filter=${encodeURIComponent(JSON.stringify(efcats))}`).then((res) => {
                                                    setCategory(res.data)
                                                })
                                                setActDateID(null)

                                            }} style={{ border: actstdid === item.id && "1px solid grey", fontWeight: actstdid === item.id ? 'bold' : 'normal', color: actstdid === item.id ? '#005284' : 'black' }} onContextMenu={(e) => { showTier1CM(e, item.id) }}>
                                                <EditText
                                                    onSave={(e) => {
                                                        console.log(e)
                                                        if (e.value.trim().length !== 0) {
                                                            updateStandardName(item.id, e.value.trim())
                                                        } else {
                                                            console.log(e.previousValue)
                                                            efstdlist[stdind].title = e.previousValue;
                                                            forceUpdate()
                                                        }
                                                    }}

                                                    name="textbox3"
                                                    // value={item.country}
                                                    defaultValue={item.title}
                                                    editButtonProps={{
                                                        style: {
                                                            marginLeft: "5px",
                                                            width: 16,
                                                        },
                                                    }}
                                                    showEditButton
                                                />
                                                <ContextMenu model={[
                                                    {
                                                        label: 'Add Date Under ' + item.title,
                                                        icon: 'pi pi-plus', command: () => { setStdDate({ start: null, end: null }); setDateModal(true); }
                                                    },
                                                    {
                                                        label: 'Configure ' + item.title,
                                                        icon: 'pi pi-cog', command: () => { editStd(item) }
                                                    },
                                                    {
                                                        label: 'Delete ' + item.title,
                                                        icon: 'pi pi-fw pi-trash', command: () => { alert('Not Allowed') }
                                                        // deleteEFStd(item.id)
                                                    }]} ref={(r) => { tier1ref.current[item.id] = r }}></ContextMenu>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{actstdid === item.id && item.newEfDates.length !== 0 &&
                                                <div className="col-10 " >{item.newEfDates.map((item2) => {
                                                    return (
                                                        <div className="p-card" onContextMenu={(e) => { showTier2CM(e, item2.id) }} onClick={() => { renderEF(item2.id) }} style={{
                                                            color: actdateid === item2.id ? '#005284' : 'black', background: 'white', display: 'flex', justifyContent: 'center',
                                                            borderRadius: '20px',
                                                            padding: '5px',
                                                            margin: 5
                                                        }} >
                                                            {formatDate(item2.start, 'MMM-yyyy')} - {formatDate(item2.end, 'MMM-yyyy', 2)}
                                                            <ContextMenu model={[
                                                                {
                                                                    label: 'Edit',
                                                                    icon: 'pi pi-pencil', command: () => { let it = JSON.parse(JSON.stringify(item2)); it.start = DateTime.fromISO(it.start, { zone: 'utc' }).toJSDate(); if (it.end !== null) { it.end = DateTime.fromISO(it.end, { zone: 'utc' }).toJSDate() } setStdDate(it); setDateModal(true); }
                                                                },
                                                                {
                                                                    label: 'Delete',
                                                                    icon: 'pi pi-fw pi-trash', command: () => { alert('Not Allowed') }
                                                                    // deleteEFDate(item2.id)
                                                                }]} ref={(r) => { tier2ref.current[item2.id] = r }}></ContextMenu>
                                                        </div>
                                                    )
                                                })
                                                }
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-9 p-card" style={{ margin: 5, height: "calc(100vh - 9rem)", overflow: "scroll" }}>
                        {actdateid !== null ? <div>
                            <h3> {renderTitle()} </h3>
                            <div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end', marginBottom: 10
                                }}>
                                    <div style={{
                                        display: 'flex', width: 300,
                                        justifyContent: 'space-between'
                                    }}>
                                        <Button onClick={() => { setClipText('<p></p>'); setEditorModal(true); }} label="Generate Methodology" />

                                        <Button onClick={() => { setNewStandards({ category: null, source: '', description: '', version: '', status: '', last_update: null, link: '', status: null }); setSubmitted(false); setStdModal(true) }} label="Add" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <DataTable value={data} ref={stdref} expandedRows={expandedRows} multiple={false} onRowToggle={(e) => setExpandedRows(e.data)}
                                    rowExpansionTemplate={rowExpansionTemplate}
                                    dataKey="id" tableStyle={{ minWidth: '60rem' }}>
                                    <Column expander={allowExpansion} />
                                    <Column field="id" header="id" />

                                    <Column body={categoryTemplate} header="Category" />
                                    <Column body={LastUpDateTemplate} header="Last Updated" />
                                    {/* <Column body={AppDateTemplate} header="Applicable Date" /> */}
                                    <Column body={(rowData) => { return (<>{rowData.status === 1 ? 'Current' : 'Historical'} </>) }} header="Status" />
                                    <Column body={(rowData) => { return (rowData.link.trim().length !== 0 ? <a target="_blank" href={rowData.link}>Open</a> : 'NA') }} header="Link" />
                                    <Column field='version' header="version" />
                                    {/* <Column body={addEF} /> */}

                                    <Column body={actionBodyTemplate} />
                                </DataTable>

                            </div>
                        </div>
                            :
                            <div className="col-12 ">Select Standards and Date to view EF Library</div>
                        }
                    </div>
                </div>
            ) : (
                <div className="col-12 card">You have no rights to access this page </div>
            )}
            <Dialog
                visible={stdmodal}
                style={{
                    width: "60%",
                }}
                header={'Add New Standard '}
                modal
                footer={addFooter}
                onHide={() => {
                    setStdModal(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div className="col-12 grid">


                            <div className="col-6 grid align-items-center">
                                <label className="col-4">
                                    Category <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <Dropdown options={category} style={{ width: '100%' }} itemTemplate={categoryOptionTemplate} className={submitted && newstandards.category === null && 'p-invalid'} filter optionLabel="title" optionValue="id" value={newstandards.category} onChange={(e) => { updateStd('category', e.value) }} />
                                    {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                </div>
                                <div className="col-1" onClick={() => { setSubmitted(false); setEFCat({ title: '' }); setEFCatModal(true) }}>
                                    <i className="material-icons">add</i>
                                    {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 grid">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Last Updated <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <Calendar value={newstandards.last_update} className={submitted && newstandards.last_update === null && 'p-invalid'} onChange={(e) => { updateStd('last_update', e.value) }} showIcon view="date" dateFormat="dd-mm-yy" />
                                </div>

                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Status <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <Dropdown style={{ width: '100%' }} className={submitted && (newstandards.status === null) && 'p-invalid'} options={[{ name: 'Current', id: 1 }, { name: 'Historical', id: 2 }]} optionLabel="name" optionValue="id" value={newstandards.status} onChange={(e) => { updateStd('status', e.value) }}  ></Dropdown>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 grid">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Data Version <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <InputText value={newstandards.version} className={submitted && newstandards.version.trim().length === 0 && 'p-invalid'} onChange={(e) => { updateStd('version', e.target.value) }} />
                                </div>

                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-4">
                                    Description
                                </label>
                                <div className="col-8">
                                    <InputTextarea style={{ width: '100%' }} value={newstandards.description} onChange={(e) => { updateStd('description', e.target.value) }} />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 grid">

                            <div className="col-12 grid align-items-center">
                                <label className="col-2">
                                    Source <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-10">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        <InputText value={newstandards.source} className={submitted && newstandards.source.trim().length === 0 && 'p-invalid'} style={{ width: '100%' }} onChange={(e) => { updateStd('source', e.target.value) }} />
                                    </div>
                                    {/* <Editor value={newstandards.link} className="text-editor" onTextChange={(e) => { updateStd('link', e.htmlValue) }} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onChange={(e) => console.log(e)} /> */}



                                </div>

                            </div>
                        </div>
                        <div className="col-12 grid">

                            <div className="col-12 grid align-items-center">
                                <label className="col-3">
                                    Link <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-9">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        <InputText value={newstandards.link} className={submitted && !isValidURL(newstandards.link.trim()) && 'p-invalid'} style={{ width: '100%' }} onChange={(e) => { updateStd('link', e.target.value) }} />
                                    </div>
                                    {/* <Editor value={newstandards.link} className="text-editor" onTextChange={(e) => { updateStd('link', e.htmlValue) }} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onChange={(e) => console.log(e)} /> */}



                                </div>

                            </div>
                        </div>

                    </div>




                </div>
            </Dialog>
            <Dialog
                visible={efmodal}
                style={{
                    width: "60%",
                }}
                header={'Add New EF '}
                modal
                footer={addFooter2}
                onHide={() => {
                    setEFModal(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div className="col-12 grid ">

                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    EF ID <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <InputText disabled={newef.id !== undefined} className={submitted && newef.ef_id.trim().length === 0 && 'p-invalid'} value={newef.ef_id} onChange={(e) => { updateEF('ef_id', e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 grid ">

                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Unit <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <InputText type='text' className={submitted && newef.unit.trim().length === 0 && 'p-invalid'} value={newef.unit} onChange={(e) => { updateEF('unit', e.target.value) }} />
                                </div>
                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Description
                                </label>
                                <div className="col-7">
                                    <InputTextarea value={newef.description} type='text' onChange={(e) => { updateEF('description', e.target.value) }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 grid ">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Sub Category 1 <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7">
                                    <Dropdown options={tieroptions.tier1} value={newef.subcategory1} optionLabel="title" optionValue="id" onChange={(e) => { updateEF('subcategory1', e.value) }} style={{ width: '100%' }} />
                                    {/* <InputText type='text' className={submitted && newef.subcategory1.trim().length === 0 && 'p-invalid'} value={newef.subcategory1} onChange={(e) => { updateEF('subcategory1', e.target.value) }} /> */}
                                </div>
                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Sub Category 2
                                </label>
                                <div className="col-7">
                                    <Dropdown options={tieroptions.tier2} value={newef.subcategory2} optionLabel="title" optionValue="id" onChange={(e) => { updateEF('subcategory2', e.value) }} style={{ width: '100%' }} />
                                    {/* <InputText type='text' value={newef.subcategory2} onChange={(e) => { updateEF('subcategory2', e.target.value) }} /> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-12 grid ">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Sub Category 3
                                </label>
                                <div className="col-7">
                                    <Dropdown options={tieroptions.tier3} value={newef.subcategory3} optionLabel="title" optionValue="id" onChange={(e) => { updateEF('subcategory3', e.value) }} style={{ width: '100%' }} />
                                    {/* <InputText type='text' value={newef.subcategory3} onChange={(e) => { updateEF('subcategory3', e.target.value) }} /> */}
                                </div>
                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Sub Category 4
                                </label>
                                <div className="col-7">
                                    <Dropdown options={tieroptions.tier4} value={newef.subcategory4} optionLabel="title" optionValue="id" onChange={(e) => { updateEF('subcategory4', e.value) }} style={{ width: '100%' }} />
                                    {/* <InputText type='text' value={newef.subcategory4} onChange={(e) => { updateEF('subcategory4', e.target.value) }} /> */}
                                </div>
                            </div>

                        </div>
                        <fieldset style={{ borderRadius: 10 }} >

                            <legend>Emission Factor Values</legend>
                            <div className="col-12 grid">


                                <div className="col-12 grid justify-content-around">
                                    <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                        <span className="p-inputgroup-addon">co2e<span style={{ color: 'red' }}>*</span></span>
                                        <InputText value={newef.co2e} type={'number'} className={submitted && newef.co2e < 0 && 'p-invalid'} onChange={(e) => { updateEF('co2e', parseFloat(e.target.value)) }} />
                                        <span className="p-inputgroup-addon">kg</span>
                                    </div>
                                    <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                        <span className="p-inputgroup-addon">co2</span>
                                        <InputText value={newef.co2} placeholder="co2" type={'number'} onChange={(e) => { updateEF('co2', parseFloat(e.target.value)) }} />
                                        <span className="p-inputgroup-addon">kg</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 grid">


                                <div className="col-12 grid justify-content-around">
                                    <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                        <span className="p-inputgroup-addon">ch4</span>
                                        <InputText value={newef.ch4} placeholder="ch4" type={'number'} onChange={(e) => { updateEF('ch4', parseFloat(e.target.value)) }} />
                                        <span className="p-inputgroup-addon">kg</span>
                                    </div>

                                    <div className="col-2 p-inputgroup" style={{ width: '48%' }} >
                                        <span className="p-inputgroup-addon">n2o</span>
                                        <InputText value={newef.n2o} placeholder="n2o" type={'number'} onChange={(e) => { updateEF('n2o', parseFloat(e.target.value)) }} />
                                        <span className="p-inputgroup-addon">kg</span>
                                    </div>


                                </div>
                            </div>
                        </fieldset>


                        <div className="col-12 grid">

                            <div className="col-12 grid align-items-center">
                                <label className="col-2">
                                    Methodology
                                </label>
                                <div className="col-10">
                                    <Editor className={"text-editor"} value={newef.methodology} onTextChange={(e) => { updateEF('methodology', e.htmlValue) }} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onChange={(e) => console.log(e)} />

                                </div>

                            </div>
                        </div>
                        <div className="col-12 grid">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Applicability {newef.applicability !== null && `(${newef.applicability.length})`} <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-7 ">
                                    <MultiSelect className={"w-full " + (((submitted && newef.applicability === null) || (submitted && newef.applicability.length === 0)) && 'p-invalid')} panelClassName={'hidefilter'} display="chip" value={newef.applicability} style={{ width: '100%' }} optionValue="id" optionLabel="name" filter options={country} onChange={(e) => { updateEF('applicability', e.value) }} />
                                </div>
                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Data Quality
                                </label>
                                <div className="col-7">
                                    <InputText type='text' value={newef.quality} onChange={(e) => { updateEF('quality', e.target.value) }} />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 grid">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Created by
                                </label>
                                <div className="col-7 ">
                                    <InputText type='text' className={submitted && newef.created_by.trim().length === 0 && 'p-invalid'} value={newef.created_by} onChange={(e) => { updateEF('created_by', e.target.value) }} />
                                </div>
                            </div>
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Checked by
                                </label>
                                <div className="col-7">
                                    <InputText type='text' disabled={newef.id === undefined} value={newef.checked_by} onChange={(e) => { updateEF('checked_by', e.target.value) }} />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 grid">
                            <div className="col-6 grid align-items-center">
                                <label className="col-5">
                                    Notes
                                </label>
                                <div className="col-7">
                                    <InputTextarea value={newef.notes} type='text' onChange={(e) => { updateEF('notes', e.target.value) }} />
                                </div>
                            </div>

                        </div>

                    </div>




                </div>
            </Dialog>
            <Dialog
                visible={efcatmodal}
                style={{
                    width: "60%",
                }}
                header={efcat.id === undefined ? 'Add New category' : 'Edit Category'}
                modal
                footer={addFooter3}
                onHide={() => {
                    setEFCatModal(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div className="col-12 grid">

                            <div className="col-6 grid align-items-center">
                                <label className="col-4">
                                    Category Name
                                </label>
                                <div className="col-8">
                                    <InputText style={{ width: '100%' }} value={efcat.title} onChange={(e) => { let loc = efcat; loc.title = e.target.value; setEFCat(loc); forceUpdate() }} />
                                </div>

                            </div>
                        </div>

                    </div>




                </div>
            </Dialog>
            <Dialog
                visible={importdialog}
                style={{
                    width: "75%",
                }}
                header={'Verify EF ' + actEFid + ' ('+importdata.length+')'}
                modal
                footer={addFooter4}
                onHide={() => {
                    setImportDialogModal(false);
                }}
            >
                <div>
                    <DataTable editMode="row" onRowEditComplete={onRowEditComplete} value={importdata} >
                        <Column field="ef_id" editor={(options) => textEditor(options)} header="EF_ID" />
                        <Column field="unit" editor={(options) => textEditor(options)} header="Unit" />
                        <Column field="subcategory1" header="Subcategory 1" />
                        <Column field="subcategory2" header="Subcategory 2" />
                        <Column field="subcategory3" header="Subcategory 3" />
                        <Column field="subcategory4" header="Subcategory 4" />
                        <Column field="co2e" editor={(options) => numberEditor(options)} header="co2e_in_kg" />
                        <Column field="co2" editor={(options) => numberEditor(options)} header="co2_in_kg" />
                        <Column field="ch4" editor={(options) => numberEditor(options)} header="ch4_in_kg" />
                        <Column field="n2o" editor={(options) => numberEditor(options)} header="n2o_in_kg" />
                        <Column field="created_by" editor={(options) => textEditor(options)} header="created_by" />
                        <Column field="quality" editor={(options) => textEditor(options)} header="Data Quality" />
                        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>


                </div>
            </Dialog>
            <Dialog
                visible={editormodal}
                style={{
                    width: "75%",
                }}
                header={'Generate Methodology'}
                modal
                footer={addFooter5}
                onHide={() => {
                    setEditorModal(false);
                }}
            >
                <div>
                    <Editor className={"text-editor"} value={cliptext} onTextChange={(e) => { setClipText(e.htmlValue) }} style={{ width: '100%', padding: 10, maxHeight: 350, height: 'auto', overflow: 'scroll' }} onChange={(e) => console.log(e)} />



                </div>
            </Dialog>
            <Dialog
                visible={datemodal}
                style={{
                    width: "75%",
                }}
                header={newstdate.id === undefined ? 'Add Date' : 'Edit Start & End Date'}
                modal
                footer={addFooter6}
                onHide={() => {
                    setDateModal(false);
                }}
            >
                <div>
                    <div className="col-12 grid">

                        <div className="col-6 grid align-items-center">
                            <label className="col-5">
                                Start Date <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="col-7">
                                <Calendar value={newstdate.start} className={submitted && newstdate.start === null && 'p-invalid'} onChange={(e) => updateStdDate('start', e.value)} dateFormat="mm/yy" view="month" readOnlyInput showIcon />

                                {/* <Calendar value={newstandards.year} className={submitted && newstandards.year === null && 'p-invalid'} onChange={(e) => { updateStd('year', e.value) }} showIcon view="year" dateFormat="yy" /> */}
                            </div>

                        </div>
                        <div className="col-6 grid align-items-center">
                            <label className="col-5">
                                End Date <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="col-7">
                                <Calendar value={newstdate.end} onChange={(e) => updateStdDate('end', e.value)} dateFormat="mm/yy" view="month" showButtonBar />

                                {/* <Calendar value={newstandards.year} className={submitted && newstandards.year === null && 'p-invalid'} onChange={(e) => { updateStd('year', e.value) }} showIcon view="year" dateFormat="yy" /> */}
                            </div>

                        </div>

                    </div>
                </div>
            </Dialog>
            <Dialog
                visible={stdconfigmodal}
                style={{
                    width: "60%",
                }}
                header={'Edit Standard'}
                modal
                footer={addFooter7}
                onHide={() => {
                    setStdConfigModal(false);
                }}
            >
                <div>
                    <div className="col-12 lg:col-12">
                        <div className="col-12 ">

                            <div className="col-6 grid align-items-center">
                                <label className="col-4">
                                    Standard Name
                                </label>
                                <div className="col-8">
                                    <InputText style={{ width: '100%' }} className={(submitted && actStdItem.title.trim().length === 0) ? 'p-invalid' : ''} value={actStdItem.title} onChange={(e) => { let loc = actStdItem; loc.title = e.target.value; setActiveStdItem(loc); forceUpdate() }} />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 ">

                            <div className="col-10 grid align-items-center">
                                <label className="col-4">
                                    Select DCF <span style={{color:'red'}}>*</span>
                                </label>
                                <div className="col-8">
                                    <MultiSelect className={"w-full " + (((submitted && (actStdItem.dcf_ids === null || actStdItem.dcf_ids.length === 0))) && 'p-invalid')} panelClassName={'hidefilter'} display="chip" value={actStdItem.dcf_ids} style={{ width: '100%' }} optionValue="id" optionLabel="title" filter options={dcflist} onChange={(e) => { let loc = actStdItem; loc.dcf_ids = e.value; setActiveStdItem(loc); forceUpdate() }} />
                                </div>

                            </div>
                        </div>

                        <div className="col-12 flex align-items-center" >
                            <Checkbox inputId={"cb"} name={'public'} value={actStdItem.public} onChange={(e) => { let loc = actStdItem; loc.public = e.checked; loc.client_ids = []; setActiveStdItem(loc); forceUpdate() }} checked={actStdItem.public === true} />
                            <label htmlFor={"cb"} className="ml-2">{'Public'}</label>
                        </div>
                        {!actStdItem.public &&
                            <div className="col-12 ">

                                <div className="col-10 grid align-items-center">
                                    <label className="col-4">
                                        Select Client
                                    </label>
                                    <div className="col-8">
                                        <MultiSelect className={"w-full " + (((submitted && actStdItem.client_ids === null) || (submitted && actStdItem.client_ids.length === 0)) && 'p-invalid')} panelClassName={'hidefilter'} display="chip" value={actStdItem.client_ids} style={{ width: '100%' }} optionValue="id" optionLabel="information.companyname" filter options={clientList} onChange={(e) => { let loc = actStdItem; loc.client_ids = e.value; setActiveStdItem(loc); forceUpdate() }} />

                                    </div>

                                </div>
                            </div>

                        }
                    </div>




                </div>

            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EFLibrary, comparisonFn);
