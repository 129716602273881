import React, { useEffect, useState, useRef, createRef } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import FormBuilder from "../../components/FormBuilder";

import { FaRegSquare, FaRegCheckSquare, FaMinusSquare } from "react-icons/fa";
import { MdPlayArrow, MdCheckBoxOutlineBlank, MdOutlineIndeterminateCheckBox } from 'react-icons/md'
import { TbCheckbox } from "react-icons/tb"
import { IoMdArrowDropright, IoMdCheckboxOutline } from "react-icons/io";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { Button } from "primereact/button";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import { OrderList } from "primereact/orderlist";
import useForceUpdate from "use-force-update";
import { ListBox } from "primereact/listbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import moment from "moment";
import $, { data } from 'jquery'
import axios from "axios";
import IATACodes from "../../assets/dummy/IATACodes";
import { AutoComplete } from "primereact/autocomplete";
import { GoogleMap, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import readXlsxFile from "read-excel-file";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { setRef } from "@fullcalendar/core";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2";
import "react-image-crop/dist/ReactCrop.css";

import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";

import { useSelector } from "react-redux";

import cx from "classnames";

import { render } from "react-dom";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { MultiSelect } from 'primereact/multiselect';
import { DateTime } from "luxon";
const google = window.google;

window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");

let fbref = null
const RFCollectionFormEdit = (props) => {

    const [clienttag, setClientTag] = useState([])
    const selector = useSelector((state) => state.user.userdetail)
    const loc = useLocation();
    const [visibleright, setVisibleRight] = useState(false)
    const [indicatorsuggestion, setIndicatorSuggestion] = useState([])
    const [selected, setSelected] = useState([])
    const his = useHistory()
    const [indicator, setIndicator] = useState()
    const [indicatorlist, setIndicatorList] = useState([])

    const forceUpdate = useForceUpdate()
    const [form, setForm] = useState(loc.state)
    const [submitted, setSubmitted] = useState(false)
    const [dplist, setDPList] = useState([])
    const [dp, setDP] = useState(null)
    const [dpsuggestion, setDPSuggestion] = useState([])
    const [prevdialog, setPrevDialog] = useState(false)
    useEffect(() => {

        APIServices.get(API.UserProfile).then((res) => {
            let locuser = [];

            res.data.forEach((item) => {
                if (item.role === "clientadmin" && item.information.enterpriseid !== undefined) {
                    locuser.push({
                        name: item.information.enterpriseid,
                        id: item.id,
                    });
                }
            });

            setClientTag(locuser);
        });
        setForm(loc.state)
    }, [fbref]);

    const prevDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => { setPrevDialog(false) }} />
        </>
    );
    const updateForm = (obj, val) => {
        console.log(val)
        let loc = form;
        loc[obj] = val;
        setForm(loc);
        forceUpdate()
    }
    const search = (event) => {

        let _items = dplist.filter((k) => { return k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setDPSuggestion(_items);
        forceUpdate()
    }
    const search_ = (event) => {
        console.log(indicatorlist)
        let _items = indicatorlist.filter((k) => { return k.title.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setIndicatorSuggestion(_items);
        forceUpdate()
    }
    function hasNoDuplicateKeyValues(data, key) {

        const seenValues = new Set();

        if (Array.isArray(data)) {
            // Check for duplicates in an array of objects
            for (const obj of data) {
                if (obj[key] !== undefined) {
                    // Convert the value to lowercase for case-insensitive comparison
                    const value = obj[key].toString().toLowerCase();

                    if (seenValues.has(value)) {
                        return false; // Duplicate value found
                    }
                    if (value.trim().length === 0) {
                        return false;
                    } else {
                        seenValues.add(value);
                    }

                }
            }
        } else if (typeof data === 'object') {
            // Check for duplicates in an object
            for (const prop in data) {
                if (data.hasOwnProperty(prop) && prop === key) {
                    if (data[prop] !== undefined && data[prop] !== '' && data[prop].trim() !== '') {
                        // Convert the value to lowercase for case-insensitive comparison
                        const value = data[prop].toString().toLowerCase();
                        if (seenValues.has(value)) {
                            return false; // Duplicate value found
                        }
                        seenValues.add(value);
                    }
                }
            }
        }

        return true; // No duplicates found
    }
    const checkForm = (data) => {
        let check = true;
        let count = 0
        data.forEach((field) => {

            if (field.type === 'paragraph') {
                if (field.label === 'Paragraph') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'number') {
                if (field.label === 'Number') {

                    check = false
                    count = count + 1
                }
            } else if (field.type === 'checkbox-group') {
                if (field.label === 'Checkbox Group') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'date') {
                if (field.label === 'Date Field') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'radio-group') {
                if (field.label === 'Radio Group') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'select') {
                if (field.label === 'Select') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'text') {
                if (field.label === 'Text Field') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'textarea') {
                if (field.label === 'Text Area') {
                    check = false
                    count = count + 1
                }
            } else if (field.type === 'file') {
                if (field.label === 'File Upload') {
                    check = false
                    count = count + 1
                }
            }
        })
        return { result: check, count }
    }
    const addDCF = () => {
        setSubmitted(true)

        if (form.title.trim().length !== 0 && fbref.actions.getData('js').length !== 0) {
            if (hasNoDuplicateKeyValues(fbref.actions.getData('js'), 'name')) {
                if (checkForm(fbref.actions.getData('js')).result) {
                    let data = form;
                    let data_ = JSON.parse(JSON.stringify(fbref.actions.getData('js')));
                    data_.forEach((item) => {
                        if (item.type === 'number' || item.type === 'text' || item.type === 'textarea' || item.type === 'file' || item.type === 'date' || item.type === 'select' || item.type === 'radio-group' || item.type === 'checkbox-group') {
                            item.value = null
                            if (item.type === 'select' || item.type === 'radio-group' || item.type === 'checkbox-group') {
                                item.values.forEach((options) => {
                                    options.selected = false
                                })
                            }
                        }
                    })
                    console.log(data_)
                    data.data1 = data_
                    data.modifier_id = selector.id
                    delete data.data2
                    delete data.created
                    delete data.curator_id
                    data['updated'] = DateTime.utc()
                    APIServices.patch(API.RF_Edit(loc.state.id), data).then((res) => {
                        his.goBack()
                        setSubmitted(false)
                        forceUpdate()

                    })
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "info",
                        title: checkForm(fbref.actions.getData('js')).count + " field(s) found with default label name",
                        showConfirmButton: false,
                        timer: 1800,
                    });
                }
            } else {
                Swal.fire({
                    position: "center",
                    icon: "warning",
                    title: "Found duplicates in Name Field",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }

        } else if (fbref.actions.getData('js').length === 0 && form.title.trim().length !== 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Atleast one field is required to submit form",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
    const removeHTMLTag = (html) => {
        return html.replace(/(<([^>]+)>)/gi, "")
            .replace(/\n/g, " ")
            .replace(/&nbsp;/g, " ")
    }
    const renderPreview = () => {


        if (fbref !== null || form.data1.length !== 0) {
            let data = []
            if (fbref === null) {
                data = form.data1
            } else {
                data = fbref.actions.getData('js')
            }
            return data.map((field, ind) => {
                if (field.type === 'paragraph') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">

                            <p>{removeHTMLTag(field.label)}</p>
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'date' + ind}>{removeHTMLTag(field.label)}</label>
                            <Calendar disabled showIcon ></Calendar>
                        </div>
                    )
                } else if (field.type === 'text') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'text' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'textarea') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textarea' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputTextarea disabled></InputTextarea>
                        </div>
                    )
                } else if (field.type === 'number') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'textno' + ind}>{removeHTMLTag(field.label)}</label>
                            < InputText keyfilter={'num'} disabled></InputText>
                        </div>
                    )
                } else if (field.type === 'select') {
                    return (
                        <div className="card   flex-wrap justify-content-center gap-3">
                            <label htmlFor={'select' + ind}>{removeHTMLTag(field.label)}</label>
                            <Dropdown options={field.values} ></Dropdown>
                        </div>
                    )
                } else if (field.type === 'radio-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'radio' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.label}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>
                    )
                } else if (field.type === 'checkbox-group') {
                    return (
                        <>

                            <div className="card   flex-wrap justify-content-center gap-3">
                                <label htmlFor={'checkbox' + ind} style={{ marginBottom: 15 }}>{removeHTMLTag(field.label)}</label>
                                {field.values.map((option) => {
                                    return (
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="ingredient1" name={option.label} value={option.value} checked={option.selected} />
                                            <label htmlFor="ingredient1" className="ml-2">{option.value}</label>
                                        </div>
                                    )
                                })

                                }
                            </div>
                        </>

                    )
                }
            })
        }
    }
    const onChangeIndicator = (item) => {
        setIndicator(item)
        if (item !== null) {
            if (item.newDataPoints !== undefined) {
                setSelected(item.newDataPoints)
            }

        } else {
            setSelected([])
        }

    }
    return (
        <div className="grid p-fluid">
            {selector.role === "eisqradmin" ? (
                <div className="col-12 md:col-12">
                    <div className="card ">
                        <div
                            className="col-12 md:col-12"
                            style={{
                                textDecoration: "underline",
                                textAlign: "center",
                                textDecorationColor: "green",
                            }}
                        >
                            <h5>Edit DF Form</h5>

                        </div>

                        <div>
                            <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                        DF ID

                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <label>DF{form.id}</label>

                                </div>

                            </div>
                            <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                        DF Title
                                        <span
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            &nbsp;*
                                        </span>
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <InputText
                                        value={form.title}
                                        type={'text'}
                                        min={1}
                                        onChange={(e) => {
                                            updateForm("title", e.target.value);
                                        }}

                                        placeholder="DF Title"
                                    />

                                </div>

                            </div>
                            {submitted && form.title.trim().length === 0 && (
                                <div
                                    className="col-12 " style={{
                                        display: 'flex',
                                        marginLeft: '16%'
                                    }}>
                                    <small
                                        className="p-invalid"
                                        style={{
                                            color: "red",
                                        }}
                                    >

                                        Title is mandatory
                                    </small>
                                </div>
                            )}
                               <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row"
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                      Tags
                                        <span
                                            style={{
                                                color: "red",
                                            }}
                                        >
                                            &nbsp;*
                                        </span>
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <MultiSelect value={!form.tags ? [] :  form.tags.filter(i => clienttag.map(j => j.id).includes(i))} onChange={(e) => { setForm((prev) => ({ ...prev, tags: e.value })) }} style={{ width: '100%' }} options={clienttag} optionLabel="name" display='chip' optionValue="id" 
                                        placeholder="Select Tags" className="w-full" />

                                </div>

                            </div>
                            <div
                                className="col-12 "
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <div
                                    style={{
                                        width: "20%",
                                        display: "flex",
                                        alignSelf: "center",
                                    }}
                                >
                                    <span>
                                        Comments

                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: "80%",
                                    }}
                                    className="p-inputgroup"
                                >
                                    <InputTextarea
                                        value={form.comments}
                                        type={'text'}
                                        min={1}
                                        onChange={(e) => {
                                            updateForm("comments", e.target.value);
                                        }}

                                        placeholder="Comments"
                                    />

                                </div>

                            </div>

                        </div>
                        <div style={{ marginTop: 20 }}>
                            <FormBuilder fbref={(e) => { fbref = e }} onSubmit={(e) => { updateForm('data1', e) }} values={JSON.stringify(form.data1)} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column", alignItems: 'flex-end'
                        }}>

                            {/* <div className="field col-4" >
                       <Button onClick={()=>{console.log(fbref); console.log(fbref.actions.save()) }} >Save Form</Button></div>  */}
                            <div className="field col-3" >
                                <Button onClick={() => { fbref.actions.clearFields() }}>Clear Form</Button>
                            </div>
                            <div className="field col-3" >
                                <Button onClick={() => { his.goBack() }}>Back to library</Button>
                            </div>
                            <div className="field col-3" >
                                <Button onClick={() => {
                                    console.log(fbref, form.data1)
                                    if (fbref !== null && fbref.actions.getData('js').length !== 0) { setPrevDialog(true) } else {
                                        Swal.fire({
                                            position: "center",
                                            icon: "info",
                                            title: "create form with least 1 field",
                                            showConfirmButton: false,
                                            timer: 1800,
                                        });
                                    }
                                }}>Preview DF</Button>
                            </div>
                            <div className="field col-3" >
                                <Button onClick={() => { addDCF() }}>Save DF</Button>
                            </div>
                        </div>
                        <div>

                        </div>

                        {/* 
                    <div className="col-12 md:col-12">

                        <Button label="Save" className="mr-2 mb-2" style={{ marginTop: 10, width: 100 }} onClick={(e) => { addClientApi() }}></Button>

                    </div> */}
                    </div>
                </div>
            ) : (
                <div className="card col-12">You have no rights to access this page</div>
            )}
            <Dialog
                visible={prevdialog}
                style={{
                    width: "450px",
                }}
                header={form.title + " preview"}
                modal
                className="p-fluid"
                footer={prevDialogFooter}
                onHide={() => { setPrevDialog(false) }}
            >
                {renderPreview()}
            </Dialog>
        </div>
    );
};

export default RFCollectionFormEdit;
