
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { API } from "../../constants/api_url";
import { DateTime } from "luxon";
const initialState = {
    // profile:{id:'12',name:'Venkat',mobno:'0000000'}
    userdetail: {},
    config: [],
    tierLabel: [],
    fyStartMonth: { fymonth: 1 }
}
export const fetchApi = createAsyncThunk('api/fetachApi', async (token) => {
    return axios.get(API.GetMe, { headers: { "Authorization": "Bearer" + " " + token } }).then((res) => res.data)
})

const setData = (state, action) => {
    if (action.payload.role === 'clientadmin' || action.payload.role === 'eisqradmin') {
        if (action.payload.information.blocked === undefined || !action.payload.information.blocked) {
            let data = action.payload
            if (data.role === 'clientadmin') {
                console.log(DateTime.fromISO(data.fyStartMonth,{zone:'utc'}).toLocal().month,DateTime.fromISO(data.fyStartMonth,{zone:'utc'}).month +1)
                data.information['cid'] = data.id
                data.clientId = data.id
                state.tierLabel = data.tierLabel ? data.tierLabel : ['Country', 'City', 'Site']
                state.fyStartMonth = data.fyStartMonth ?{fymonth:DateTime.fromISO(data.fyStartMonth,{zone:'utc'}).month+1 } : { fymonth: 1 }
            }
            state.userdetail = data;

        } else {
            localStorage.removeItem("token");
            Swal.fire({
                icon: 'error',
                title: 'Blocked',
                text: 'You have been blocked from platform, contact admin for futher details',
                returnInputValueOnDeny: () => {
                    console.log('deny')
                }
            })

        }
    } else {
        localStorage.removeItem("token");
        Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You have no access to this platform, contact admin for futher details',
            returnInputValueOnDeny: () => {
                console.log('deny')
            }
        })
    }

}

const userProfile = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoggedUserDetail: (state, action) => { state.userdetail = action.payload },
        resetLoggedUserDetail: (state) => {
            localStorage.clear();
            sessionStorage.clear(); state.userdetail = {}
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchApi.fulfilled, (state, action) => { setData(state, action) })

    }



})

export default userProfile.reducer
export const { setLoggedUserDetail, resetLoggedUserDetail } = userProfile.actions
