import React, { useState, useEffect } from "react";
import { API } from "../../constants/api_url";
import APIServices from "../../service/APIService";
import { Tree } from 'primereact/tree';
import { Dropdown } from "primereact/dropdown";
import CheckboxTree from 'react-checkbox-tree';
import { Button } from "primereact/button";
// import '../../Styles/react-checkbox-tree.css';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import 'react-checkbox-tree/src/less/react-checkbox-tree.less';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import Swal from "sweetalert2";
const EFDropDownClientConfigAdmin = () => {

    const [efddclientobj, setEfDdClientObj] = useState({ userProfileId: null, efStandardId: null, efCategoryId: null, selected: [], expanded: [] });
    const [stdlist, setStdList] = useState([])
    const [adminlist, setAdminList] = useState([])
    const [catlist, setCatList] = useState([])
    const [efddlist, setEfDdList] = useState([])
    const [assignlist, setAssignList] = useState([])
    const [selectedKeys, setSelectedKeys] = useState(null);
    const selector = useSelector((state) => state.user.userdetail)
    const forceUpdate = useForceUpdate();
    useEffect(() => {

        const promise0 = APIServices.get(API.EF_Std)
        const promise1 = APIServices.get(API.UserProfile)
        Promise.all([promise0, promise1]).then((values) => {
            setStdList(values[0].data)
            setAdminList(values[1].data.filter(i => i.role === 'clientadmin'))

        })
    }, [])
    const getTierData = (id) => {
        let sel = efddclientobj;
        sel.id = undefined
        sel.efStandardId = id
        sel.efCategoryId = null
        setSelectedKeys(null)
        setEfDdClientObj(sel);

        APIServices.get(API.EF_Category_Std(id),
        ).then((res) => {
            setCatList(res.data);
        });
    };
    const getTier1Data = (id) => {
        let sel = efddclientobj;
        sel.efCategoryId = id;
        let arr = []
        setEfDdClientObj(sel);
        console.log(sel)
        let efstd = {

            include: [
                {
                    relation: "newEfSubcategory1s",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory2s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory3s",
                                            scope: {
                                                include: [{ relation: "newEfSubcategory4s" }],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],

        };
        let filter = { where: { efCategoryId: id, efStandardId: sel.standard } }
        forceUpdate()
        APIServices.get(API.Client_EF_Cat_Ass_Up(efddclientobj.userProfileId) + `?filter=${encodeURIComponent(JSON.stringify(filter))}`).then((res) => {
            if (res.data.length !== 0 && res.data.length === 1) {
                let checked = {}
                res.data[0].checked_ids.forEach((key) => {
                    checked[key] = { checked: true, partialChecked: false }
                })
                res.data[0].partial_ids.forEach((key) => {
                    checked[key] = { checked: false, partialChecked: true }
                })
                setSelectedKeys(() => checked)
                console.log(checked, res.data)
                setEfDdClientObj((prev) => ({ ...prev, id: res.data[0].id }))
                forceUpdate()
            } else if (res.data.length === 0) {
                setEfDdClientObj((prev) => ({ ...prev, id: undefined }))
                setSelectedKeys(null)
            } else {
                setEfDdClientObj((prev) => ({ ...prev, efStandardId: null, efCategoryId: null, id: undefined }))
            }


        }).catch((e) => {

        })
        APIServices.get(API.EF_Category_edit(id) + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`,
        ).then((res) => {
            if (res.data.newEfSubcategory1s) {
                console.log(convertDataForTree(res.data.newEfSubcategory1s))
                setEfDdList(convertDataForTree(res.data.newEfSubcategory1s))
            }
        });
    };
    function convertDataForTree(data) {
        return data.map(category => {
            const categoryNode = {
                key: "1-" + category.id.toString(),
                label: category.title,
                children: []
            };

            if (category.newEfSubcategory2s) {
                category.newEfSubcategory2s.forEach(subcategory2 => {
                    const subcategory2Node = {
                        key: "2-" + subcategory2.id.toString(),
                        label: subcategory2.title,
                        children: []
                    };

                    if (subcategory2.newEfSubcategory3s) {
                        subcategory2.newEfSubcategory3s.forEach(subcategory3 => {
                            const subcategory3Node = {
                                key: "3-" + subcategory3.id.toString(),
                                label: subcategory3.title,
                                children: []
                            };

                            if (subcategory3.newEfSubcategory4s) {
                                subcategory3.newEfSubcategory4s.forEach(subcategory4 => {
                                    subcategory3Node.children.push({
                                        key: "4-" + subcategory4.id.toString(),
                                        label: subcategory4.title
                                    });
                                });
                            }

                            subcategory2Node.children.push(subcategory3Node);
                        });
                    }

                    categoryNode.children.push(subcategory2Node);
                });
            }

            return categoryNode;
        });
    }
    function processCheckboxData(data) {
        const selected_ids = Object.keys(data);
        const checked_ids = [];
        const partial_ids = [];

        selected_ids.forEach(key => {
            if (data[key].checked) {
                checked_ids.push(key);
            } else if (data[key].partialChecked) {
                partial_ids.push(key);
            }
        });

        return { selected_ids, checked_ids, partial_ids };
    }
    const saveClientEfCategory = async () => {
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Alert</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to ${efddclientobj.id === undefined ? 'save' : 'update'}  assignment
          </div>`,

            confirmButtonColor: 'green',
            showCancelButton: true,
            confirmButtonText:
                'Yes',
        })
        if (accept) {
            if (efddclientobj.id === undefined) {
                let newObj = { ...processCheckboxData(selectedKeys), efCategoryId: efddclientobj.efCategoryId, efStandardId: efddclientobj.efStandardId, created_on: DateTime.utc(), created_by: selector.id }
                APIServices.post(API.Client_EF_Cat_Ass_Up(efddclientobj.userProfileId), newObj).then((res) => {
                    setEfDdClientObj(res.data)
                })
            } else {
                let newObj = { ...processCheckboxData(selectedKeys), modified_on: DateTime.utc(), modified_by: selector.id }
                APIServices.patch(API.Client_EF_Cat_Edit(efddclientobj.id), newObj).then((res) => {

                })
            }
        }
    }
    return (
        <div className="col-12">
            {selector.role === 'eisqradmin' ?
                <div className="card" style={{ height: 'calc(100vh - 100px)', overflow: 'auto' }}>
                    <h3> EF Category Configuration for Client </h3>
                    <div>
                        <div className="col-12 grid align-items-center justify-content-between">
                            <div className="col-10 grid align-items-center">
                                <label className="col-2 ">
                                    Select Client <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-5">
                                    <Dropdown options={adminlist} style={{ width: '100%' }} filter optionLabel="information.companyname" optionValue="id" value={efddclientobj.userProfileId} onChange={(e) => { setEfDdClientObj(prev => ({ ...prev, id: undefined, efCategoryId: null, efStandardId: null, userProfileId: e.value })) }} />

                                </div>

                            </div>

                        </div>
                        {efddclientobj.userProfileId !== null && <div className="col-12 grid align-items-center justify-content-between">
                            <div className="col-10 grid align-items-center">
                                <label className="col-2 ">
                                    Standard <span style={{ color: 'red' }}>*</span>
                                </label>
                                <div className="col-5">
                                    <Dropdown options={stdlist} style={{ width: '100%' }} filter optionLabel="title" optionValue="id" value={efddclientobj.efStandardId} onChange={(e) => { getTierData(e.value) }} />

                                </div>

                            </div>

                        </div>}
                        {efddclientobj.efStandardId !== null && <div className="col-10 grid align-items-center">
                            <label className="col-2">
                                Category <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div className="col-5">
                                <Dropdown options={catlist} style={{ width: '100%' }} filter optionLabel="title" optionValue="id" value={efddclientobj.efCategoryId} onChange={(e) => { getTier1Data(e.value) }} />
                                {/* <InputText type='text' className={submitted && newstandards.category.trim().length === 0 && 'p-invalid'} value={newstandards.category} onChange={(e) => { updateStd('category', e.target.value) }} /> */}
                            </div>

                        </div>}
                        {efddclientobj.efCategoryId !== null &&
                            <>
                                <Tree value={efddlist} selectionMode="checkbox" selectionKeys={selectedKeys} onSelectionChange={(e) => { console.log(e.value); setSelectedKeys(e.value) }} className="w-full" />
                                <div>
                                    <Button label={efddclientobj.id === undefined ? 'Save' : 'Update'} onClick={saveClientEfCategory} ></Button>
                                </div>
                            </>
                            // <CheckboxTree
                            //     nodes={[{"value":"9","label":"Cars (by market segment)","parentId":null,"children":[{"value":"51","label":"Mini","parentId":9,"children":[{"value":"175","label":"Diesel","parentId":51,"children":[{"value":"1","label":"km","parentId":"175","children":[]},{"value":"2","label":"miles","parentId":"175","children":[]}]}]}]}]
                            // }

                            //     checked={efddclientobj.selected}
                            //     expanded={efddclientobj.expanded}
                            //     onCheck={checked => {setEfDdClientObj(prev => ({ ...prev, selected:checked })); console.log(efddclientobj); forceUpdate()}}
                            //     onExpand={selected => {setEfDdClientObj(prev => ({ ...prev, checked:selected })); forceUpdate() }}
                            // />
                        }
                    </div>
                </div>
                :
                <div className="card col-12">You have no rights to access this page</div>
            }
        </div>
    )
}
export default EFDropDownClientConfigAdmin