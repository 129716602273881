import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const data = [
    { 
        air: 'Refrigerant losses (metric ton (mt)) CO2e',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
    { 
        air: 'Volatile organics (thousand kg)',
        '2021': 100,
        '2022': 200,
        '2020One': 150,
        '2020Two': 180,
        '2020Three': 300,
        '2020Four': 450,
        '2020Five': 450,
        'GRI Indicator': 'Some Value'
    },
];

const AirDataTable = () => {
    const tableStyle = { 
        borderRadius: '10px',
        overflow: 'hidden',
        border: '1px solid #EBEDEF', // Set border color to grey
        fontSize: '16px',
        fontWeight: '400'
    };

    return (
        <DataTable value={data} style={tableStyle}>
            <Column field="air" header="Air Emissions"/>
            <Column field="2021" header="2021" />
            <Column field="2022" header="2022" />
            <Column field="2020One" header="2020" />
            <Column field="2020Two" header="2020" />
            <Column field="2020Three" header="2020" />
            <Column field="2020Four" header="2020" />
            <Column field="2020Five" header="2020" />
            <Column field="GRI Indicator" header="GRI Indicator" />
        </DataTable>
    );
}

export default AirDataTable;
