import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MultiLine = () => {
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const generateRandomData = () => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const data = [];
        months.forEach(month => {
            data.push({
                month: month,
                reuse: getRandomInt(50, 500),
                recycle: getRandomInt(50, 500),
                landfill: getRandomInt(50, 500),
                incineration: getRandomInt(50, 500),
                coprocessing: getRandomInt(50, 500)
            });
        });
        return data;
    };

    const data = generateRandomData();



    return (
        <Card>
            <p className='card-title'>Waste Generation</p>
            <p className='card-content'>Cupiditate tempore aliquid voluptatem est numquam distinctio dolor corporis cupiditate. Voluptatibus</p>

            <ResponsiveContainer width="100%" height={500}>
                <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="reuse" stroke="#8884d8" name="Reuse" />
                    <Line type="monotone" dataKey="recycle" stroke="#82ca9d" name="Recycle" />
                    <Line type="monotone" dataKey="landfill" stroke="#ffc658" name="Landfill" />
                    <Line type="monotone" dataKey="incineration" stroke="#FF5733" name="Incineration" />
                    <Line type="monotone" dataKey="coprocessing" stroke="#8B008B" name="Co-processing" />
                </LineChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default MultiLine;
