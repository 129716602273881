import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import $ from "jquery";
import { API } from "../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import APIServices from "../service/APIService";

window.jQuery = $;
window.$ = $;

const LocationConfigByClient = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [user, setUser] = useState([]);
    const [sitecount, setSiteCount] = useState(0)
    const [selDataPoint, setSelDataPoint] = useState([])
    const configtype = [{ name: 'Location' }, { name: 'Data Point' }]
    const forceUpdate = useForceUpdate();
    const [old, setOld] = useState([]);
    const [datapoint, setDataPoint] = useState([])
    const [location, setLocation] = useState([]);
    const [userList, setUserList] = useState([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const [label1,label2,label3] = useSelector((state) => state.user.tierLabel);
    const [userConfig, setUserConfig] = useState({
        name: "",
        location: {
            countrylist: [],
            newcountry: "",
            newcity: "",
            newlocation: "",
            selectedcountry: "",
            citylist: [],
            selectedcity: "",
            locationlist: [],
            selectedlocation: "",
        },
    });
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [selected, setSelected] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
    });
    const [cascade, setCascade] = useState("");
    const [showSave, setShowSave] = useState(0);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [moduleList, setModuleList] = useState({
        mod: [],
        title: [],
        topic: [],
    });

    useEffect(async () => {
        setOld(selector.information);
        let uriString2 = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let REQUEST_URL_WITH_ID_2 =API.LocationOne_UP(selector.id) 
        let url_2 = REQUEST_URL_WITH_ID_2 + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        
        APIServices.get(url_2)
            .then((res) => {
                let count = 0;
                res.data.forEach((i)=>{
                    if(i.locationTwos !== undefined){
                        i.locationTwos.forEach((j)=>{
                            if(j.locationThrees !== undefined){
                                count =count + j.locationThrees.length
                            }
                        })
                    }
                })
                setSiteCount(count)
            })
        APIServices.get( API.UserProfile
        ).then((res) => {
            let locuser = [];
            setUserList(res.data);
            res.data.forEach((item) => {
                if (item.role === "clientadmin") {
                    locuser.push({
                        name: item.information.companyname,
                        id: item.id,
                    });
                }
            });

            setUser(locuser);
        });

        APIServices.get( API.LocationOne_UP(selector.id),
        )
            .then((res) => {
                if (res.status === 200) {
                    setTier1(res.data);
                    setModule({
                        tier1: "",
                        tier2: "",
                        tier3: "",
                    });
                }
            })
            .catch((e) => {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: `Something went wrong, try again later `,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });


    }, [selector]);
    const updateUserConfig = (obj, val) => {
        let loc = userConfig;
        loc[obj] = val;
        if (val !== undefined) {
            APIServices.get(API.LocationOne_UP(val.id),
            )
                .then((res) => {
                    if (res.status === 200) {
                        setTier1(res.data);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
            setSelected({ tier1: "", tier2: "", tier3: "" });
            setUserConfig(loc);
        }

        setCascade("");
        forceUpdate();
    };
    const showTier3CM = (e, ind) => {

        tier3ref.current.forEach((i, j) => {
            if (j === ind) {
                tier3ref.current[ind].show(e)
            } else {
                if (tier3ref.current[j] !== null) {
                    tier3ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
    }
    const deleteTier1 = async (item, type) => {
        let index = tier1.findIndex((i) => {
            return i.id === item.id;
        });
        let loc = tier1
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.name}" from ${type} list</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.LocationOne_Edit(item.id)).then((res) => {
                loc.splice(index, 1);
                let loc2 = selected

                loc2.tier1 = ""
                loc2.tier2 = ""
                setSelected(loc2)
                setTier1(loc)
                forceUpdate()
            })
        }
    }
    const deleteTier2 = async (item, type) => {
        let index = tier2.findIndex((i) => {
            return i.id === item.id;
        });
        let loc = tier2
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.name}" from ${type} list</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.LocationTwo_Edit(item.id)).then((res) => {
                loc.splice(index, 1);
                setTier2(loc)
                let loc2 = selected

                loc2.tier2 = ""
                setSelected(loc2)
                forceUpdate()
            })
        }
    }
    const deleteTier3 = async (item, type) => {
        let index = tier3.findIndex((i) => {
            return i.id === item.id;
        });
        let loc = tier3
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Warning</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to delete "${item.name}" from ${type} list</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Delete',

        })
        if (accept) {
            APIServices.delete(API.LocationThree_Edit(item.id)).then((res) => {
                loc.splice(index, 1);
                setTier3(loc)
                forceUpdate()
            })
        }
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
    }
    const addNewTier1 = () => {
        let index = tier1.findIndex((i) => {
            return i.name.trim().toLowerCase() === module.tier1.trim().toLowerCase();
        });

        if (index === -1 && module.tier1.trim().length !== 0) {
            APIServices.post( API.LocationOne_UP(userConfig.name.id),
                 {
                    name: module.tier1.trim(),
                }
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier1;
                        tier.push(res.data);
                        setTier1(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier2 = () => {
        let index = tier2.findIndex((i) => {
            return i.name.trim().toLowerCase() === module.tier1.trim().toLowerCase();
        });

        if (index === -1 && module.tier2.trim().length !== 0) {
            APIServices.post( API.LocationTwo_UP(selected.tier1),
                 {
                    name: module.tier2.trim(),
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        let tier = tier2;
                        tier.push(res.data);
                        setTier2(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier3 = () => {
        let index = tier3.findIndex((i) => {
            return i.name.trim().toLowerCase() === module.tier1.trim().toLowerCase();
        });

        if (index === -1 && module.tier3.trim().length !== 0) {
            APIServices.post( API.LocationThree_UP(selected.tier2),
             {
                    name: module.tier3.trim(),
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier3;
                        tier.push(res.data);
                        setTier3(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const updateTier1 = (e) => {
        let index = tier1.findIndex((i) => {
            return i.name === e.previousValue;
        });
        if (index !== -1) {
            APIServices.patch( API.LocationOne_Edit(tier1[index].id),
                 {
                    name: e.value.trim(),
                },
            ).then((res) => {
                forceUpdate();
            });
        }
    };
    const getTier2Data = (id) => {
        let sel = selected;
        sel.tier1 = id;
        sel.tier2 = "";
        setSelected(sel);

        APIServices.get(API.LocationTwo_UP(id),
        ).then((res) => {
            setTier2(res.data);
        });
    };
    const getTier3Data = (id) => {
        let sel = selected;
        sel.tier2 = id;
        setSelected(sel);
        forceUpdate();
        APIServices.get( API.LocationThree_UP(selected.tier2),
        ).then((res) => {
            setTier3(res.data);
        });
    };
    const getTier4Data = (id) => {
        let sel = selected;
        sel.tier3 = id;
        setSelected(sel);
        forceUpdate();
    };
    const updateTier2 = (e) => {
        let index = tier2.findIndex((i) => {
            return i.name === e.previousValue;
        });

        APIServices.patch(API.LocationTwo_Edit(tier2[index].id),
             {
                name: e.value.trim(),
            },
        ).then((res) => { });
    };
    const updateTier3 = (e) => {
        let index = tier3.findIndex((i) => {
            return i.name === e.previousValue;
        });

        APIServices.patch( API.LocationThree_Edit(tier3[index].id),
             {
                name: e.value.trim(),
            },
        ).then((res) => { });
    };

    const updateTierValue = (obj, e) => {
        let mod = module;
        mod[obj] = e.target.value;
        setModule(mod);
        forceUpdate();
    };

    return (
        <div className="grid">
            <div className="col-12">
                {selector.role === "clientadmin" ?
                    <div className="card">

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div><h4>View Site Configuration</h4></div>
                            <div><h6>Total Site(s) - <span style={{ color: '#005284' }}>{sitecount}</span></h6></div>
                        </div>


                        <div className="field">
                            <div style={{ padding: 10 }}>
                                <div className="col-12" style={{ marginBottom: -15 }}>
                                    <div className="grid">
                                        <div className="col-4"><label style={{
                                            background: 'cornflowerblue',
                                            display: 'flex',
                                            padding: '5px', color: 'white',
                                            borderRadius: '5px'
                                        }}>{label1}</label></div>

                                        <div className="col-4"><label style={{
                                            background: 'cornflowerblue',
                                            display: 'flex',
                                            padding: '5px', color: 'white',
                                            borderRadius: '5px'
                                        }}>{label2}</label></div>

                                        <div className="col-4"><label style={{
                                            background: 'cornflowerblue',
                                            display: 'flex',
                                            padding: '5px', color: 'white',
                                            borderRadius: '5px'
                                        }}>{label3}</label></div></div>
                                </div>
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="col-4">

                                            <div
                                                className="col-12"
                                                style={{
                                                    border: "1px solid grey",
                                                    height: "25vh",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                {tier1.length === 0 ? (
                                                    <text
                                                        style={{
                                                            padding: 5,
                                                        }}
                                                    >
                                                        {label1+" list not found/added"}
                                                    </text>
                                                ) : (
                                                    tier1.map((item, cyindex) => {
                                                        return (

                                                            <div
                                                                onClick={() => {
                                                                    getTier2Data(item.id);
                                                                }}
                                                                onContextMenu={(e) => { showTier1CM(e, parseInt(`1${item.id}`)) }}
                                                                style={{
                                                                    color: selected.tier1 === item.id && "white",
                                                                    background: selected.tier1 === item.id && "grey",
                                                                    borderBottom: '0.5px ridge grey',

                                                                    margin: '5px',
                                                                    padding: '5px',
                                                                    borderRadius: '5px'
                                                                }}
                                                            >

                                                                <label >{item.name}</label>

                                                            </div>


                                                        );
                                                    })
                                                )}
                                            </div>
                                        </div>
                                        {selected.tier1 !== "" && (
                                            <div className="col-4">

                                                <div
                                                    className="col-12"
                                                    style={{
                                                        border: "1px solid grey",
                                                        height: "25vh",
                                                        overflow: "scroll",
                                                    }}
                                                >
                                                    {tier2.length === 0 ? (
                                                        <text
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >
                                                            {label2+" list not found/added"}
                                                        </text>
                                                    ) : (
                                                        tier2.map((item, cyindex) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        getTier3Data(item.id);
                                                                    }}
                                                                    onContextMenu={(e) => { showTier2CM(e, parseInt(`2${item.id}`)) }}
                                                                    style={{
                                                                        color: selected.tier2 === item.id && "white",
                                                                        background: selected.tier2 === item.id && "grey",
                                                                        borderBottom: "0.5px ridge grey",
                                                                        margin: '5px',
                                                                        padding: '5px',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                >
                                                                    <label>{item.name}</label>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {selected.tier2 !== "" && (
                                            <div className="col-4">

                                                <div
                                                    className="col-12"
                                                    style={{
                                                        border: "1px solid grey",
                                                        height: "25vh",
                                                        overflow: "scroll",
                                                    }}
                                                >
                                                    {tier3.length === 0 ? (
                                                        <text
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >
                                                            {label3+" list not found/added"}
                                                        </text>
                                                    ) : (
                                                        tier3.map((item, cyindex) => {
                                                            return (
                                                                <div
                                                                    onClick={() => {
                                                                        getTier4Data(item.id);
                                                                    }}
                                                                    onContextMenu={(e) => { showTier3CM(e, parseInt(`3${item.id}`)) }}
                                                                    style={{
                                                                        borderBottom: "0.5px ridge grey",
                                                                        margin: '5px',
                                                                        padding: '5px',
                                                                        borderRadius: '5px'
                                                                    }}
                                                                >
                                                                    <label>{item.name}</label>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    :
                    <div className="col-12 card">You have no rights to access this page </div>}
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(LocationConfigByClient, comparisonFn);
