import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';





import EmptyPage from './client/EmptyPage';


import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from "react-redux";

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';

import './assets/layout/layout.scss';
import './App.scss';

import EFInputEntryRespo from './eisqr/other/ef_respo_ip';
import ClimateChangeDB from './client/dashboard/ClimateChangeDB';
import AddClient from './eisqr/client/AddClient';
import Login from './Login';
import AddClientUser from './client/admin/AddClientUser';
import adminCCDashboard from './client/dashboard/adminCCDashboard';

import clientLocationConfig from './eisqr/client/clientLocationConfig';
import ClimateChangeInitivative from './client/admin/ccInitivative';
import DataPointConfig from './eisqr/other/DataPointConfig';

import DataCollectionForm from './eisqr/forms/dataCollectionForm';
import DataCollectionLibrary from './eisqr/forms/dataColectionLibrary';
import DataCollectionFormEdit from './eisqr/forms/dataCollectionFormEdit';
import clientUserDCFAssignment from './client/admin/clientUserDCFAssignment';
import clientDCFAssignment from './eisqr/client/clientDCFAssignment';
import locationConfigByClient from './client/locationConfigByClient';

import AddClientSupplier from './eisqr/client/addClientSupplier';
import ClientIndicators from './client/admin/ClientIndicators';
import clientDCFSupplierAssignment from './eisqr/client/clientDCFSupplierAssignment';
import clientSupplierDCFAssignment from './client/admin/clientSupplierDCFAssignment';

import Excel2JSON from './eisqr/other/excel2json_internal';
import BRSRReport from './client/reports/BRSR_Report__';
import StandardsConfig from './eisqr/other/StandardsConfig';
import tableReportDashboard from './client/dashboard/tableReportDashboard';
import DashboardCC from './client/dashboard/DashboardCC';
import dummy_report from './client/reports/dummy_report';
import approvedLogs from './client/dashboard/approvedLogs';
import newDataPointsTransfer from './eisqr/other/newDataPointsTransfer';
import DataCollectionFormTwo from './eisqr/forms/RFCollectionForm';
import reportNameConfig from './eisqr/other/reportNameConfig';
import QualitativeDCFListing from './client/QualitativeDCFListing';
import DataCollectionLibraryTwo from './eisqr/forms/RFCollectionLibrary';
import RFCollectionFormEdit from './eisqr/forms/RFCollectionFormEdit';
import { API } from './constants/api_url';
import CarbonFootPrinting from './client/reports/CarbonFootPrinting';
import PasswordReset from './passwordReset';
import EFLibrary from './eisqr/EF/EFLibrary';
import NasdaqReport from './client/reports/Nasdaq_Report';
import SGX_Report from './client/reports/SGX_Report';
import CDP_Report from './client/reports/CDP_Report';
import Assurance from './client/assurance_';
import ConsolidatedForm from './eisqr/forms/ConsolidatedForm';

import EcoVadis_report_ from './client/reports/EcoVadis_report_';
import ConsolidateFormLibrary from './eisqr/forms/consolidateFormLibrary';

import cf_preview_view from './client/user_form_preview/cf_preview_view';

import GRI_Report from './client/reports/GRI_Report';
import efCategoryConfiguration from './eisqr/EF/efCategoryConfiguration';

import '../src/Styles/custom.css'
import '../src/Styles/ui_ux.css'
import '../src/Styles/stt_ui.css'
// import '../src/Styles/sidebar.css'

import APIServices from './service/APIService';

import AdminHomeScreen from './client/dashboard/AdminHomeScreen';
import ReportListing from './client/ReportListing';

import Sidebar from './components/sidebar/sidebar';

import '../src/components/sidebar/sidebar.scss'
import DCFInputEntryPreview from './client/user_form_preview/DCFInputEntryPreview';

import DCFInputEntrySubmissionPreview from './client/user_form_preview/DCFInputEntrySubmissionPreview';



import BRSR_Core_report from './client/reports/BRSR_Core_report';
import Approver from './client/admin/approver';
import QualitativeRFListing from './client/QualitativeRFListing';
import clientSupplierSRFAssignment from './client/admin/clientSupplierSRFAssignment';
import QualitativeRFListingOld from './client/QualitativeRFListingOld';
import AssignDCFToUser from './client/admin/AssignDCFToUser';
import MaterialityDashboard from './Materiality/dashboard/MaterialityDashboard';
import EFDropDownClientConfig from './client/admin/EFDropDownClientConfig';
import EFDropDownClientConfigAdmin from './client/admin/EFDropDownClientConfigAdmin';
import LoginForm from './LoginNew';
import CFP from './client/reports/CFP';
import LoginNew from './LoginNew';
import { resetOverallPendingData } from './RTK/Background/pendingData';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import OverallQuantitativeSubmissions from './client/dashboard/OverallQuantitativeSubmissions';
import quantitativeOverallSubmissionPreview from './client/user_form_preview/quantitativeOverallSubmissionPreview';
import CertificationConfig from './eisqr/other/CertificationConfig';
import RoleConfig from './eisqr/other/RoleConfig';
import PerformanceDashboard from './client/dashboard/Performance/PerformanceDashboard';
import LeadershipDashboard from './client/dashboard/Leadership/LeadershipDashboard';
import Initiative from './client/admin/Initiative';
import QuantitativeApproverDB from './client/forms/Approver/QuantitativeApproverDB';
import DropdownConfig from './eisqr/other/DropdownConfig';
import AirPortCodeConfig from './eisqr/other/AirPortCodeConfig';
import AssuranceReport from './client/dashboard/Assurance/AssuranceReport';
import IndicatorConfiguration from './eisqr/other/IndicatorConfiguration';
import ApproverIndicatorClientAssignment from './eisqr/client/ApproverIndicatorClientAssignment';
const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory()
    const config = useSelector(state => state.user.userdetail)
    const dispatch = useDispatch()
    PrimeReact.ripple = true;

    const groupedReports = [

        { label: 'GRI', value: 11, to: '/dummy_report' },
        { label: 'SASB', value: 12, to: '/dummy_report' },
        { label: 'GHG Inventory', value: 21, to: '/dummy_report' },
        { label: 'TCFD', value: 22, to: '/dummy_report' },
        { label: 'Boursa Kuwait', value: 31, to: '/dummy_report' },
        { label: 'Bursa Malaysia', value: 32, to: '/dummy_report' },
        { label: 'HKEX', value: 33, to: '/dummy_report' },
        { label: 'NASDAQ', value: 34, to: '/dummy_report' },
        { label: 'SGX', value: 35, to: '/dummy_report' },
        { label: 'SEBI (BRSR)', value: 36, to: '/brsr_report' },
        { label: 'CDP', value: 41, to: '/dummy_report' },
        { label: 'EcoVadis', value: 42, to: '/dummy_report' },
        { label: 'MSCI', value: 43, to: '/dummy_report' },
        { label: 'S&P', value: 44, to: '/dummy_report' },
        { label: 'Sustainalytics', value: 45, to: '/dummy_report' },
        { label: 'MCfS', value: 51, to: '/dummy_report' },
        { label: 'Unilever', value: 52, to: '/dummy_report' },
        { label: 'Goldman Sachs', value: 61, to: '/dummy_report' },
        { label: 'EQT', value: 62, to: '/dummy_report' },
        { label: 'ESG Report', value: 71, to: '/dummy_report' },
        { label: 'CSR Report', value: 72, to: '/dummy_report' },
        { label: 'MOEFCC', value: 81, to: '/dummy_report' }
    ];
    const [stdlist, setSTDList] = useState([])
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {

        if (localStorage.getItem('token') === null && !location.pathname.match('/reset-password-finish')) {
            history.push('/')
            sessionStorage.removeItem('temp')
        }
    }, [])

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
                setSTDList(res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 }))
            })
        }

        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        dispatch(resetOverallPendingData())
        dispatch(resetLoggedUserDetail());
        localStorage.clear()
        sessionStorage.clear()
        history.push('/')

    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }
    const eisqradmin = [

        {
            label: 'Forms', gicon: 'article', icon: 'material-icons', to: '/form/',
            items: [

                { label: ' DCF Library', to: '/form/quantitative_dcf_list', icon: 'material-icons', gicon: 'library_books' },
                { label: 'DF Library', to: '/form/qualitative_dcf_list', icon: 'material-icons', gicon: 'library_books' },
                { label: 'SRF Library', to: '/form/cf_list', icon: 'material-icons', gicon: 'library_books' },

            ]
        },
        {
            label: 'Client Configuration', icon: 'material-icons', gicon: 'manage_accounts', to: '/config/',
            items: [
                { label: 'Client Management', to: '/config/add_new_client', icon: 'material-icons', gicon: 'domain_add' },
                { label: 'Client EF Category Assignment', to: '/ef_dd_client_config', icon: 'material-icons', gicon: 'assignment_turned_in' },
                // { label: 'Module Configuration', to: '/module_config', gicon: 'tune', icon: 'material-icons', },
                { label: 'Configure Client Sites', to: '/config/client_location', icon: 'material-icons', gicon: 'edit_location_alt' },

                { label: 'Client DCF/SRF Assignment', to: '/config/dcf_client_assignment', icon: 'material-icons', gicon: 'assignment_turned_in' },
                // { label: 'Client DCF Assignment', to: '/dcf_client_assignment', icon: 'material-icons', gicon: 'assignment_turned_in' },

                { label: 'Supplier DCF Assignment', to: '/config/dcf_cs_assignment', icon: 'material-icons', gicon: 'assignment_turned_in' },



            ]
        },
        {
            label: 'EF Configuration', icon: 'material-icons', gicon: 'settings_applications', to: '/ef',
            items: [

                { label: 'Emission Factor Library', to: '/ef_library', icon: 'material-icons', gicon: 'settings_suggest' },

                { label: 'EF Category Configuration', to: '/ef_category_config', icon: 'material-icons', gicon: 'settings_suggest' },

            ]
        },
        { label: 'IATA Code Configuration', to: '/air_iata_config', icon: 'material-icons', gicon: 'flight' },
        { label: 'Certificate Configuration', to: '/certificate_config', icon: 'material-icons', gicon: 'collections_bookmark' },
        { label: 'Role Configuration', to: '/role_config', icon: 'material-icons', gicon: 'person_pin' },
        { label: 'Data Point Configuration', to: '/data_points_config', icon: 'material-icons', gicon: 'settings_suggest' },
        { label: 'Framework Configuration', to: '/report_name_config', icon: 'material-icons', gicon: 'settings_suggest' },
        {
            label: 'Logout', icon: 'pi pi-sign-out ', to: '/logout', exit: true
        }

    ];
    const menu1 = [
        {

            items: [
                { label: 'Home', to: '/input_cc_dashboard', icon: 'pi pi-desktop' },
                { label: 'Goals,Targets & Actions', to: '/client/goals', icon: 'material-icons', gicon: 'trending_up' },
                { label: 'Selected Indicators', to: '/client_indicators', icon: 'material-icons', gicon: 'traffic' },
                { label: 'Estimation Tools', to: '', icon: 'material-icons', gicon: 'calculate' },


            ]

        },

        {
            label: 'ESG', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Environment', icon: 'material-icons', gicon: 'eco',
                    items: [
                        {
                            label: 'Climate Change', icon: 'material-icons', gicon: 'nights_stay',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop', to: '/climate_change_db' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil', to: '/input_entry_add' },



                                { label: 'Approval', icon: 'pi pi-fw pi-check-circle', to: '/input_entry_approval_new' },
                                { label: 'Approved Data', to: '/input_approval_entry', icon: 'pi pi-server' },
                                { label: 'Final Approval', to: '/input_approval_entry_final', icon: 'pi pi-server' }


                            ]
                        },
                        {
                            label: 'Energy Management', icon: 'material-icons', gicon: 'energy_savings_leaf',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Waste Management', icon: 'material-icons', gicon: 'recycling',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }


                    ]
                },
                {
                    label: 'Social', icon: 'material-icons', gicon: 'language',
                    items: [
                        {
                            label: 'Diversity in gender, LBGT, physically challenged', icon: 'material-icons', gicon: 'diversity_3',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Equal opportunity', icon: 'material-icons', gicon: 'balance',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Non-Discrimination', icon: 'material-icons', gicon: 'groups_2',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Occupational Health & Safety', icon: 'material-icons', gicon: 'health_and_safety',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Employment', icon: 'material-icons', gicon: 'badge',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Training and Education', icon: 'material-icons', gicon: 'school',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Labour Relations', icon: 'material-icons', gicon: 'social_distance',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Value/ opportunities created for the community', icon: 'material-icons', gicon: 'settings_accessibility',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }

                    ]
                },
                {
                    label: 'Governance', icon: 'material-icons', gicon: 'foundation',
                    items: [
                        {
                            label: 'Data Security', icon: 'material-icons', gicon: 'security',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Customer Privacy', icon: 'material-icons', gicon: 'admin_panel_settings',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Whistle Blower', icon: 'material-icons', gicon: 'sports',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Anti-Bribery and Anti-Corruption', icon: 'material-icons', gicon: 'gpp_bad',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Human Rights practices', icon: 'material-icons', gicon: 'groups_3',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Socio-economic compliance', icon: 'material-icons', gicon: 'assignment_late',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }

                    ]
                }

            ]
        },
        {
            label: 'Admin', icon: 'pi pi-fw pi-search',
            items: [
                { label: 'User Management', to: '/client/add_user', icon: 'pi pi-user-plus' },
                { label: 'Supplier Management', to: '/client/add_supplier', icon: 'pi pi-user-plus' },
                { label: 'Viewer Management', to: '', icon: 'pi pi-user-plus' },
                { label: 'Internal DCF Assignment', to: '/dcf_user_assign', icon: 'material-icons', gicon: 'rebase_edit' },
                { label: 'Supplier DCF Assignment', to: '/dcf_supplier_assign', icon: 'material-icons', gicon: 'rebase_edit' },

                { label: 'View Site Configuration', to: '/client/location_config', icon: 'material-icons', gicon: 'edit_location_alt' },


                // { label: 'User Config', to: '/client/user_config', icon: 'pi pi-sliders-h' },
                // { label: 'Approver Config', to: '/client/approver_config', icon: 'pi pi-sliders-h' },





            ]
        }

    ];
    const renderReportList = (list, row) => {
        let data = JSON.parse(JSON.stringify(list))
        let newlist = []
        data.forEach((i) => {
            if (Number(String(i).charAt(0)) === row) {
                let index = groupedReports.findIndex((l) => { return l.value === i })
                if (index.length !== -1) {
                    newlist.push({ label: groupedReports[index].label, to: groupedReports[index].to })
                }

            }
        })
        return newlist
    }
    const getRenderSTDs = (reportid) => {
        let reports = []
        stdlist.forEach((i) => {
            i.reportNameTwos.forEach((j) => {
                if (reportid.includes(j.id)) {
                    let index = reports.findIndex((l) => { return l.id === i.id })
                    if (index === -1) {
                        reports.push({
                            id: i.id,
                            label: i.title, icon: 'material-icons', gicon: 'assignment', items: [{ label: j.title, to: j.extra === null ? '/dummy_report' : j.extra }]
                        })
                    } else {
                        reports[index].items.push({ label: j.title, to: j.extra === null ? '/dummy_report' : j.extra })
                    }
                }
            })
        })
        console.log(reports)
        return reports
    }
    const getApprover = () => {
        if (config.id !== 17 && config.id !== 28 && config.id !== 169 && config.id !== 222) {
            return { label: 'Data Approver', to: '/approver', icon: 'material-icons', gicon: 'fact_check' }
        } else {
            console.log(config.id)
            return { label: 'Data Approver', to: '/quantitative_approver', icon: 'material-icons', gicon: 'fact_check' }
        }

    }
    let navigos = [

        { label: 'Home', icon: 'pi pi-fw pi-home', to: '/client_home' },
        { label: 'Performance Dashboard', icon: 'pi pi-fw pi-desktop', to: '/client_performance' },
        { label: 'Reports & Disclosure', to: '/report_list', icon: 'pi pi-book' },
        { label: 'Material Topics', to: '/client_indicators', icon: 'material-icons', gicon: 'traffic' },


        { label: 'Qualitative Responses', to: '/qualitative_rf_inputs', icon: 'material-icons', gicon: 'calculate' },
        getApprover(),
        { label: 'New Data Assignment', to: '/quantitative_assignment_user', icon: 'material-icons', gicon: 'fact_check' },
        {
            label: 'Data Assignment', icon: 'material-icons', gicon: 'settings_applications', to: '/dcf_',
            items: [

                // { label: 'Internal DCF Assignment', to: '/dcf_user_assign', icon: 'material-icons', gicon: 'rebase_edit' },
                { label: 'Supplier SRF Assignment', to: '/dcf_supplier_assign', icon: 'material-icons', gicon: 'engineering' }
            ]
        },

        { label: 'DCF Submission Status', to: '/overall_quantitative_status', icon: 'material-icons', gicon: 'calculate' },
        { label: 'Assurance', to: '/assurance', icon: 'material-icons', gicon: 'summarize' },

        { label: 'EHS Applications', to: null, icon: 'material-icons', gicon: 'warning' },
        {
            label: 'Settings', icon: 'pi pi-cog', to: '/client/',
            items: [
                { label: 'User Management', to: '/client/add_user', icon: 'pi pi-user-plus' },
                { label: 'Supplier Management', to: '/client/add_supplier', icon: 'pi pi-user-plus' },

                { label: 'Goals,Targets & Actions', to: '/client/goals', icon: 'material-icons', gicon: 'trending_up' },
                { label: 'View Site Configuration', to: '/client/location_config', icon: 'material-icons', gicon: 'edit_location_alt' },

            ]
        },
        {
            label: 'Logout', icon: 'pi pi-sign-out ', to: '/logout', exit: true
        },



    ].filter(i => i !== null)
    let materiality = [
        { label: 'Materiality Dashboard', to: '/materiality_dashboard', icon: 'pi pi-fw pi-desktop' },

    ]
    let materiality_ = [
        { label: 'Materiality Dashboard', to: '/materiality_dashboard', icon: 'pi pi-fw pi-desktop' },
        {
            label: 'Logout', icon: 'pi pi-sign-out ', to: '/logout', exit: true
        },
    ]
    const getMerge = () => {
        let arr = JSON.parse(JSON.stringify(navigos))
        arr.splice(4, 1, ...materiality)
        console.log(arr)
        return arr
    }
    const superadmin = (config.access === undefined || config.access === null) ? navigos : (config.access.navigos && config.access.materiality) ?
        getMerge() : config.access.navigos ? navigos : config.access.materiality ? materiality_ : []
    const edituser =
        [

            {
                label: 'Home', icon: 'pi pi-fw pi-desktop', to: '/client_user/homescreen'
            },


        ];
    const approver =
        [
            {
                label: '', icon: 'pi pi-fw pi-desktop', items: [{
                    label: 'Homescreen', icon: 'pi pi-fw pi-desktop', to: '/client_user/homescreen'
                }]
            },
            {
                label: 'ESG', icon: 'pi pi-fw pi-search',
                items: [
                    {
                        label: 'Environment', icon: 'material-icons', gicon: 'eco',
                        items: [
                            {
                                label: 'Climate Change', icon: 'material-icons', gicon: 'nights_stay',
                                items: [
                                    { label: 'My Dashboard', to: '/my_dashboard_2', icon: 'pi pi-fw pi-desktop' },
                                    { label: 'Approval', icon: 'pi pi-fw pi-check-circle', to: '/input_entry_approval_new' },

                                ]
                            },

                        ]
                    }

                ]
            }




        ];
    const appuser =
        [

            {
                label: 'ESG', icon: 'pi pi-fw pi-search',
                items: [
                    {
                        label: 'Environment', icon: 'material-icons', gicon: 'eco',
                        items: [
                            {
                                label: 'Climate Change', icon: 'material-icons', gicon: 'nights_stay',
                                items: [
                                    { label: 'Approver Dashboard', to: '/my_dashboard_2', icon: 'pi pi-fw pi-desktop' },
                                    { label: 'Input Entry Dashboard', icon: 'pi pi-fw pi-desktop', to: '/my_dashboard' },
                                    { label: 'Approval', icon: 'pi pi-fw pi-check-circle', to: '/input_entry_approval_new' },
                                    { label: 'Input Entry', icon: 'pi pi-fw pi-pencil', to: '/input_entry_add' },

                                ]
                            },

                        ]
                    }

                ]
            }



        ];
    const menu = [
        {
            label: 'Home',
            items: [{
                label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'
            }]
        },
        {
            label: 'ESG', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Environment', icon: 'material-icons', gicon: 'eco',
                    items: [
                        {
                            label: 'Climate Change', icon: 'material-icons', gicon: 'nights_stay',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil', to: '/input_entry' },
                                { label: 'Approval', icon: 'pi pi-fw pi-check-circle', to: '/input_entry_approval' },

                            ]
                        },
                        {
                            label: 'Energy Management', icon: 'material-icons', gicon: 'electric_bolt',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Waste Management', icon: 'material-icons', gicon: 'compost',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                    ]
                },
                {
                    label: 'Social', icon: 'material-icons', gicon: 'public',
                    items: [
                        {
                            label: 'Diversity in gender, LBGT, physically challenged',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Equal opportunity',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Non-Discrimination',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Occupational Health & Safety',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Employment',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Training and Education',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }, {
                            label: 'Labour Relations',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }, {
                            label: 'Value/ opportunities created for the community',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }
                    ]
                },
                {
                    label: 'Governance', icon: 'material-icons', gicon: 'account_balance',
                    items: [
                        {
                            label: 'Data Security',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Customer Privacy',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Whistle Blower',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Anti-Bribery and Anti-Corruption',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Human Rights practices',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        },
                        {
                            label: 'Socio-economic compliance',
                            items: [
                                { label: 'Dashboard', icon: 'pi pi-fw pi-desktop' },
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil' },

                            ]
                        }
                    ]
                }
            ]
        },
        {
            label: 'Configuration', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Emission Factor', icon: 'pi pi-fw pi-cog',
                    items: [
                        {
                            label: 'Repository', icon: 'pi pi-fw pi-inbox',
                            items: [
                                { label: 'Input Entry', icon: 'pi pi-fw pi-pencil', to: '/respo_ef_ip' },


                            ]
                        },
                    ]
                }

            ]
        },
        {
            label: 'UI Components', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
                { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
                { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
                { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
                { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
                { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
                { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
                { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
                { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
                { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
                { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
                { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
                { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
                { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
                { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
                { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
            ]
        },
        {
            label: 'UI Blocks',
            items: [
                { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "NEW" },
                { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-react' }
            ]
        },
        {
            label: 'Icons',
            items: [
                { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/icons' }
            ]
        },
        {
            label: 'Pages', icon: 'pi pi-fw pi-clone',
            items: [
                { label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
                { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
                { label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
            ]
        },
        {
            label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
            items: [
                {
                    label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {
                            label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
                            ]
                        },
                        {
                            label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
                            ]
                        },
                    ]
                },
                {
                    label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                    items: [
                        {
                            label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' },
                            ]
                        },
                        {
                            label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                            items: [
                                { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
                                { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            label: 'Get Started',
            items: [
                { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
                { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
            ]
        }
    ];
    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {localStorage.getItem('token') &&
                <>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                    <AppTopbar layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <Sidebar logout={onMenuItemClick} onToggleMenuClick={onToggleMenuClick} menuitems={Object.keys(config).length !== 0 && (config.role === 'eisqradmin' ? eisqradmin : config.role === 'clientadmin' ? superadmin : [])} />


                    {/* {(config.information.role === undefined || !config.information.role.reporter) && (!sessionStorage.getItem('temp')) && <div className="layout-sidebar" onClick={onSidebarClick}>
                        <AppMenu model={Object.keys(config).length !== 0 && (config.role === 'eisqradmin' ? eisqradmin : config.role === 'clientadmin' ? superadmin : config.information.role.reporter ? edituser : approver)} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />


                    </div>
                    } */}

                </>
            }
            <div className={localStorage.getItem('token') && "layout-main-container bg-smoke"}>
                <div className="layout-main" style={{ marginTop: 10 }}>

                    {/* <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                    {/* <Route exact path="/login" component={LoginForm} /> */}
                    <Route exact path="/" component={LoginNew} />
                    <Route path='/reset-password-finish' component={PasswordReset} />


                    {localStorage.getItem('token') &&
                        <>
                            <Route path='/client_home' component={AdminHomeScreen} />
                            <Route path="/input_cc_dashboard" component={adminCCDashboard} />

                            <Route path='/materiality_dashboard' component={MaterialityDashboard} />

                            {/* <Route path="/formlayout" component={FormLayoutDemo} /> */}
                            {/* <Route path='/input_entry' component={InputEntry} /> */}
                            <Route path='/client/add_user' component={AddClientUser} />
                            <Route path='/client/add_supplier' component={AddClientSupplier} />

                            <Route path='/climate_change_db' component={ClimateChangeDB} />
                            <Route path='/client_performance' component={DashboardCC} />

                            {/* <Route path='/my_dashboard' component={MyDashboard} /> */}
                            {/* <Route path='/my_dashboard_2' component={ApproverDashboard} /> */}

                            {/* <Route path='/input_entry_add' component={InputEntryAdd} />
                            <Route path='/input_entry_edit' component={InputEntryEdit} />
                            <Route path='/input_entry_approve' component={InputEntryApprove} /> */}

                            {/* <Route path='/client/user_config' component={ClientUserConfig} /> */}
                            {/* <Route path='/client/approver_config' component={clientApproverConfig} /> */}
                            {/* <Route path='/climate/data_input_frequency' component={ModuleIntervalAllocation} /> */}

                            {/* <Route path='/module_config' component={ModuleConfig} /> */}
                            <Route path='/config/client_location' component={clientLocationConfig} />
                            <Route path='/config/approver_indicator_assignment' component={ApproverIndicatorClientAssignment} />

                            <Route path='/client/goals' component={ClimateChangeInitivative} />
                            <Route path='/client_indicators' component={ClientIndicators} />
                            <Route path='/dcf_supplier_assign_old' component={clientDCFSupplierAssignment} />

                            <Route path='/dcf_supplier_assign' component={clientSupplierSRFAssignment} />

                            <Route path='/report_list' component={ReportListing} />

                            <Route path='/config/add_new_client' component={AddClient} />
                            <Route path='/data_points_config' component={DataPointConfig} />

                            <Route path='/config/dcf_client_assignment' component={clientDCFAssignment} />
                            <Route path='/config/dcf_cs_assignment' component={clientSupplierDCFAssignment} />


                            <Route path='/add_quantitative_dcf' component={DataCollectionForm} />
                            <Route path='/add_qualitative_dcf' component={DataCollectionFormTwo} />
                            <Route path='/form/add_cf' component={ConsolidatedForm} />

                            <Route path='/form/quantitative_dcf_list' component={DataCollectionLibrary} />
                            <Route path='/form/qualitative_dcf_list' component={DataCollectionLibraryTwo} />
                            <Route path='/form/cf_list' component={ConsolidateFormLibrary} />

                            <Route path='/form/edit_dcf' component={DataCollectionFormEdit} />
                            <Route path='/form/edit_rf' component={RFCollectionFormEdit} />

                            <Route path='/client/location_config' component={locationConfigByClient} />

                            <Route path='/dcf_user_assign' component={clientUserDCFAssignment} />

                            <Route path='/qualitative_rf_inputs' component={QualitativeRFListing} />
                            {/* DCF */}
                            <Route path='/submission_preview/:id' component={DCFInputEntrySubmissionPreview} />
                            <Route path='/dcf_preview/:id' component={DCFInputEntryPreview} />
                            {/* RF */}
                            {/* <Route path='/rf_input_entry/:id' component={RFInputEntrySubmission} /> */}
                            {/* <Route path='/rf_submission_preview/:id' component={RFInputEntrySubmissionPreview} /> */}
                            {/* <Route path='/rf_preview/:id' component={RFInputEntryPreview} /> */}
                            {/* CF */}
                            {/* <Route path='/cf_preview/:id' component={SRFInputEntryPreview} /> */}
                            {/* <Route path='/cf_submission_preview/:id' component={SRFInputEntrySubmissionPreview} /> */}
                            {/* Approver */}
                            <Route path='/approver' component={Approver} />
                            <Route path='/cf_preview_view' component={cf_preview_view} />





                            <Route path='/assurance' component={AssuranceReport} />

                            <Route path='/brsr_report' component={BRSRReport} />
                            <Route path='/brsr_core_report' component={BRSR_Core_report} />
                            <Route path='/cfp' component={CFP} />
                            <Route path='/cfp_old' component={CarbonFootPrinting} />
                            <Route path='/nasdaq_report' component={NasdaqReport} />
                            <Route path='/cdp_report' component={CDP_Report} />
                            <Route path='/sgx_report' component={SGX_Report} />
                            <Route path='/ecovadis_report' component={EcoVadis_report_} />
                            <Route path='/gri_report' component={GRI_Report} />
                            <Route path='/dummy_report' component={dummy_report} />

                            <Route path='/ef_config' component={StandardsConfig} />
                            <Route path='/ef_dd_client_config' component={EFDropDownClientConfigAdmin} />
                            <Route path='/ef_category_config' component={efCategoryConfiguration} />
                            <Route path='/ef_library' component={EFLibrary} />
                            <Route path='/report_name_config' component={reportNameConfig} />
                            <Route path='/approver_indicator_config' component={IndicatorConfiguration} />

                            <Route path='/report_table' component={tableReportDashboard} />
                            <Route path='/approved_logs' component={approvedLogs} />


                            <Route path='/respo_ef_ip' component={EFInputEntryRespo} />
                            <Route path='/e2j' component={Excel2JSON} />
                            <Route path='/data_point_transfer' component={newDataPointsTransfer} />
                            <Route path="/empty" component={EmptyPage} />
                            {/* New */}
                            <Route path='/quantitative_assignment_user' component={AssignDCFToUser} />
                            <Route path='/overall_quantitative_status' component={OverallQuantitativeSubmissions} />
                            <Route path='/data_input_status/:id/:id2' component={quantitativeOverallSubmissionPreview} />
                            <Route path='/certificate_config' component={CertificationConfig} />
                            <Route path='/role_config' component={RoleConfig} />
                            <Route path='/performance_board' component={PerformanceDashboard} />
                            <Route path='/leadership_board' component={LeadershipDashboard} />
                            <Route path='/initiatives' component={Initiative} />
                            <Route path='/quantitative_approver' component={QuantitativeApproverDB} />
                            {/* <Route path='/dropdown_config' component={DropdownConfig} /> */}
                            <Route path='/air_iata_config' component={AirPortCodeConfig} />
                        </>
                    }



                    {/* {localStorage.getItem('token') !== null && <AppFooter layoutColorMode={layoutColorMode} />} */}
                </div>
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;


// "repository": {
//     "type": "git",
//     "url": "https://github.com/primefaces/sakai-react.git"
// },