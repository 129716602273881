import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const data = [
    { 
        labor: 'Freedom of Association',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Freely Chosen Employment',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Humane treatment',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Nondiscrimination',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Sub contractor and next-tier supplier responsibility',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Wages and benefits',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Worker grievance/ complaint mechanism',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Working hours',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
    { 
        labor: 'Young workers',
        '2019': 100,
        '2019': 200,
        '2020': 150,
        '2021': 180,
        '2022': 300,
    },
];

const LaborDataTable = () => {
    const tableStyle = { 
        borderRadius: '10px',
        overflow: 'hidden',
        border: '1px solid #EBEDEF', // Set border color to grey
        fontSize: '16px',
        fontWeight: '400'
    };

    return (
        <DataTable value={data} style={tableStyle}>
            <Column field="labor" header="Labor"/>
            <Column field="2019" header="2019" />
            <Column field="2019" header="2019" />
            <Column field="2020" header="2020" />
            <Column field="2021" header="2021" />
            <Column field="2022" header="2022" />
        </DataTable>
    );
}

export default LaborDataTable;
