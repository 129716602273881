import React, { useEffect, useState } from 'react';

import { Card } from 'primereact/card';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

import EmissionChart from './EmissionChart';
import EmissionTable from './EmissionTable';
import BarOne from './BarOne';
import BarTwo from './BarTwo';
import MultiLine from './MultiLine';
import BarThree from './BarThree';
import BarFour from './BarFour';
import WaterChart from './WaterChart';
import WaterConsumption from './WaterConsumption';
import WaterWithdrawal from './WaterWithdrawal';
import ScopePie from './ScopePie';
import BarFive from './BarFive';


function Graph() {

    const emissionsData = [
        { month: 'Jan', emission: 32000 },
        { month: 'Feb', emission: 16000 },
        { month: 'Mar', emission: 32000 },
        { month: 'Apr', emission: 10000 },
        { month: 'May', emission: 42000 },
        { month: 'Jun', emission: 2000 },
        // Add more months as needed
    ];



    const styles = {
        cardContainer: {
            width: '400px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px'
        },
        textContent: {
            flex: 1
        },
        emissionsValue: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '5px'
        },
        targetText: {
            color: 'black',
            fontWeight: 'normal'
        },
        greenText: {
            color: 'green',
            fontWeight: 'bold'
        },
        redText: {
            color: 'red',
            fontWeight: 'bold'
        },
        chartContainer: {
            width: 150
        }
    };
    return (
        <div className='font-lato'>
            <div className='row'>
                <div className='col'>
                    <Card title="YTD Direct Emissions (Scope 1 + Scope 2)" >

                        <div className='d-flex justify-content-between'>
                            <div>
                                <div style={{ marginTop: '10px', fontSize: '26px', fontWeight: 'bold' }}>
                                    232342.23 t CO<sub>2</sub>e
                                </div>
                                <div style={styles.targetText}>
                                    Target: <strong>232400</strong> <span style={styles.greenText}>↑ 40%</span>
                                </div>
                            </div>


                            <LineChart width={180} height={50} data={emissionsData}>
                                <XAxis dataKey="name" hide />
                                <YAxis hide />
                                <Tooltip />
                                <Line type="monotone" dataKey="emission" stroke="green" strokeWidth={2} />
                            </LineChart>
                        </div>

                    </Card>
                </div>
                <div className='col'>
                    <Card title="YTD Direct Emissions (Scope 3)" >

                        <div className='d-flex justify-content-between'>
                            <div>
                                <div style={{ marginTop: '10px', fontSize: '26px', fontWeight: 'bold' }}>
                                    342.233 t CO<sub>2</sub>e
                                </div>
                                <div style={styles.targetText}>
                                    Target: <strong>232400</strong> <span style={styles.redText}>↑ 40%</span>
                                </div>
                            </div>


                            <LineChart width={180} height={50} data={emissionsData}>
                                <XAxis dataKey="name" hide />
                                <YAxis hide />
                                <Tooltip />
                                <Line type="monotone" dataKey="emission" stroke="red" strokeWidth={2} />
                            </LineChart>
                        </div>

                    </Card>
                </div>
                <div className='col'>
                    <Card title="YTD Direct Emissions (Scope 1 + Scope 2)" >

                        <div className='d-flex justify-content-between'>
                            <div>
                                <div style={{ marginTop: '10px', fontSize: '26px', fontWeight: 'bold' }}>
                                    232342.23 t CO<sub>2</sub>e
                                </div>
                                <div style={styles.targetText}>
                                    Target: <strong>232400</strong> <span style={styles.greenText}>↑ 40%</span>
                                </div>
                            </div>


                            <LineChart width={180} height={50} data={emissionsData}>
                                <XAxis dataKey="name" hide />
                                <YAxis hide />
                                <Tooltip />
                                <Line type="monotone" dataKey="emission" stroke="green" strokeWidth={2} />
                            </LineChart>
                        </div>

                    </Card>
                </div>
            </div>

            <div className='row'>
                <div className='col-4'>
                    <EmissionChart />
                </div>
                <div className='col-8'>
                    <EmissionTable />
                </div>
            </div>

            <div className='row'>
                <div className='col-6'>
                    <BarOne />
                </div>
                <div className='col-6'>
                    <BarTwo />
                </div>

            </div>

            <div className='row'>
                <div className='col-12'>
                    <MultiLine />
                </div>


            </div>
            <div className='row'>
                <div className='col-6'>
                    <BarThree />
                </div>
                <div className='col-6'>
                    <BarFour />
                </div>

            </div>
            <div className='row'>
                <div className='col-4'>
                    <ScopePie />
                </div>
                <div className='col-8'>
                    <BarFive />
                </div>
              


            </div>
            <div className='row'>
                <div className='col-4'>
                    <WaterChart />
                </div>
                <div className='col-4'>
                    <WaterConsumption />
                </div>
                <div className='col-4'>
                    <WaterWithdrawal />
                </div>


            </div>
        </div>
    )

}

export default Graph;
