import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { RadialBarChart, RadialBar, Legend, Tooltip, ResponsiveContainer } from 'recharts';

const EmissionChart = () => {
  const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    // Add more months...
  ];
  const years = [
    { label: '2024', value: 2024 },
    { label: '2023', value: 2023 },
    // Add more years...
  ];

  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const [selectedYear, setSelectedYear] = useState(years[0]);

  const data = [
    { name: 'Renewable', value: 220.8, fill: '#8884d8' },
    { name: 'Business travel', value: 81.9, fill: '#83a6ed' },
    { name: 'Non-renewable', value: 156, fill: '#ffc658' },
    { name: 'Purchased energy', value: 115, fill: '#ff8042' }
  ];

  const renderCustomLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ listStyleType: 'none', margin: 0, padding: 0, textAlign: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color, display: 'flex', alignItems: 'center', marginRight: 20 }}>
            <span style={{ height: 10, width: 10, backgroundColor: entry.color, borderRadius: '50%', display: 'inline-block', marginRight: 5 }}></span>
            {entry.value}: <strong style={{ marginLeft: 5 }}>{data[index].value}</strong>
          </li>
        ))}
      </ul>
    );
  };




  return (
    <Card>
      <p className='card-title'>Carbon Emission Tracking Service</p>
      <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>
      <Dropdown value={selectedMonth} className="me-3" options={months} onChange={(e) => setSelectedMonth(e.value)} placeholder="Select a Month" />
      <Dropdown value={selectedYear} options={years} onChange={(e) => setSelectedYear(e.value)} placeholder="Select a Year" />

      <ResponsiveContainer width="100%" height={300}>
        <RadialBarChart
          width={730}
          height={250}
          innerRadius="20%"
          outerRadius="90%"
          data={data}
          startAngle={360}
          endAngle={0}>
          <RadialBar
            minAngle={15}
            clockWise
            dataKey="value"
            cornerRadius={10} // This adds the rounded corners
          />
          <Legend
            content={renderCustomLegend} // Custom legend rendering
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
          />
          <Tooltip />
        </RadialBarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default EmissionChart;
