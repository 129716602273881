import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditText } from "react-edit-text";
import EdiText from 'react-editext'
import { AutoComplete } from "primereact/autocomplete";
import Axios from "axios";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from 'primereact/multiselect';
import Swal from "sweetalert2";
import { Chips } from 'primereact/chips';
import useForceUpdate from "use-force-update";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { Dropdown } from "primereact/dropdown";
import { API } from "../../constants/api_url";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import APIServices from "../../service/APIService";
const ReportNameConfig = () => {
    const [module, setModule] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });
    const tier4ref = useRef([]);
    const tier3ref = useRef([]);
    const tier2ref = useRef([]);
    const tier1ref = useRef([]);
    const [datasourcelist, setDataSourceList] = useState([])
    const [datasourcelist_, setDataSourceList_] = useState([])

    const [ReportNameConfigdialog, setReportNameConfigDialog] = useState(false)
    const [metricconfigdialog, setMetricConfigDialog] = useState(false)
    const [dcfitems, setDCFItems] = useState([])
    const [selected, setSelected] = useState({
        tier1: "",
        tier2: "",
        tier3: "",
        tier0: "",
        tier4: ""
    });
    const [metricList, setMetricList] = useState([])
    const [metricListBK, setMetricListBK] = useState([])

    const [submitted, setSubmitted] = useState(false);
    const [config, setConfig] = useState({ unit: '', desc: '', tags: [], suffix: 'DP', datasource: null, type: { name: 'Quantitative' } })
    const [metricconfig, setMetricConfig] = useState({ tags: [], desc: '', type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1 })

    const selector = useSelector((state) => state.user.userdetail);
    const [tier1, setTier1] = useState([]);
    const [tier2, setTier2] = useState([]);
    const [tier3, setTier3] = useState([]);
    const [tier4, setTier4] = useState([]);
    const [selectedDataPoint, setSelectedDataPoint] = useState('')
    const [metric, setSelectedMetric] = useState('')

    const [tier0, setTier0] = useState([])
    const forceUpdate = useForceUpdate();
    useEffect(() => {
        APIServices.get(API.DCF).then((a) => {
            setDataSourceList_(a.data)
            a.data.forEach((a) => {
                a.name = a.suffix + ' ' + a.title
            })
            console.log(a.data)
            setDataSourceList(a.data);
            forceUpdate()

        })
        APIServices.get(API.Report_Name_Ones).then((res) => {
            setTier1(res.data);
        });
        APIServices.get(API.Metric).then((res) => {
            setMetricList(res.data);
            setMetricListBK(res.data)
        });

    }, []);
  
    const setEditModeTier4 = (cyindex) => {
        tier4.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier4 = (cyindex) => {
        tier4.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier3 = (cyindex) => {
        tier3.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier3 = (cyindex) => {
        tier3.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier2 = (cyindex) => {
        tier2.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })

        forceUpdate()
    }
    const setEditModeOffTier2 = (cyindex) => {
        tier2.map((i, j) => { i.edit = false })

        forceUpdate()
    }
    const setEditModeTier1 = (cyindex) => {
        tier1.map((i, j) => { if (j === cyindex) { i.edit = true } else { i.edit = false } })
        console.log(tier4)
        forceUpdate()
    }
    const setEditModeOffTier1 = (cyindex) => {
        tier1.map((i, j) => { i.edit = false })

        forceUpdate()
    }

    const deleteTier2 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier2.findIndex((i) => { return i.id === item.id })
                APIServices.delete( API.Report_Name_Twos_Edit(item.id)
                ).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);
                    setTier4([])
                    let loc = tier2
                    loc.splice(index, 1);

                    setTier2(loc);
                    forceUpdate();

                });
            }
        })

    }
    const deleteTier1 = (item) => {
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                let index = tier1.findIndex((i) => { return i.id === item.id })
                APIServices.delete( API.Report_Name_Ones_Edit(item.id)).then((res) => {
                    let sel = selected;
                    sel.tier3 = ''
                    sel.tier1 = ''
                    sel.tier2 = ''

                    sel.tier4 = ''
                    setSelected(sel);

                    let loc = tier1
                    loc.splice(index, 1);

                    setTier1(loc);
                    forceUpdate();

                });
            }
        })

    }

  

    const setDataSource = () => {

    }
    const openConfig = (items) => {
        // setDataSource()
        let item = JSON.parse(items)
        setSubmitted(false)
        console.log(item)
        setSelectedDataPoint(item);
        if (item.data1 === null) {
            setConfig({ unit: '', desc: '', suffix: 'DP', tags: [], datasource: null, type: { name: 'Quantitative' } });
            setReportNameConfigDialog(true);
        } else {
            if (item.data1[0]['suffix'] === undefined) { item.data1[0]['suffix'] = 'DP' }

            if ((item.data1[0].datasource !== null && item.data1[0].datasource !== undefined) || !Array.isArray(item.data1[0].datasource)) {

                item.data1[0].datasource = datasourcelist[datasourcelist.findIndex((k) => { return k.id === item.data1[0].datasource })]
            }

            setConfig(item.data1[0]);
            setReportNameConfigDialog(true);
        }
    }
    const openMetricConfig = (items) => {
        let item = JSON.parse(items)
        setSubmitted(false)
        setSelectedMetric(item);
        console.log(item, metricListBK)
        setMetricList(metricListBK.filter((i) => { return i.id !== item.id }))
        if (item.data1 === null) {

            setMetricConfig({ tags: [], desc: '', type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1 });
            setMetricConfigDialog(true);
        } else {
            if (Object.keys(item.data1[0]).length === 2) {
                setMetricConfig({ ...item.data1[0], type: 0, indicator: [], dcf: [], tags1: [], tags2: [], tags3: [], tags4: [], source: 1 });

            } else {
                setMetricConfig(item.data1[0]);

            }
            setMetricConfigDialog(true);
        }
    }
    const updateConfig = (obj, val) => {
        let loc = config;

        if (obj === 'type') {
            loc.unit = ''
        }
        loc[obj] = val;


        setConfig(loc);
        forceUpdate()

    }
    const updateMetricConfig = (obj, val) => {
        let loc = metricconfig;


        loc[obj] = val;


        setMetricConfig(loc);
        forceUpdate()

    }

    const addNewTier1 = () => {

        let index = tier1.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier1.trim().length !== 0) {
            APIServices.post( API.Report_Name_Ones,
                 {
                    title: module.tier1.trim(), suffix: 'RNO'
                },
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        let tier = tier1;
                        tier.push(res.data);
                        setTier1(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",
                            tier0: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };
    const addNewTier2 = () => {

        let index = tier2.findIndex((i) => { return i.title.trim().toLowerCase() === module.tier1.trim().toLowerCase() })

        if (index === -1 && module.tier2.trim().length !== 0) {
            APIServices.post( API.Report_Name_Twos_RNO(selected.tier1),
             {
                    title: module.tier2.trim(), suffix: 'FMW'
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        let tier = tier2;
                        tier.push(res.data);
                        setTier2(tier);
                        setModule({
                            tier1: "",
                            tier2: "",
                            tier3: "",

                            tier4: ''
                        });
                    }
                })
                .catch((e) => {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: `Something went wrong, try again later `,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        }
    };

    const updateTier1 = (e, id) => {
        console.log(e)
        let index = tier1.findIndex((i) => {
            return i.id === id;
        });
        if (index !== -1) {
            APIServices.patch( API.Report_Name_Ones_Edit(tier1[index].id),
                 {
                    title: e.trim(),
                },
            ).then((res) => {
                let loc = tier1
                loc[index].title = e.trim();
                setTier1(loc);
                forceUpdate()
            });
        }
    };


    const getTier2Data = (id) => {
        let sel = selected;
        sel.tier1 = id;
        sel.tier2 = "";
        sel.tier3 = "";
        sel.tier4 = ''
        setSelected(sel);

        APIServices.get( API.Report_Name_Twos_RNO(id),
        ).then((res) => {
            setTier2(res.data);
        });
    };
  
    const getTier5Data = (id) => {
        let sel = selected;
        sel.tier4 = id;
        setSelected(sel);
        forceUpdate();
    };
    const updateTier2 = (e, id) => {
        let index = tier2.findIndex((i) => {
            return i.id === id;
        });

        APIServices.patch( API.Report_Name_Twos_Edit(tier2[index].id),
             {
                title: e.trim(),
            },
        ).then((res) => {
            let loc = tier2
            loc[index].title = e.trim();
            setTier2(loc);
            forceUpdate()

        });
    };
   
    const showTier4CM = (e, ind) => {
        console.log(tier4ref.current)
        tier4ref.current.forEach((i, j) => {
            if (j === ind) {
                tier4ref.current[ind].show(e)
            } else {
                if (tier4ref.current[j] !== null) {
                    tier4ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
    }
    const showTier3CM = (e, ind) => {

        tier3ref.current.forEach((i, j) => {
            if (j === ind) {
                tier3ref.current[ind].show(e)
            } else {
                if (tier3ref.current[j] !== null) {
                    tier3ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier2ref.current.forEach((i, j) => {

            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier2CM = (e, ind) => {

        tier2ref.current.forEach((i, j) => {
            if (j === ind) {
                tier2ref.current[ind].show(e)
            } else {
                if (tier2ref.current[j] !== null) {
                    tier2ref.current[j].hide(e)
                }
            }
        })
        tier1ref.current.forEach((i, j) => {

            if (tier1ref.current[j] !== null) {
                tier1ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)

            }
        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })
    }
    const showTier1CM = (e, ind) => {

        tier1ref.current.forEach((i, j) => {
            if (j === ind) {
                tier1ref.current[ind].show(e)
            } else {
                if (tier1ref.current[j] !== null) {
                    tier1ref.current[j].hide(e)
                }
            }
        })
        tier2ref.current.forEach((i, j) => {
            console.log(tier2ref.current[j])
            if (tier2ref.current[j] !== null) {
                tier2ref.current[j].hide(e)

            }
        })
        tier3ref.current.forEach((i, j) => {

            if (tier3ref.current[j] !== null) {
                tier3ref.current[j].hide(e)
            }

        })
        tier4ref.current.forEach((i, j) => {

            if (tier4ref.current[j] !== null) {
                tier4ref.current[j].hide(e)

            }
        })

    }
  
    const updateTierValue = (obj, e) => {

        let mod = module;
        mod[obj] = e.target.value;
        setModule(mod);
        forceUpdate();
    };
    const search = (event) => {

        let _items = datasourcelist.filter((k) => { return k.name.trim().toLowerCase().includes(event.query.trim().toLowerCase()) });
        setDCFItems(_items);
        forceUpdate()
    }
    const items_ = [
        {
            label: 'Indicator Listing',

            command: () => {
                renderData(0)

            }
        },
        {
            label: 'Indicator > DP',

            command: () => {
                renderData(1)
            }
        },
        {
            label: 'DP > DCF ',

            command: () => {
                renderData(2)
            }
        }
    ];

    const renderData = (type) => {

        let uriString = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]

        }


        let site_url = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`;
        APIServices.get(site_url).then((res) => {
            let metrics = [], dps = []
            res.data.forEach((item) => {
                if (item.newTopics !== undefined) {
                    item.newTopics.forEach((item2) => {
                        if (item2.newMetrics !== undefined) {

                            metrics.push(...item2.newMetrics)
                            item2.newMetrics.forEach((item3) => {
                                if (item3.newDataPoints !== undefined) {
                                    dps.push(...item3.newDataPoints)
                                }
                            })
                        }
                    })
                }
            })
            export2Excel(type, metrics, dps)
        })

    }
    const groupArrayObject = (array) => {
        return array.reduce((group, arr) => {

            const { DCF } = arr;

            group[DCF] = group[DCF] ?? [];

            group[DCF].push(arr);

            return group;

        },

            {})
    }
    const export2Excel = (type, metrics, dps) => {
        let loc = [], loc2 = [];
        let obj = {}

        if (type === 0) {

            metrics.forEach((i) => {


                if (i.data1 !== null && i.data1.length !== 0) {
                    console.log(i.data1)
                    if (i.data1[0].tags.length !== 0) {
                        let tags_ = ''
                        i.data1[0].tags.forEach((j, ind) => {
                            console.log(j.substr(0, 4), j)
                            loc.push({ Indicator: i.title, tags: j, standards: j.substr(0, 3).toLowerCase().includes('gri') ? "GRI" : j.substr(0, 4).toLowerCase().includes('brsr') ? 'BRSR' : j.substr(0, 7).toLowerCase().includes('ecovadis') ? 'ECOVADIS' : j.substr(0, 4).toLowerCase().includes('msci') ? 'MSCI' : 'Others' })

                        })

                    } else {
                        loc.push({ Indicator: i.title, tags: 'Not Found' })

                    }
                } else {
                    loc.push({ Indicator: i.title, tags: 'Not Found' })
                }

            })

        } else if (type === 1) {

            metrics.forEach((i) => {


                if (i.newDataPoints !== undefined) {
                    i.newDataPoints.forEach((j, ind) => {



                        loc.push({ Indicator: i.title, DP_ID: j.suffix === null ? 'Not Assigned' : j.suffix.substr(0, 2).includes('DP') ? j.suffix.length === 2 ? 'Invalid DP' : j.suffix : 'Not Assigned', DP: j.title })


                    })
                } else {
                    loc.push({ Indicator: i.title, DP_ID: 'Not Assigned', DP: 'Not Found' })
                }

            })

        } else if (type === 2) {
            dps.forEach((item) => {
                if (item.data1 !== null) {
                    if (typeof item.data1[0].datasource === 'number') {
                        if (datasourcelist_.filter((i) => { return i.id === item.data1[0].datasource }).length === 0) {
                            loc2.push({ DP: item.title, DCF: 'Deleted DCF ID' })
                        } else {
                            let dcf = datasourcelist_.filter((i) => { return i.id === item.data1[0].datasource })[0]
                            loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, DCF: dcf.suffix + ' : ' + dcf.title })
                        }


                    } else {
                        loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, DCF: item.data1[0].datasource === null ? 'Not Found' : 'Invalid DCF' })
                    }
                } else {
                    loc2.push({ DP_ID: item.suffix === null ? 'Not Assigned' : item.suffix.substr(0, 2).includes('DP') ? item.suffix.length === 2 ? 'Invalid DP' : item.suffix : 'Not Assigned', DP: item.title, DCF: 'Not Found' })

                }
            })

            Object.values(groupArrayObject(loc2)).forEach((item) => {

                item.forEach((item2, ind) => {

                    loc.push({ DP_ID: item2.DP_ID, DP: item2.DP, DCF: item2.DCF })



                })
            })
        }



        const ws = XLSX.utils.json_to_sheet(loc)
        let sheet_name = type === 0 ? 'Indicator_Listing' : type === 1 ? 'Indicator_DP_Listing' : 'DP_DCF_Listing'
        const wb = {
            Sheets: { [sheet_name]: ws },
            SheetNames: [sheet_name],
        };

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

        const data = new Blob([excelBuffer], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, sheet_name + ".xlsx");
    }
    return (
        <div className="grid">

            {selector.role === "eisqradmin" ? (
                <div className="col-12">
                    <div className="card">
                        <h3> Framework Configuration </h3>
                        <div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                {/* <SplitButton label="Export" icon="pi pi-print" model={items_} /> */}
                            </div>
                            <div className="field">
                                <div>
                                    <div className="col-12">
                                        <div className="grid">

                                            <div className="col-6">
                                                <div className="grid">
                                                    <div className="col-10">
                                                        <InputText
                                                            type={"text"}
                                                            style={{ width: '100%' }}
                                                            value={module.tier1}
                                                            onChange={(e) => {
                                                                updateTierValue("tier1", e);
                                                            }}
                                                            placeholder="Enter Category"
                                                        />
                                                    </div>
                                                    <div className="col-2" style={{ marginLeft: -10 }}>
                                                        <Button
                                                            icon="pi pi-plus"

                                                            style={{ cursor: module.tier1.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier1.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier1.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                            className="p-button-success mr-2"
                                                            onClick={() => {
                                                                addNewTier1();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-12"
                                                    style={{
                                                        border: "1px solid grey",
                                                        height: "46vh",
                                                        overflow: "auto",
                                                        borderRadius: 5
                                                    }}
                                                >
                                                    {tier1.length === 0 ? (
                                                        <text
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >

                                                            {"Add Category"}
                                                        </text>
                                                    ) : (
                                                        tier1.map((item, cyindex) => {
                                                            return (
                                                                <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                                    <div className="col-11"
                                                                        onClick={() => {
                                                                            getTier2Data(item.id);
                                                                        }}
                                                                        style={{
                                                                            color: selected.tier1 === item.id && "white",
                                                                            background: selected.tier1 === item.id && "grey",
                                                                            borderBottom: "0.5px ridge grey", padding: 0
                                                                        }}
                                                                    >
                                                                        <EdiText

                                                                            viewContainerClassName={selected.tier1 === item.id ? 'my-custom-view-wrapper-selected' : 'my-custom-view-wrapper'}

                                                                            type='text'
                                                                            value={item.title}
                                                                            onSave={(e) => { updateTier1(e, item.id); setEditModeOffTier1() }}
                                                                            editing={item.edit}
                                                                            onCancel={() => { setEditModeOffTier1() }}
                                                                            hideIcons={true}
                                                                            validationMessage="Title should not exist / not empty "
                                                                            validation={val => { console.log(val); return val.trim().length !== 0 && tier1.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                            saveButtonContent="✔"
                                                                            cancelOnUnfocus
                                                                            cancelButtonContent={<strong>X</strong>}

                                                                            editButtonClassName="custom-edit-button"
                                                                        />
                                                                        {/* <EditText
                                                                            onSave={(e) => {
                                                                                if (e.value.trim().length !== 0) {
                                                                                    updateTier1(e);
                                                                                }
                                                                            }}
                                                                            name="textbox3"
                                                                            // value={item.country}
                                                                            defaultValue={item.title}
                                                                            editButtonProps={{
                                                                                style: {
                                                                                    marginLeft: "5px",
                                                                                    width: 16,
                                                                                },
                                                                            }}
                                                                            showEditButton
                                                                        /> */}
                                                                    </div>
                                                                    <ContextMenu model={[
                                                                        {
                                                                            label: 'Edit',
                                                                            icon: 'pi pi-fw pi-pencil',
                                                                            command: () => { setEditModeTier1(cyindex) }
                                                                        },
                                                                        {
                                                                            label: 'Delete',
                                                                            icon: 'pi pi-fw pi-trash', command: () => { deleteTier1(item) }
                                                                        }]} ref={(r) => { tier1ref.current[parseInt(`1${item.id}`)] = r }}></ContextMenu>
                                                                    <div className="col-1" onContextMenu={(e) => { showTier1CM(e, parseInt(`1${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                        <i className="material-icons" >settings</i>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            {selected.tier1 !== "" && (
                                                <div className="col-6">
                                                    <div className="grid">
                                                        <div className="col-10">
                                                            <InputText
                                                                type={"text"}
                                                                style={{ width: '100%' }}
                                                                value={module.tier2}
                                                                onChange={(e) => {
                                                                    updateTierValue("tier2", e);
                                                                }}
                                                                placeholder="Enter Framework"
                                                            />
                                                        </div>
                                                        <div className="col-2" style={{ marginLeft: -10 }}>
                                                            <Button
                                                                icon="pi pi-plus"
                                                                className="p-button-success mr-2"
                                                                style={{ cursor: module.tier2.trim().length === 0 ? 'not-allowed' : 'pointer', background: module.tier2.trim().length === 0 ? 'gray' : '#22C55E', border: module.tier2.trim().length === 0 ? '1px solid gray' : '1px solid #22C55E' }}
                                                                onClick={() => {
                                                                    addNewTier2();
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-12"
                                                        style={{
                                                            border: "1px solid grey",
                                                            height: "46vh",
                                                            overflow: "auto",
                                                            borderRadius: 5
                                                        }}
                                                    >
                                                        {tier2.length === 0 ? (
                                                            <text
                                                                style={{
                                                                    padding: 5,
                                                                }}
                                                            >

                                                                {"Add Framework"}
                                                            </text>
                                                        ) : (
                                                            tier2.map((item, cyindex) => {
                                                                return (
                                                                    <>
                                                                        <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>

                                                                            <div className={"col-11 frame" + item.id}

                                                                                style={{

                                                                                    borderBottom: "0.5px ridge grey", padding: 0
                                                                                }}
                                                                            >
                                                                                <EdiText

                                                                                    viewContainerClassName={'my-custom-view-wrapper'}

                                                                                    type='text'
                                                                                    value={item.title}
                                                                                    onSave={(e) => { updateTier2(e, item.id); setEditModeOffTier2() }}
                                                                                    editing={item.edit}
                                                                                    onCancel={() => { setEditModeOffTier2() }}
                                                                                    hideIcons={true}
                                                                                    validationMessage="Title should not exist / not empty "
                                                                                    validation={val => { console.log(val); return val.trim().length !== 0 && tier2.findIndex((k) => { return k.title.trim() === val.trim() }) === -1 }}
                                                                                    saveButtonContent="✔"
                                                                                    cancelOnUnfocus
                                                                                    cancelButtonContent={<strong>X</strong>}

                                                                                    editButtonClassName="custom-edit-button"
                                                                                />
                                                                                {/* <EditText
                                                                                    onSave={(e) => {
                                                                                        if (e.value.trim().length !== 0) {
                                                                                            updateTier2(e);
                                                                                        }
                                                                                    }}
                                                                                    name="textbox3"
                                                                                    // value={item.country}
                                                                                    defaultValue={item.title}
                                                                                    editButtonProps={{
                                                                                        style: {
                                                                                            marginLeft: "5px",
                                                                                            width: 16,
                                                                                        },
                                                                                    }}
                                                                                    showEditButton
                                                                                /> */}
                                                                            </div>
                                                                            <ContextMenu model={[
                                                                                {
                                                                                    label: 'Edit',
                                                                                    icon: 'pi pi-fw pi-pencil',
                                                                                    command: () => { setEditModeTier2(cyindex) }
                                                                                },
                                                                                {
                                                                                    label: 'Delete',
                                                                                    icon: 'pi pi-fw pi-trash', command: () => { deleteTier2(item) }
                                                                                }]} ref={(r) => { tier2ref.current[parseInt(`2${item.id}`)] = r }}></ContextMenu>
                                                                            <div className="col-1" onContextMenu={(e) => { showTier2CM(e, parseInt(`2${item.id}`)) }} style={{ padding: 0, cursor: 'pointer' }} >

                                                                                <i className="material-icons" >settings</i>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-12 card">You have no rights to access this page </div>
            )}
       
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ReportNameConfig, comparisonFn);
