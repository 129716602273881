import React from "react";
import Header from "./header";
import '../Styles/dashboard.scss';
import '../Styles/ui_ux.css';
import TimelineCard from './timelineCard';
import { Card } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { useSelector } from "react-redux";


const MaterialityDashboard = () => {
  const selector = useSelector(state => state.user.userdetail)
  return (
    //header component
    <div className="font-lato">
      <Header name={selector.information.contactperson} email={selector.email}/>


      <div className='container'>
        <div className='timeline-flex'>
          <div className='timeline-card'>
            <TimelineCard subHeader='TYPE' header='Double Materiality' content='Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor' />

          </div>
          <div className='timeline-card'>
            <TimelineCard subHeader='DATE OF INITIATION' header='12-Dec-2023' content='Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor' />

          </div>
          <div className='timeline-card'>
            <TimelineCard subHeader='CLOSING DATE' header='31-Dec-2023' content='Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor' />

          </div>


        </div>
      </div>


      <div className='container'>
        <p style={{ fontWeight: '500', fontSize: '16px' }}>Materials topics for stakeholder engagements</p>
        <div className="stakeholder-wrapper">
          <div className='card'>
            <div className='grid'>
              <div className='col-4 stakeholder-card'>
                <div className='stakeholder-card-header-one'>
                  ENVIRONMENT
                </div>
                {/* can make these static content as constant array of objects to loop over it to display */}
                <div className='stakeholder-card-body'>
                  <div className='stakeholder-head'>Energy</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Water and Effluent</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Emissions</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                </div>
              </div>
              <div className='col-4 stakeholder-card'>
                <div className='stakeholder-card-header-two'>
                  SOCIAL
                </div>
                <div className='stakeholder-card-body'>
                  <div className='stakeholder-head'>Waste</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Occupational Health and Safety</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Diversity and Equal Oppurtunity</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Training and Education</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Local Communities</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                </div>
              </div>
              <div className='col-4'>
                <div className='stakeholder-card-header-three'>
                  GOVERNANCE
                </div>
                <div className='stakeholder-card-body'>
                  <div className='stakeholder-head'>Economic Performance</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Customer Privacy</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Responsible Governance Practices</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Employment</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                  <div className='stakeholder-head'>Procurement Practices</div>
                  <div className='stakeholder-content'>Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='container response-container'>
        <div style={{ marginBottom: '1%', fontWeight: '600' }}>Response status</div>
        <div className='card'>
          <div style={{ marginBottom: '3%', fontSize: '16px' }} className='content-width'>Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd Lorem ipsum dadasdajbdjkdjkasdbsabdasjkdasnc,sdhdhkasnd</div>
          <Table bordered={false} hover={true} className='response-table'>
            <tbody>
              <tr>
                <td>Government & Regulators</td>
                <td>52</td>
              </tr>
              <tr>
                <td>Suppliers & Service Providers</td>
                <td>182</td>
              </tr>
              <tr>
                <td>Government & Regulators</td>
                <td>86</td>
              </tr>
              <tr>
                <td>Customers</td>
                <td>44</td>
              </tr>
              <tr>
                <td>Channel Partner</td>
                <td>39</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>


    </div>


  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MaterialityDashboard, comparisonFn);
