import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton'
import { useDropzone } from 'react-dropzone';
import { Badge } from 'primereact/badge';
import { Message } from 'primereact/message';
import moment from "moment";
import useForceUpdate from "use-force-update";
import { DateTime } from 'luxon';
import Axios from 'axios';
import APIServices from '../../service/APIService';
import { API } from '../../constants/api_url';
import { useSelector } from 'react-redux';
import { name } from 'xlsx-populate/lib/RichTextFragment';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import Swal from 'sweetalert2';
import { Tooltip } from 'primereact/tooltip';

const getID = () => {
    return parseInt(Date.now() * Math.random()) + moment().unix() + randomIntFromInterval(1, 1000)

}
const randomIntFromInterval = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}
const Initiative = ({ }) => {

    // const [baDialog, setBADialog] = useState(false)
    // const [badata, setBAData] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState([])
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const selector = useSelector(state => state.user.userdetail)
    const [userlist, setUserList] = useState([])
    const [topiclist, setTopicList] = useState([])
    const [topiclistbk, setTopicListBk] = useState([])
    const [goallist, setGoalList] = useState([])
    const [categorylist, setCategoryList] = useState([])
    const [categoryunitlist, setCategoryUnitList] = useState([])
    const [categoryunitbklist, setCategoryUnitBkList] = useState([])
    const currencyList = [{ name: 'INR', value: 1 }, { name: 'SGD', value: 2 }, { name: 'AUD', value: 3 }, { name: 'GBP', value: 4 }, { name: 'USD', value: 5 }, { name: 'AED', value: 6 }, { name: 'KWD', value: 7 }]
    const forceUpdate = useForceUpdate()
    const [locList, setLocList] = useState({ country: [], city: [], location: [] })
    const [rawsitelist, setRawSitelist] = useState([]);
    const [initiative, setInitiative] = useState({
        title: '',
        category: null,
        description: '',
        startDate: null,
        completionDate: null,
        targetReduction: 0,
        measurementUnit: '',
        baselineData: 0,
        responsibility: [],
        applicability: { country: 0, city: 0, location: 0 },
        investment: 0,
        expectedAchievement: '',
        monitorMethod: '',
        comments: '',
        attachment: [],
        currencyUnit: null,
        currentStatus: null,
        goal: null,
        materialTopic: null
    });


    useEffect(async () => {
        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        const promise0 = APIServices.get(API.UserProfile)
        const promise1 = APIServices.get(API.Topic)
        const promise2 = APIServices.get(API.AssignDCFClient_UP(selector.id))
        const promise3 = APIServices.get(API.Goal_UP(selector.id))
        const promise4 = APIServices.get(API.ClientIniative_UP(selector.id))
        const promise5 = APIServices.get(API.Categories)
        const promise6 = APIServices.get(API.Unit_InitCategory)
        const promise7 = APIServices.get(
            API.LocationOne_UP(selector.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        Promise.all([
            promise0,
            promise1,
            promise2,
            promise3, promise4,
            promise5,
            promise6, promise7

        ]).then((values) => {
            let user_ = [], topic_ = [], goals = []
            values[0].data.forEach((usr) => {
                if (usr.role === 'clientuser' && usr.clientId === selector.id) {
                    user_.push({ name: usr.information.empname, id: usr.id })
                }

            })
            setUserList(user_)
            let topic_ids = values[2].data.length ? values[2].data[0].topic_ids : []
            values[1].data.forEach((tp) => {
                if ((tp.tag === null || selector.id === parseFloat(tp.tag)) && topic_ids.includes(tp.id)) {
                    topic_.push(tp)
                }
            })
            const shapedSite = values[7].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setRawSitelist(shapedSite)
            setRecord(values[4].data)
            setCategoryUnitBkList(values[6].data)
            setCategoryList(values[5].data)
            setGoalList(values[3].data)
            setTopicList(topic_)
            setTopicListBk(topic_)
        })


    }, []);


    const getMinDate = (date) => {
        // Convert the JavaScript Date object to a Luxon DateTime object
        const dateTime = DateTime.fromJSDate(date);

        // Add one day to the date
        const newDateTime = dateTime.plus({ days: 1 });

        // Convert back to JavaScript Date object
        return newDateTime.toJSDate();
    }

    const updateInitiative = (obj, val) => {
        let local = initiative;
        if (obj === 'applicability') {
            let item = val
            let selected_item = { country: 0, city: 0, location: 0 }
            let country_list = [{ name: 'All', id: 0 }]
            let city_list = [{ name: 'All', id: 0 }]
            let location_list = [{ name: 'All', id: 0 }]
            rawsitelist.forEach((country) => {
                country_list.push({ name: country.name, id: country.id })
                if (country.id === item.country || item.country === 0) {
                    if (country.locationTwos) {
                        country.locationTwos.forEach((city) => {
                            city_list.push({ name: city.name, id: city.id })
                            if (city.id === item.city || item.city === 0) {
                                if (city.locationThrees) {
                                    city.locationThrees.forEach((site) => {
                                        location_list.push({ name: site.name, id: site.id })

                                    })
                                }
                            }
                        })

                    }

                }

            })
            setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

            local[obj] = val
        } else {
            local[obj] = val;
        }
        if (obj === 'category') {
            setTopicList(topiclistbk.filter(i => i.newCategoryId === val))
            console.log(topiclistbk, val, topiclistbk.filter(i => i.newCategoryId === val))
        }
        if (obj === 'startDate') {
            local['completionDate'] = null
        }
        console.log(local)
        setInitiative(local)
        forceUpdate();
    }


    const saveData = () => {
        // Logic to save data
        // onHide();

        setSubmitted(true);
        let local = initiative;
        let local2 = record;
        if (initiative.title.trim().length !== 0 && initiative.goal !== null && initiative.goal.length && initiative.category !== null && initiative.description.trim().length !== 0 && initiative.startDate !== null && initiative.completionDate !== null && parseFloat(initiative.targetReduction) >= 0 && initiative.measurementUnit.trim().length && initiative.currencyUnit !== null && parseFloat(initiative.baselineData) >= 0 && initiative.responsibility !== null && initiative.responsibility.length && parseFloat(initiative.investment) > 0 && initiative.expectedAchievement.trim().length && initiative.monitorMethod.trim().length !== 0 && initiative.currentStatus !== null) {
            if (initiative.id === undefined) {
                let newObj = { ...initiative, created_on: DateTime.utc(), created_by: selector.id }
                APIServices.post(API.ClientIniative_UP(selector.id), newObj).then((res) => {

                    local2.push(res.data);
                    setInitiative({
                        title: '',
                        category: null,
                        description: '',
                        startDate: null,
                        completionDate: null,
                        targetReduction: 0,
                        measurementUnit: '',
                        applicability: { country: 0, city: 0, location: 0 },
                        baselineData: 0,
                        responsibility: [],
                        investment: 0,
                        expectedAchievement: '',
                        monitorMethod: '',
                        comments: '',
                        attachment: [],
                        currencyUnit: null,
                        currentStatus: null,
                        goal: null,
                        materialTopic: null
                    });
                    setRecord(local2)
                    forceUpdate()
                    setVisible(false)
                })
            } else {
                let newObj = { ...initiative, modified_on: DateTime.utc(), modified_by: selector.id }
                delete newObj.created_on
                delete newObj.created_by

                APIServices.patch(API.ClientIniative_Edit(initiative.id), newObj).then((res) => {
                    let index = local2.findIndex(i => i.id === newObj.id)
                    if (index !== -1) {
                        local2[index] = newObj
                    }
                    setRecord(local2)
                    forceUpdate()
                    setVisible(false)

                })
            }





        }
        console.log(initiative)
    };

    const initiativeCategories = [
        { label: 'GHG Reduction', value: 'GHG Reduction' },
        // ... other categories
    ];

    const CurrentStatus = [

        { label: 'New', value: 1 },

        { label: 'Underway', value: 2 },

        { label: 'Achieved', value: 3 },

        { label: 'Expired', value: 4 },

        { label: 'Revised', value: 5 },

        { label: 'Replaced', value: 6 },

        { label: 'Retired', value: 7 }


    ]

    const departments = [
        { label: 'ABC Department', value: 'ABC Department' },
        // ... other departments
    ];

    const measurementUnits = [
        { label: 'Ltr', value: 'Ltr' },
        // ... other departments
    ];

    const onHide = () => setVisible(false);




    const initiatives = [
        {
            id: 1,
            title: 'Energy Conservation Program',
            category: 'GHG Reduction',
            startDate: new Date(2023, 5, 1).toLocaleDateString(),
            completionDate: new Date(2023, 11, 1).toLocaleDateString(),
            responsibility: 'Operations',
            currentStatus: 'Completed',
            attachment: 'document1.pdf'
        },
        {
            id: 2,
            title: 'Water Usage Reduction',
            category: 'Resource Management',
            startDate: new Date(2023, 6, 15).toLocaleDateString(),
            completionDate: new Date(2024, 1, 15).toLocaleDateString(),
            responsibility: 'Facilities',
            currentStatus: 'In Progress',
            attachment: 'document2.pdf'
        },
        // Add more objects as needed for your application
    ];
    const titleTemplate = (rowData)=>{


        return (<div className="text-underline clr-navy fw-5 cur-pointer" onClick={() => { editVal(rowData)  }}>{rowData.title}</div>)
    }
    const categoryTemplate = (rowData) => {
        let text = 'NA'
        let index = categorylist.findIndex(i => i.id === rowData.category)
        if (index !== -1) {
            text = categorylist[index].title
        }
        return (<>{text}</>)
    }
    // Template for the 'Status' column
    const statusBodyTemplate = (rowData) => {
        let severity;
        let text = 'NA'
        let index = CurrentStatus.findIndex(i => i.value === rowData.currentStatus)
        if (index !== -1) {
            text = CurrentStatus[index].label
        }
        switch (rowData.currentStatus) {
            case 'Completed':
                severity = 'success';
                break;
            case 'Planned':
                severity = 'info';
                break;
            case 'In-progress':
                severity = 'warn';
                break;
            default:
                severity = 'info';
                break;
        }

        return <Message severity={severity} text={text} />;
    };

    const dateFormating = (input) => {
        let dateTime;
        // Parse input into Luxon DateTime object
        if (typeof input === 'string') {

            // Parse UTC string
            dateTime = DateTime.fromISO(input, { zone: 'utc' });
        } else if (input instanceof Date) {

            // Convert JavaScript Date object to Luxon DateTime object
            dateTime = DateTime.fromJSDate(input);
        } else {
            throw new Error('Invalid input type. Expected UTC string or Date object.');
        }

        // Format DateTime object into "dd-LLL-yyyy" format
        return dateTime.toLocal().toFormat('dd-LLL-yyyy');
    }

    const startDateTemplate = (rowData) => {
        return (
            <div>

                {dateFormating(rowData.startDate)}

            </div>
        )
    }

    const endDateTemplate = (rowData) => {
        return (
            <div>

                {dateFormating(rowData.completionDate)}

            </div>
        )
    }

    const newInitiative = () => {
        setInitiative({
            title: '',
            category: null,
            description: '',
            startDate: null,
            completionDate: null,
            targetReduction: 0,
            measurementUnit: '',
            applicability: { country: 0, city: 0, location: 0 },
            baselineData: 0,
            responsibility: [],
            investment: 0,
            expectedAchievement: '',
            monitorMethod: '',
            comments: '',
            attachment: [],
            currencyUnit: null,
            currentStatus: null,
            goal: null,
            materialTopic: null
        });

        let item = { country: 0, city: 0, location: 0 }
        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item.country || item.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item.city || item.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })

        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))

        setVisible(true);
        setSubmitted(false)
    }



    const attachmentBodyTemplate = (rowData) => {
        return (
            (rowData.attachment === null || !rowData.attachment.length) ?
                <span className="attachment-link">
                    No Attachment
                </span>
                :
                <span className="attachment-link">
                    <a href={API.Docs + rowData.attachment[0].originalname} target="_blank" rel="noopener noreferrer">
                        <i className="pi pi-file-pdf" style={{ 'marginRight': '0.5em', 'color': 'red' }}></i>
                        {rowData.attachment[0].originalname}
                    </a>
                </span>



        );
    };



    // Header for the table with a search input
    const renderHeader = () => {
        return (
            <div className="table-header-container">
                <h5>Sustainability Initiatives</h5>

                <div className="table-header-actions py-4 d-flex justify-content-between">

                    <Button onClick={() => { newInitiative(); setVisible(true); }} label="Add New" icon="pi pi-plus" className="p-button-primary" /* Add click event logic here */ />
                </div>
            </div>
        );
    };

    const onDrop = (acceptedFiles) => {
        console.log(acceptedFiles);
        // Process files here. For example, you could upload them to a server or parse file content.
    };
    function getFileExtension_(filename) {
        const lastDot = filename.lastIndexOf('.');
        let ext = lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
        let ext_types = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'jpeg', 'jpg', 'png', 'bmp']
        console.log(ext)
        return ext_types.includes(ext)
    }
    function getFileExtension(filename) {
        const lastDot = filename.lastIndexOf('.');
        return lastDot === -1 ? '' : filename.slice(lastDot + 1).toLowerCase();
    }
    const attachmentTemplate = (file) => {
        console.log(file)
        return (<div className="flex align-items-center text-three-dot col-10 mt-2">
            {(getFileExtension(file.originalname) === 'pdf') ?
                <img src={require('../../assets/images/pdf_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                (getFileExtension(file.originalname) === 'xlsx' || getFileExtension(file.originalname) === 'xls') ?
                    <img src={require('../../assets/images/excel_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                    (getFileExtension(file.originalname) === 'pptx' || getFileExtension(file.originalname) === 'ppt') ?
                        <img src={require('../../assets/images/ppt_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                        (getFileExtension(file.originalname) === 'docx' || getFileExtension(file.originalname) === 'doc') ?
                            <img src={require('../../assets/images/word_icon.png').default} width={100} style={{ borderRadius: 10 }} /> :
                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
            <span className="flex align-items-center text-left ml-3  clr-navy cur-pointer" onClick={() => { window.open(API.Docs + file.originalname) }}>
                {file.originalname.slice(13)} <Tag className={'status-tag-gray ml-2'} style={{ display: 'block', textDecoration: 'none' }} value={'Updated_on: ' + DateTime.fromISO(file.uploaded_on, { zone: 'utc' }).toLocal().toFormat('dd-LL-yyyy hh:mm a')} />
                {/* <small>{new Date().toLocaleDateString()}</small> */}
            </span>
        </div>)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept: 'image/*,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx',
        onDrop: (acceptedFiles) => {
            console.log(acceptedFiles);
            if (acceptedFiles[0].size <= 10000000 && getFileExtension_(acceptedFiles[0].name)) {
                let formData = new FormData()
                formData.append('file', acceptedFiles[0]);
                APIServices.post(API.FilesUpload, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'

                    }
                }).then((res) => {
                    let loc = initiative
                    loc.attachment = res.data.files
                    console.log(res.data)
                    setInitiative(loc)

                })
            }

            // Handle file logic here
        }
    });

    // Styles for the dropzone
    const dropzoneStyle = {
        border: '2px dashed gray',
        borderRadius: '5px',
        padding: '40px 20px', // Increased padding as requested
        textAlign: 'center',
        cursor: 'pointer'
    };

    // Icon style
    const iconStyle = {
        width: '48px',
        height: '48px',
        color: '#9e9e9e'
    };

    const dialogFooter = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-text" />
            <Button label="Save" icon="pi pi-check"
                onClick={() => {
                    saveData()
                }} />
        </div>
    );

    const editVal = (item) => {
        let items = JSON.parse(JSON.stringify(item))
        items.startDate = DateTime.fromISO(items.startDate, { zone: 'utc' }).toJSDate()
        items.completionDate = DateTime.fromISO(items.completionDate, { zone: 'utc' }).toJSDate()
        let cat_list = categorylist.findIndex(i => i.id === items.category)
        if (cat_list === -1) {
            items.category = null
            items.materialTopic = null
        } else {
            let top_list = topiclistbk.filter(i => i.newCategoryId === items.category)
            if (top_list.length) {
                setTopicList(top_list)
            } else {
                items.materialTopic = null
            }
        }
        let item_ = items.applicability

        let country_list = [{ name: 'All', id: 0 }]
        let city_list = [{ name: 'All', id: 0 }]
        let location_list = [{ name: 'All', id: 0 }]
        rawsitelist.forEach((country) => {
            country_list.push({ name: country.name, id: country.id })
            if (country.id === item_.country || item_.country === 0) {
                if (country.locationTwos) {
                    country.locationTwos.forEach((city) => {
                        city_list.push({ name: city.name, id: city.id })
                        if (city.id === item_.city || item_.city === 0) {
                            if (city.locationThrees) {
                                city.locationThrees.forEach((site) => {
                                    location_list.push({ name: site.name, id: site.id })

                                })
                            }
                        }
                    })

                }

            }

        })
        setLocList((prev) => ({ ...prev, 'country': country_list, 'city': city_list, 'location': location_list }))


        setInitiative(items)

        setVisible(true);
        forceUpdate()
    }
    const deleteRow = (item) => {
        // alert("into del func")
        let loc = record
        Swal.fire({
            title: `Are you sure want to delete ${item.title} ?`,
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                APIServices.delete(API.ClientIniative_Edit(item.id)).then((res) => {
                    let index = loc.findIndex((k) => { return k.id === item.id })
                    loc.splice(index, 1)
                    setRecord(loc)
                    forceUpdate()
                })
            }
        })



    }
    const sortRP = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.startDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.startDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.startDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.startDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const sortRP2 = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.completionDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.completionDate, { zone: 'utc' });


                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromISO(a.completionDate, { zone: 'utc' });
                let dateB = DateTime.fromISO(b.completionDate, { zone: 'utc' });
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>


                <div className="actions">

                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-warning mt-2"
                        onClick={() => {
                            deleteRow(rowData)
                        }}
                    />
                </div>

            </>
        );
    };




    return (
        <div className='font-lato'>
            <DataTable
                value={record}
                dataKey="id"
                tableClassName='font-lato'
                header={renderHeader()}
                //   header={edit === 1 && renderHeader()}
                className="p-datatable-initiatives">

                <Column
                    field="title"
                    header="Initiative" sortable

                    body={titleTemplate}
                />

                <Column field="category" header="Category"
                    body={categoryTemplate}
                />

                <Column
                    body={startDateTemplate} sortFunction={sortRP} sortable
                    field="startDate" header="Start Date"
                />

                <Column
                    body={endDateTemplate} sortFunction={sortRP2} sortable
                    field="completionDate" header="Target Date" />



                <Column
                    body={statusBodyTemplate}
                    header="Status" />

                <Column
                    body={attachmentBodyTemplate}
                    header="Attachment" />

                <Column
                    body={actionBodyTemplate}
                >

                </Column>

            </DataTable>

            <Dialog contentClassName='fw-5 font-lato' header={initiative.id ? "Edit " + initiative.title : "Add Sustainability Initiative"} visible={visible} style={{ width: '50vw' }} footer={dialogFooter} onHide={() => { setVisible(false) }} modal>
                <Tooltip className="form-tt-1" target={".clientinit"} mouseTrack mouseTrackLeft={10} />
                <div className="p-fluid row p-3">
                    <div className="p-field col-6  ">
                        <label htmlFor="title" >Initiative Name  <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Enter the official name of the initiative. Keep it concise and indicative of the initiative's purpose. \n Example: "Sustainable Energy Program" `}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>  </label>
                        <InputText
                            id="title"
                            className='mt-2'
                            value={initiative.title}
                            onChange={(e) => { updateInitiative("title", e.target.value) }}
                            placeholder='Name'
                        />

                        {submitted && initiative.title.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Initiative Name
                            </small>
                        )}
                    </div>
                    <div className="p-field col-6 ">
                        <label htmlFor="category" className='flex'>Initiative Category <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Select the category that best represents the initiative's focus, such as Environment, Social, Governance others`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <Dropdown className='mt-2' id="category" value={initiative.category} optionValue='id' optionLabel='title' options={categorylist} onChange={(e) => { updateInitiative("category", e.value) }} placeholder="Select  Initiative Category" />

                        {submitted && initiative.category === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Initiative Category
                            </small>
                        )}
                    </div>
                    <div className="p-field col-12 ">
                        <fieldset style={{
                            padding: '20px',
                            borderRadius: '10px',
                            border: '1px solid #ced4da',

                        }}>
                            <legend>Applicability</legend>
                            <label>Country</label>
                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={initiative.applicability.country} options={locList.country} optionLabel="name" optionValue="id"
                                onChange={(e) => { updateInitiative('applicability', { country: e.value, city: 0, location: 0 }); forceUpdate() }} placeholder="Select Country"
                            />
                            <label>City</label>
                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={initiative.applicability.city} options={locList.city} optionLabel="name" optionValue="id"
                                onChange={(e) => { updateInitiative('applicability', { country: initiative.applicability.country, city: e.value, location: 0 }); forceUpdate() }} placeholder="Select City"
                            />
                            <label>Location</label>
                            <Dropdown className='mt-2 mb-2' style={{ width: '100%' }} value={initiative.applicability.location} options={locList.location} optionLabel="name" optionValue="id"
                                onChange={(e) => { updateInitiative('applicability', { country: initiative.applicability.country, city: initiative.applicability.city, location: e.value }); forceUpdate() }} placeholder="Select Site"
                            />
                        </fieldset>
                    </div>

                    <div className="p-field col-12 ">
                        <label htmlFor="description" className='flex'>Initiative Description <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Provide a brief overview of the initiative, including its primary objectives, target audience, and key activities.
                        Example: "Aims to reduce carbon emissions by promoting renewable energy through workshops and solar panel installations."`  }
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <InputText className='mt-2' id="description" value={initiative.description} onChange={(e) => setInitiative({ ...initiative, description: e.target.value })} />

                        {submitted && initiative.description.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Initiative Description
                            </small>
                        )}
                    </div>

                    <div className="p-field col-12 ">
                        <label htmlFor="status" className='flex'>Associated Goal(s) <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Specify the overarching goal or outcome the initiative aims to achieve. This could be environmental, social, or economic in nature.
                        Example: "To decrease carbon footprint within three years."`  }
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>  </label>

                        <MultiSelect value={initiative.goal} onChange={(e) => updateInitiative("goal", e.value)} options={goallist} optionLabel="title"
                            optionValue='id' filter placeholder="Select Goal(s)" maxSelectedLabels={3} className="w-full mt-2" />
                        {submitted && (initiative.goal === null || !initiative.goal.length) && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Goal Associated
                            </small>
                        )}
                    </div>

                    <div className="p-field col-12 ">
                        <label htmlFor="status" className='flex'>Material Topic <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Identify the primary material topic or focus area of the initiative. This could include energy, waste management, water conservation, etc.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <Dropdown className='mt-2' id="status" disabled={initiative.category === null} filter value={initiative.materialTopic} optionValue='id' optionLabel='title' options={topiclist} onChange={(e) => setInitiative({ ...initiative, materialTopic: e.value })} placeholder={initiative.category === null ? 'Select category to list topics' : "Select a Material Topic"} />

                        {submitted && initiative.materialTopic === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Material Topic
                            </small>
                        )}
                    </div>

                    <div className="p-field col-6 ps-0 pb-4">
                        <label htmlFor="startDate" className='flex'> Start Date <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Enter the date when the initiative officially begins.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>  </label>
                        <Calendar className='mt-2' id="startDate" value={initiative.startDate} onChange={(e) => { updateInitiative("startDate", e.value) }} showIcon dateFormat='dd-MM-yy' />

                        {submitted && initiative.startDate === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Start Date
                            </small>
                        )}
                    </div>

                    <div className="p-field col-6 pe-0 pb-4">
                        <label htmlFor="completionDate" className='flex'>Target Date <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Specify the deadline or target date for achieving the initiative's goals.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <Calendar className='mt-2' minDate={getMinDate(initiative.startDate)} id="completionDate" value={initiative.completionDate} onChange={(e) => { updateInitiative("completionDate", e.value) }} showIcon dateFormat='dd-MM-yy' />

                        {submitted && initiative.completionDate === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select End Date
                            </small>
                        )}
                    </div>

                    <div className="p-field col-6 ps-0 pb-4">
                        <label htmlFor="targetReduction" className='flex'>Target <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Quantify the specific target to be achieved by the initiative. Include the unit of measurement.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <InputNumber className='mt-2' id="targetReduction" value={initiative.targetReduction} onValueChange={(e) => setInitiative({ ...initiative, targetReduction: e.value })} />

                        {submitted && parseFloat(initiative.targetReduction) < 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Target Reduction is required
                            </small>
                        )}
                    </div>

                    <div className="p-field col-6 pe-0 pb-4">
                        <label htmlFor="targetReduction" className='flex'> Measurement Unit <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Define the unit of measurement used to track progress towards the target.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>  </label>
                        <InputText className='mt-2' value={initiative.measurementUnit} onChange={(e) => setInitiative({ ...initiative, measurementUnit: e.target.value })} />

                        {submitted && !initiative.measurementUnit.trim().length && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Measurement Unit
                            </small>
                        )}

                    </div>

                    <div className="p-field  col-12 ">
                        <label htmlFor="baselineData" className='flex'>Baseline Performance Data <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Provide data from a baseline year to establish a starting point for measuring progress.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <InputNumber className='mt-2' min={1} id="baselineData" value={initiative.baselineData} onChange={(e) => setInitiative({ ...initiative, baselineData: e.value })} />

                        {submitted && parseFloat(initiative.baselineData) < 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Baseline Performance Data
                            </small>
                        )}
                    </div>

                    <div className="p-field col-6 ps-0 pb-4">
                        <label htmlFor="department" className='flex'>Responsibility <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Assign responsibility for implementing and overseeing the initiative. Specify individuals or departments involved.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>

                        <MultiSelect value={initiative.responsibility} onChange={(e) => updateInitiative("responsibility", e.target.value)} options={userlist} optionLabel="name"
                            optionValue='id' filter placeholder="Select User(s)" maxSelectedLabels={3} className="w-full mt-2" />
                        {submitted && (initiative.responsibility === null || !initiative.responsibility.length) && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Responsibility
                            </small>
                        )}

                    </div>

                    <div className="p-field col-6 pe-0 pb-4">
                        <label htmlFor="investment" className='flex'>Investment <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={` Estimate the financial investment required to execute the initiative successfully.

                        `  }
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <div className="p-inputgroup flex-1 mt-2">

                            <InputNumber id="investment" min={1} value={initiative.investment} onValueChange={(e) => setInitiative({ ...initiative, investment: e.value })} />
                            <Dropdown value={initiative.currencyUnit} options={currencyList} onChange={(e) => setInitiative({ ...initiative, currencyUnit: e.value })} optionLabel='name' optionValue='name' />
                        </div>


                        {submitted && (parseFloat(initiative.investment) <= 0 || initiative.currencyUnit === null) && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Investment & Currency code is required
                            </small>
                        )}
                    </div>

                    <div className="p-field  col-12 ">
                        <label htmlFor="expectedBenefits" className='flex'>Expected Achievement <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Outline the anticipated outcomes or results of the initiative once completed.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <InputText className='mt-2' min={1} id="expectedBenefits" value={initiative.expectedAchievement} onChange={(e) => setInitiative({ ...initiative, expectedAchievement: e.target.value })} />

                        {submitted && initiative.expectedAchievement.trim().length <= 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Expected Achievement
                            </small>
                        )}
                    </div>

                    <div className="p-field  col-12 ">
                        <label htmlFor="monitorMethod" className='flex'>Monitoring and Verification Method <span className="mandatory"> *</span>       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Describe the method used to monitor progress and verify the achievement of targets.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <InputText className='mt-2' id="monitorMethod" value={initiative.monitorMethod} onChange={(e) => setInitiative({ ...initiative, monitorMethod: e.target.value })} />

                        {submitted && initiative.monitorMethod.trim().length === 0 && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Enter Monitoring and Verification Method
                            </small>
                        )}

                    </div>

                    <div className="p-field  col-12 ">
                        <label htmlFor="comments" className='flex'>Comments  <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Include any additional comments or notes relevant to the initiative.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span> </label>
                        <InputText className='mt-2' id="comments" value={initiative.comments} onChange={(e) => setInitiative({ ...initiative, comments: e.target.value })} />



                    </div>



                    {/* <div className="p-field col-12 ">
                        <label>Current Status</label>
                        <div className="p-formgrid p-grid row">
                            <div className="p-field-radiobutton p-col col-4">
                                <RadioButton inputId="statusPlanned" value="planned" onChange={(e) => setInitiative({ ...initiative, currentStatus: e.value })}
                                    checked={initiative.currentStatus === 'planned'} />
                                <label htmlFor="statusPlanned" style={{ fontWeight: 'normal', marginLeft: '10px' }}>Planned</label>
                            </div>
                            <div className="p-field-radiobutton p-col col-4">
                                <RadioButton inputId="statusInProgress" value="inProgress" onChange={(e) => setInitiative({ ...initiative, currentStatus: e.value })}
                                    checked={initiative.currentStatus === 'inProgress'} />
                                <label htmlFor="statusInProgress" style={{ fontWeight: 'normal', marginLeft: '10px' }}>In progress</label>
                            </div>
                            <div className="p-field-radiobutton p-col col-4">
                                <RadioButton inputId="statusCompleted" value="completed" onChange={(e) => setInitiative({ ...initiative, currentStatus: e.value })}
                                    checked={initiative.currentStatus === 'completed'} />
                                <label htmlFor="statusCompleted" style={{ fontWeight: 'normal', marginLeft: '10px' }}>Completed</label>
                            </div>
                        </div>
                    </div> */}

                    <div className="p-field  col-12 ">
                        <label htmlFor="attachment" className='flex' >Attachment       <span><i style={{
                            marginTop: 2,
                            marginLeft: 3
                        }} className={`material-icons fs-14 clientinit`} data-pr-tooltip={`Attach any relevant documents, reports, or files associated with the initiative.`}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>  </label>
                        {initiative.attachment.length ?
                            attachmentTemplate(initiative.attachment[0])
                            : <div {...getRootProps()} className='mt-2' style={dropzoneStyle}>
                                <input {...getInputProps()} />
                                <i className='pi pi-cloud-upload'></i>
                                <p>Drag and drop or click to upload</p>
                            </div>}
                    </div>

                    <div className="p-field col-12 ">
                        <label htmlFor="status">Current Status <span className="mandatory"> *</span>  </label>
                        <Dropdown id="status" className='mt-2' value={initiative.currentStatus} optionValue='value' options={CurrentStatus} onChange={(e) => { updateInitiative("currentStatus", e.target.value) }} placeholder="Select a Current Status" />

                        {submitted && initiative.currentStatus === null && (
                            <small
                                className="p-invalid"
                                style={{
                                    color: "red",
                                }}
                            >

                                Select Current Status
                            </small>
                        )}
                    </div>

                </div>
            </Dialog>
        </div>
    );
};

export default Initiative;
