import React from 'react';
import { Card } from 'primereact/card';
import { Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';

const BarFive = () => {
    const data = [
        { month: 'Jan', stationary: 40, fugitive: 20, mobile: 30 },
        { month: 'Feb', stationary: 45, fugitive: 25, mobile: 35 },
        { month: 'Mar', stationary: 50, fugitive: 30, mobile: 40 },
        { month: 'Apr', stationary: 55, fugitive: 35, mobile: 45 },
        { month: 'May', stationary: 60, fugitive: 40, mobile: 50 },
        { month: 'Jun', stationary: 65, fugitive: 45, mobile: 55 },
        { month: 'Jul', stationary: 70, fugitive: 50, mobile: 60 },
        { month: 'Aug', stationary: 75, fugitive: 55, mobile: 65 },
        { month: 'Sep', stationary: 80, fugitive: 60, mobile: 70 },
        { month: 'Oct', stationary: 85, fugitive: 65, mobile: 75 },
        { month: 'Nov', stationary: 90, fugitive: 70, mobile: 80 },
        { month: 'Dec', stationary: 95, fugitive: 75, mobile: 85 }
    ];


    return (
        <Card>
            <p className='card-title'>Energy consumption (TJ)</p>
            <p className='card-content'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore</p>

            <ResponsiveContainer width="100%" height={320}>
                <BarChart data={data}>
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={() => ''} />
                    <Tooltip />
                    <Bar stackId="a" dataKey="stationary" fill="#EE5724" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="stationary" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="fugitive" fill="#FF9D7C" radius={[0, 0, 0, 0]} barSize={30}>
                        <LabelList dataKey="fugitive" position="top" />
                    </Bar>
                    <Bar stackId="a" dataKey="mobile" fill="#F5C37B" radius={[10, 10, 0, 0]} barSize={30}>
                        <LabelList dataKey="mobile" position="top" />
                    </Bar>
                   
                    <Legend verticalAlign="bottom" align="center" />
                </BarChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default BarFive;
